import React from 'react';
import { Box, Container,  Menu, MenuItem,  FormControlLabel, Switch, TextareaAutosize, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography, TextField, Autocomplete } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {SnackBar} from  '../components/general.js';
import ImageUpload from './imageUpload';
import {getProductById, getProductTypes, getProductBrands, getTemplateById} from '../services/products.js';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {getWindowDimensions} from '../util/index.js'

const classes = {
  body:{
    width:'100%',
    display:'flex',
    flexDirection: {xs:'column', sm: 'column', md:'row-reverse', lg:'row-reverse', xl:'row-reverse'}
  },
  left: {
    width:{xs:'100%', sm: '100%', md:'70%', lg:'70%', xl:'70%'} 
  },
  right: {
    width:{xs:'100%', sm: '100%', md:'30%', lg:'30%', xl:'30%'},
    display:'flex',
    alignItems:'center',
    justifyContent:'center' 
  },
  inline: {
    width:'90%',
    marginBottom:'15px',
    marginRight: 25,
  },
  formControl: {
    minWidth:100,
    marginRight:'25px'
  },
  textArea: {
    width:'100%',
    marginTop:20
  },
  rowcont :{
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  innerleft : {
    width: '50%',
    display: 'flex',
    flexDirection: 'column'

  },
  innerright: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column'
  },
  includeCont: {
    display: 'table',
    borderCollapse: 'separate',
    borderSpacing: 10,
    marginTop:'5px'
  },
  includeInd: {
    margin:'2px'
  }
};


const isSameArray =  (array1, array2) => {
  return array1.every(function (element) {
    return array2.includes(element);
  });
}
const IndInclude = ({include, handleIncludeDelete, setIncluded, included, index}) => {

  return (
    <div style={{display: 'table-row', alignItems: 'center', marginBottom: '10px'}}>
      <Typography sx={{marginRight: 5, display: 'table-cell'}}>{include.item}</Typography> 
      <CloseIcon sx={{marginRight: 5, fontSize:'1rem', display: 'table-cell'}}/> 
      <TextField
          id="outlined-number"
          variant='standard'
            sx={{marginLeft:5, width:60, display:'table-cell'}}
          value={include.count}
          type="number"
          onInput={(e)=>{
            const val = parseInt(e.nativeEvent.data, 10)
            if (val >= 1) {
              let _included = [...included]
              _included[index].count = val;
              setIncluded(_included)
            }
          }}
        />
      <IconButton><DeleteIcon onClick={handleIncludeDelete(include)}/></IconButton>
    </div>
  )
}

const IndSpec = ({spec, deleteSpec, index}) => {
    return (
        <div style={{display: 'flex', flexDirection:'row', alignItems: 'center'}}>
            <ChevronRightIcon sx={{marginRight:2}}/>
            <Typography sx={{marginRight: 3}}>{spec.label}</Typography>
            <Typography sx={{marginRight: 3}}>{spec.value}</Typography>
            <IconButton onClick={deleteSpec(spec)}><DeleteIcon/></IconButton>
        </div>
    )
}
const TypeDropdown = (props) => {
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClose = (e) => {
        console.log(e.target.textContent)
        props.setType(e.target.textContent)
        setOpen(false)
    }

    return (
        <div style={{display: 'flex', alignItems: 'center', minHeight:'50px'}}>
            <div >Type : </div>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e)=>{setAnchorEl(e.currentTarget);setOpen(!open)}}>
            {props.type|| `Select Type`}
            </Button>
            <Menu
            id="type-menu"
            keepMounted
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            >
            {props.types.map((x, key)=><MenuItem key={key} onClick={handleClose}>{x.label}</MenuItem>)}
            </Menu>
        </div>
    )
}

export default function CustomizedDialogs(props) {
    const [name, setName] = React.useState('');
    const [type, setType] = React.useState('');
    const [types, setTypes] = React.useState([])
    const [isAvailable, setIsAvailable] = React.useState(true);
    const [file, setFile] = React.useState(undefined);
    const [rent, setRent] = React.useState(0);
    const [purchasePrice, setPurchasePrice] = React.useState(0);
    const [avatarUrl, setAvatarUrl] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [included, setIncluded] = React.useState([]);
    const [includedVal, setIncludedVal] = React.useState('');
    const [spec, setSpec] = React.useState({label:'', value:''});
    const [specs, setSpecs] = React.useState([]);
    const [brand, setBrand] = React.useState('');
    const [brands, setBrands] = React.useState('');
    const [product, setProduct] = React.useState(undefined);
    const [template, setTemplate] = React.useState(undefined);
    const [showSnack, setShowSnack] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState('Something went wrong');
    const [snackType, setSnackType] = React.useState('error');
    const saveTitle = props.editableProduct ? 'Update Changes' : 'Save';
    let saveFunc
    if(props.editableProduct) {
        saveFunc = props.editProduct 
    } else if (props.editableTemplate) {
        saveFunc = props.addProductWithTemplate
    } else {
        saveFunc = props.addProduct
    }
    const dim = getWindowDimensions();
    const isMobile = dim.width < 500 ? true : false
    React.useEffect(()=>{
      if (props.editableProduct) {
          const getProductData  = async(id) => {
            const data =  await getProductById(id);
            console.log(data);
            setFields(data);
            setProduct(data);
          }
          getProductData(props.editableProduct)          
      }
    }, [props.editableProduct])

    React.useEffect(()=>{
      if (props.editableTemplate) {
          const getTemplateData  = async(id) => {
            const data =  await getTemplateById(id);
            setFields(data);
            setTemplate(data);
          }
          getTemplateData(props.editableTemplate)
      }
    }, [props.editableTemplate])
    React.useEffect(()=>{
      if (!props.editableProduct && props.addProductOpen) {
        resetFields();
      }
    }, [props.editableProduct, props.addProductOpen])
    React.useEffect(()=>{
      const getData = async() => {
        const _types = await getProductTypes();
        setTypes(['Select Type', ..._types])
        const _brands = await getProductBrands();
        setBrands(_brands)
      }
      getData();
    }, [])
    const resetFields = () => {
        setName('')
        setFile('')
        setRent(0)
        setPurchasePrice(0)
        setType('')
        setBrand('')
        setAvatarUrl('')
        setIsAvailable(true)
        setIncludedVal('')
        setDescription('')
        setIncluded([])
        setSpecs([])
    }
    const setFields = (data) => {
      const _isAvailable = data.isAvailable === undefined ? true : data.isAvailable
      setName(data.name)
      setFile('')
      setRent(data.rent)
      setPurchasePrice(data.purchasePrice || 0)
      setType(data.type)
      setBrand(data.brand)
      setAvatarUrl(data.image.thumb)
      setIsAvailable(isAvailable)
      setIncludedVal('')
      setDescription(data.description ||'')
      setIncluded(data.includes)
      setSpecs(data?.specs??[])
  }
    const handleAddProductClose = (e) => {
        props.addProductClose()(e);
        resetFields()
        setTemplate(undefined)
    }
    const handleSnackClose=(e)=> {
      setShowSnack(false)
    }
    const handleClick =()=> { 
        return (e) => {
            if (name && rent && type && (props.editableProduct || file || template)) {
                let data = {};
                if (product) {
                  if (product.name !== name) {
                    data.name = name; 
                  }
                  if (product.rent !== rent) {
                    data.rent = rent; 
                  }
                  if (product.type !== type) {
                    data.type = type; 
                  }
                  if (product.brand !== brand) {
                    data.brand = brand; 
                  }
                  if (product.isAvailable !== isAvailable) {
                    data.isAvailable = isAvailable; 
                  }
                  if (product.description !== description) {
                    data.description = description; 
                  }
                  if (!isSameArray(included, product.includes)) {
                    data.includes = included;
                  }
                  if (!isSameArray(specs, product?.specs??[])) {
                    data.specs = specs;
                  }

                } else {
                  data.name=name;
                  data.rent=rent;
                  data.type=type;
                  data.brand=brand;
                  data.includes=included;
                  data.specs = specs;
                  data.isAvailable = isAvailable;
                  data.description=description;
                }
                if (template) {
                    data.image = template.image
                }
                saveFunc(data, file, props.editableProduct)
                resetFields();

            } else {
                setSnackMessage('Mandatory details required')
                setSnackType('error')
                setShowSnack(true)
                console.log('Mandatory details required');
            }
        }
    }

    const addIncludedItem = () => {
        if (includedVal) {
            const _included = [...included];
            const val = includedVal.trim();
            _included.push({item: val, count: 1});
            setIncluded(_included);
            setIncludedVal('');
        }
    }
    const handleIncludedItems = (e) => {
      if (e.key === 'Enter' && e.target.value.trim()) {
        const _included = [...included];
        const val = e.target.value.trim();
        _included.push({item: val, count: 1});
        setIncluded(_included);
        setIncludedVal('');
      }
    }
    const handleIncludeDelete = (include) => {
      return (e) => {
        const _included = [...included];
        const index = _included.findIndex(inc=> inc.item === include.item)
        _included.splice(index,1);
        setIncluded(_included);
      }
    }
    const addSpec = () => {
        if (spec.label && spec.value) {
            const _specs = [...specs]
            _specs.push(spec)
            setSpecs(_specs)
            setSpec({value:'', label: ''})
        }
    }
    const deleteSpec = (inSpec) => {
        return (e) => {
            const _specs = [...specs]
            const index = _specs.findIndex(spec=> spec.label === inSpec.label)
            _specs.splice(index, 1)
            setSpecs(_specs)
        }

    }
  return (
    <div>
      <Dialog onClose={handleAddProductClose} fullWidth={true}
        maxWidth={"md"} aria-labelledby="customized-dialog-title" open={props.addProductOpen}>
        <DialogTitle id="customized-dialog-title" onClose={handleAddProductClose}>
          Add Product
        </DialogTitle>
        <DialogContent dividers>
            <Container sx={classes.body}>
                <Container maxWidth={"md"} sx={classes.right}>
                    <ImageUpload avatarUrl={avatarUrl} setFile={setFile} setAvatarUrl={setAvatarUrl}/>
                </Container>
                <Container maxWidth={"md"} sx={classes.left}>
                    <TextField variant="standard" value={name} sx={{marginBottom: '15px'}} fullWidth={true} id="standard-basic" label="Name" onChange={(e)=>{setName(e.target.value)}}/>
                    <div style={classes.rowcont}>
                        <div style={isMobile ? {...classes.innerleft, width:'100%'} : classes.innerleft}>
                          <TextField variant="standard" value={rent} sx={classes.inline} label="Rent" onChange={(e)=>{setRent(e.target.value)}}/>
      {/*<TextField variant="standard" disabled={true} value={purchasePrice}  sx={classes.inline} label="Purchase Price"/>*/}
                            <Autocomplete
                              disablePortal
                              id="brands"
                              getOptionLabel={option=>option.label}
                              value={{label: brand ?? 'Brand'}}
                              options={brands}
                              onChange={(e,v,r)=>{ setBrand(v.label)}}
                              sx={{ width: '90%', marginBottom:'15px' }}
                              renderInput={(params) => <TextField {...params} label="Brand" />}
                            />
                            

                        </div>
                        <div style={isMobile ? {...classes.innerright, width:'100%'} : classes.innerright}>
                              <FormControlLabel
                                style={{marginTop:'25px'}}
                                  control={
                                  <Switch
                                  checked={isAvailable}
                                  onChange={(e)=>{console.log(e.target.checked); setIsAvailable(e.target.checked)}}
                                  name="checkedB"
                                  color="primary"
                                  />
                                  }
                                  label="Available"
                              />
                              <TypeDropdown  type={type} setType={setType} types={types}/>
                        </div>
                        <Box sx={{marginTop:1, padding:1, marginBottom:1, border: '1px solid black', position:'relative', borderRadius:2, width:'100%'}}>
                            <Typography sx={{position:'absolute', top:-10, padding: '0 10px', left:10, backgroundColor:'white'}}>Included Items</Typography>
                          {included && included.length > 0 && (<div style={classes.includeCont}>{included.map((include, ind)=><IndInclude setIncluded={setIncluded} included={included} index={ind} key={`inc-${ind}`} include={include} handleIncludeDelete={handleIncludeDelete}/>)}</div>)}
                          <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>  
                              <TextField variant="standard" value={includedVal} onChange={e=>{setIncludedVal(e.target.value)}} sx={{...classes.inline, width:'80%', marginRight:2}} label="Included Items" onKeyDown={handleIncludedItems}/>
                            
                                <IconButton onClick={addIncludedItem}><AddIcon/></IconButton>
                            </div>
                        </Box>
                        <Box sx={{marginTop:1, position:'relative', padding:1, marginBottom:1, border: '1px solid black', borderRadius:2, width:'100%'}}>
                            <Typography sx={{position:'absolute', top:-10, padding: '0 10px', left:10, backgroundColor:'white'}}>Specifications</Typography>
                            {specs && specs.length > 0 && (<div>{specs.map((_spec, index)=> <IndSpec index={index} key={`spec-${index}`} spec={_spec} deleteSpec={deleteSpec}/>)}</div>)}
                            <div style={{display:'flex', flexDirection: 'row', width: '100%', alignItems: 'center'}}>
                                <TextField variant="standard" value={spec.label} sx={{width:'80%', marginRight:2}} onChange={e=> setSpec({...spec, label: e.target.value})} label="Spec label"/>
                                <TextField variant="standard" value={spec.value} sx={{width:'80%', marginRight:2}}  onChange={e=> setSpec({...spec, value: e.target.value})} label="Spec value"/>
                                <IconButton onClick={addSpec}><AddIcon/></IconButton>
                            </div>
                        </Box>
                    <TextareaAutosize
                          style={classes.textArea}
                          minRows={5}
                          onChange={(e)=>{setDescription(e.target.value)}}
                            aria-label="maximum height"
                            placeholder="Description"
                          />
                  </div>
                </Container>
            </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddProductClose} color="secondary">
            Cancel
          </Button>
          <Button autoFocus onClick={handleClick()} color="primary">
            {saveTitle}
          </Button>
        </DialogActions>
      </Dialog>
      <SnackBar message={snackMessage} open={showSnack} severity={snackType} handleClose={handleSnackClose}/>
    </div>
  );
}
