import {getStore, removeItem, checkStatusError} from '../util' 
const login = async ({username, password}) => {    
  const response =  await fetch(`/api/admin/login` ,{
        method: 'POST',
         headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({email: username, password: password})
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
};
const userexists = async (email) => {
    let url = `/api/admin/userexists?email=${email}`;
    const response =  await fetch(url, {
         headers: {
            'Content-Type': 'application/json',
        }
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
}
const doSetPassword = async(email, password, code) => {
    const response =  await fetch(`/api/admin/resetpassword` ,{
        method: 'POST',
         headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({email: email, password: password, code:code})
    });
    if (response.ok) {
        return true
    }
    throw response;
}
const requestCode = async(email) => {
    const response =  await fetch(`/api/admin/requestcode?email=${email}` ,{
         headers: {
            'Content-Type': 'application/json'
        }
    });
    if (response.ok) {
        console.log('success')
        return true
    }
    throw response;
}
export {login, userexists, doSetPassword, requestCode}