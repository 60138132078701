import React, {useState, useEffect} from 'react';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';

import LogoutIcon from '@mui/icons-material/Logout';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloseIcon from '@mui/icons-material/Close';

import {SnackBar, DialogWindow} from  './general';
import {getMultipleProducts} from '../services/products.js'

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const styleObj = {
    checkProd: {
      marginLeft: 15
    },
    delDialogLeft: {
      width: '60%'
    },
    delDialogCont: {
      display: 'flex'
    },
    delDialogRight: {
      width: '40%'
    },
    textarea: {
      width: '100%'
    },
    indNoteCont: {
      marginBottom: '5px'
    },
    noteTitle:{
      fontWeight: 'bold',
      fontSize: '14px',
      textDecoration: 'underline'
    },
    note: {
      fontSize: '14px'
    },
    adv: {
      marginBottom: '10px',
      color: 'green'
    },
    dis: {
      marginBottom: '10px',
      color: '#e1ad01'
    },
    amt: {
      marginBottom: '10px',
      color: '#a0522d'
    },
    bal: {
      marginBottom: '10px',
      color: 'red'
    }
  };
  const Cashdetails = ({bookDetails}) => {    
    return (
      <div>
        <div style={styleObj.amt}>Amount : ₹{bookDetails.amount}/-</div>
        <div style={styleObj.dis}>Discount : ₹{bookDetails.discount}/-</div>
        <div style={styleObj.adv}>Advance : ₹{bookDetails.advance}/-</div>
        <div style={styleObj.bal}>Balance : ₹{bookDetails.amount - bookDetails.advance}/-</div>
      </div>
    )
  }
  const Notes = ({currentNote, setCurrentNote, bookDetails}) => {
    const getTitle = (note) => {
      const map = {
        bookingNotes: 'Booking Notes',
        checkoutNotes: 'Checkout Notes',
        checkinNotes: 'Checkin Notes'
      }
      return map[note];
    }
    const getNextTitle = (status) => {
      switch(status) {
        case 'booked':
          return 'Checkout Notes'
        case 'checkedout':
          return 'Checkin Notes'
      }

    }
    const oldNotes = Object.keys(bookDetails.notes || {}).map(note=> {
      if (bookDetails.notes[note]) {
        return (
          <div style={styleObj.indNoteCont}>
            <div style={styleObj.noteTitle}>{getTitle(note)}</div>
            <div style={styleObj.note}>{bookDetails.notes[note]}</div>
          </div>
        )
      }
      return null;
    })
    return (
      <>
            {oldNotes}
        {(bookDetails.status === 'booked'  || bookDetails.status === 'checkedout') && (<><Typography variant="subtitle2" component="div">{getNextTitle(bookDetails.status)}</Typography>
          <TextareaAutosize
            aria-label="empty textarea"
            minRows={3}
            key='txt-area'
            value={currentNote}
            onChange={(e)=>{setCurrentNote(e.target.value)}}
            placeholder={getNextTitle(bookDetails.status)}
            sx={styleObj.textarea}
          /></>)}
      </>

    )
  }

  const Actions = ({setChecks, bookDetails, checkWarn, setCheckWarn, checkError, setCheckError, checks, setOpen, doCheckIn, doCheckout, doClose, setCurrentNote}) => {
    const handleAction = (status) => {
      const validateCheckoutCheckBox = () => {
          return Object.values(checks).filter(check=> check===true)

      }
        const validateCheckinCheckBox = () => {
          console.log(checks)
          for (const obj in checks) {
              if (!checks[obj]) {
                  setCheckError(true)
                  return false
              }
          }
          return true
      }
      return () => {
          if (status === 'booked') {
              const checkedBoxes = validateCheckoutCheckBox();
              const isAllChecked = checkedBoxes.length === Object.values(checks).length;
              const isAnyChecked = checkedBoxes.length > 0
              console.log(isAllChecked, checkWarn, isAnyChecked, checks)
              if (!isAnyChecked) {
                  setCheckError(true)
                  return
              }
              if (isAllChecked || checkWarn) {
                  doCheckout(checks);
                  setChecks(checks)
                  setCheckWarn(false)
              } else {
                  setChecks(checks)
                  setCheckWarn(true)
              }
          }
          if (status === 'checkedout') {
              if (validateCheckinCheckBox()) {
                  doCheckIn();
              }
          }
          if (status === 'checkedin') {
              doClose();
          }
      }
    }
      if (bookDetails.status==='booked') {
          return([
              <Button variant="outlined" onClick={()=>{setChecks({});setOpen(false); setCurrentNote('')}}startIcon={<ArrowBackIosNewIcon />}>Back </Button>,
              <Button variant="contained" onClick={handleAction(bookDetails.status)} endIcon={<LogoutIcon/>}>Checkout </Button>
          ])
      } else if (bookDetails.status==='checkedout') {
          return([
              <Button variant="outlined" onClick={()=>{setChecks({});setOpen(false); setCurrentNote('')}}startIcon={<ArrowBackIosNewIcon />}>Back </Button>,
              <Button variant="contained" onClick={handleAction(bookDetails.status)} endIcon={<LogoutIcon/>}>CheckIn </Button>
          ])
      }
      else if (bookDetails.status==='checkedin') {
          return([
              <Button variant="outlined" onClick={()=>{setOpen(false); setCurrentNote('')}}startIcon={<ArrowBackIosNewIcon />}>Back </Button>,
              <Button variant="contained" onClick={handleAction(bookDetails.status)} endIcon={<CloseIcon/>}>Close </Button>
          ])
      }
  }

  const Body = ({currentNote, setCurrentNote, advance, setAdvance, productDetails, bookDetails, handleLabelClick, checkError, setCheckError, checkWarn, setCheckWarn, checks}) => {
    return (
      <div style={styleObj.delDialogCont}>
        <div style={styleObj.delDialogLeft}>
          {bookDetails.status === 'checkedin' && <Cashdetails bookDetails={bookDetails}/>}
          {bookDetails.status !== 'checkedin' && productDetails.map(product=><IndProdCheck key={`check-${product._id}`} checks={checks} product={product} handleLabelClick={handleLabelClick} checkError={checkError} setCheckError={setCheckError} checkWarn={checkWarn} setCheckWarn={setCheckWarn} status={bookDetails.status} bookDetail={bookDetails.items.find(item => item.product === product._id)}/>)}
        </div>
        <div style={styleObj.delDialogRight}>
        {bookDetails.status === 'booked' && bookDetails.advance === 0 && <div style={{marginBottom:'10px'}}> <TextField  value={advance} onChange={(e)=>{setAdvance(e.target.value)}} label="Advance (₹)" /></div>}
        <Notes currentNote={currentNote} setCurrentNote={setCurrentNote} bookDetails={bookDetails}/>
        </div>
      </div>
    )
  }

  const IndProdCheck = ({product, handleLabelClick, checkWarn, setCheckWarn, checkError, setCheckError, checks, status, bookDetail}) => {
    return (
      <div>
        <Stack direction="row" alignItems="center">
          <Typography variant="h6" sx={{ fontWeight: "bold" }} gutterBottom component="div">{product.name}</Typography>
          <Typography sx={{ ml: 2 }} variant="body" gutterBottom component="div">({bookDetail?.sku})</Typography>
        </Stack>
        <CheckBoxes product={product} handleLabelClick={handleLabelClick} status={status} checks={checks}/>
        <SnackBar message={status === 'booked' ? 'You must select at least one item' : 'You must complete the check list'} open={checkError} handleClose={()=>{setCheckError(false)}} severity={"error"}/>
        <SnackBar message={'CheckList is not complete. If you want to checkout with partial list, click checkout again'} open={checkWarn} severity={"warning"}/>
      </div>
    )
  }
  const CheckBoxes = ({status, product, checks, handleLabelClick}) => {
    if (status === "checkedout") {
        let checkedItems = []
        for (const checkItem in checks) {
          const [productId, ...rest] = checkItem.split("_")
          const itemName = rest.join(" ")
          const productIncludes = product.includes.find(incl=> incl.item === itemName)
          console.log(itemName, productIncludes, productId, product._id, product.name, product.includes)
          if (productId === product._id && productIncludes) {
            checkedItems.push({item: itemName, count: productIncludes.count})
          }
        }
        /*const checkedItems = Object.keys(checks).map(key => {
            const items = key.split("_");
            items.splice(0,1)
            return items.join(" ")
        }).map(item => {
            console.log(item)
            return product.includes.find(incl => {
              return incl.item === item
            })
        }).filter(item => item !== undefined)*/



        /*console.log(checks)
        console.log("---")
        console.log(product)*/
        return (
            <FormGroup sx={styleObj.checkProd}>
                {checkedItems.map(incl=> (<FormControlLabel control={<Checkbox onChange={handleLabelClick(incl?.item, product._id)} checked={checks[incl?.item?.replace(/ /g, '_')]}/>} label={`${incl?.count} x ${incl?.item} `} />))}
            </FormGroup>
        )
    }
    if (status === "booked") {
        return (
            <FormGroup sx={styleObj.checkProd}>
                {product.includes.map(incl=> (<FormControlLabel control={<Checkbox onChange={handleLabelClick(incl?.item, product._id)} checked={checks[incl?.item?.replace(/ /g, '_')]}/>} label={`${incl?.count} x ${incl?.item} `} />))}
            </FormGroup>
        )
    }
  }
const CheckoutDialog = ({open, advance, setAdvance, bookings, setOpen, doCheckout, doCheckIn, doClose, selectedBooking, currentNote, setCurrentNote, setSelectedBooking}) => {
    const [productDetails, setProductDetails] = useState([])
    const [bookDetails, setBookDetails] = useState({});
    const [checks, setChecks] = useState({})
    const [checkWarn, setCheckWarn] = useState(false);
    const [checkError, setCheckError] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    useEffect(()=>{
      const getData = async () => {
        const book = bookings.find(book=>book._id === selectedBooking)
        setBookDetails(book)
        const ids = book.products.map(x=>x.id)
        const data = await getMultipleProducts(ids);
        setProductDetails(data)

        if (book.status === 'booked') {
            const items = data.flatMap(x=>x.includes.map(inc=>x._id + '_' + inc.item.replace(/ /g, '_')))
            let _checks = {};
            items.forEach(item=> {
              _checks[item] = false
            })
            console.log(_checks)
            setChecks(_checks)
            setDialogTitle('Check Out')
        }
        if (book.status === 'checkedout') {
          console.log(book.checks, checks)
            const savedChecks= Object.fromEntries(
                Object.entries(book.checks ?? checks)
                .filter(([key, value]) => value === true)
                .map(([key, value]) =>  [key, false]) 
            );
            console.log(savedChecks)
            setChecks(savedChecks)
            setDialogTitle('Check In')
        }
        if (book.status === 'checkedin') {
            setDialogTitle('Close')
        }
        
      }
      if (open) {
        getData()
      }
        return () => {
            setCheckWarn(false)
            setCheckError(false)
            setChecks([])
        }
    }, [open, selectedBooking])

    useEffect(()=>{
      console.log("checks", checks)
    }, [checks])

    const handleLabelClick = (item, id) => {      
      if (item) {
        return (event) => {
          let _checks  = {...checks};
          _checks[id + '_' + item.replace(/ /g, '_')] = event.target.checked
          setChecks(_checks)
        };
      }
    }



    /*return (
      <DialogWindow title={dialogTitle} Body={Body} currentNote={currentNote} setCurrentNote={setCurrentNote} Actions={Actions} open={open} setOpen={setOpen}  maxWidth={'sm'}/>
    )*/
    return (
      <Dialog
        onClose={()=>{setOpen(false)}}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={()=>{setOpen(false)}}>
          {dialogTitle}
        </BootstrapDialogTitle>
        <DialogContent dividers>
         <Body
          checkWarn={checkWarn}
          setCheckWarn={setCheckWarn}
          currentNote={currentNote}
          advance={advance}
          setAdvance={setAdvance}
          setCurrentNote={setCurrentNote}
          productDetails={productDetails}
          bookDetails={bookDetails}
          handleLabelClick={handleLabelClick}
          checkError={checkError}
          setCheckError={setCheckError}
          checks={checks}
        />

        </DialogContent>
        <DialogActions>
          <Actions setCheckWarn={setCheckWarn} checkWarn={checkWarn} setChecks={setChecks} setSelectedBooking={setSelectedBooking} setCurrentNote={setCurrentNote} bookDetails={bookDetails} checkError={checkError} setCheckError={setCheckError} checks={checks} setOpen={setOpen} doCheckout={doCheckout} doCheckIn={doCheckIn} doClose={doClose}/>
        </DialogActions>
      </Dialog>
    )
  }


  export default CheckoutDialog
