import React, { useEffect, useState } from 'react';
import { Container, Card, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography, TextField, Autocomplete } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PersonIcon from '@mui/icons-material/Person';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

import {getAvailableProducts, getMultipleProducts} from '../services/products.js';
import {SnackBar} from  './general';

import moment from 'moment';

import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { editBook, getBookingById } from '../services/booking.js';



const classes = {
  body:{
    width:'100%',
    display:'flex',
    height: '100%'
  },
  dateCont: {
      display: 'flex',
      marginBottom: 10
  },
  dateInd: {
      marginRight: 20
  },
  tenantCont: {
      display: 'flex',
      alignItems: 'center',
      marginBottom : 20
  },
  itemCont: {
    marginBottom : 20
  },
  left: {
      width: '55%'
  },
  right: {
    width: '50%',
    position: 'relative',
    height: 'calc(100% - 55px)',
    paddingBottom: '55px',
  },
  selItemCont: {
    display: 'flex',
    marginBottom:5
  },
  selItemImg: {
    width: 100,
    objectFit:'contain'
  },
  selItemImgCont: {
    marginRight: 10,
    marginBottom: 5
  },
  selItemDetCont: {
    marginTop:5
  },
  selItemActionCont: {
    display: 'flex',
    alignItems: 'center'
  },
  totalCont: {
    position:'absolute',
    bottom:0,
    width: '100%'
  },
  selTotCont: {
    display: 'flex',
    height: 50,
    alignItems:'center',
  },
  totalEl: {
    marginRight: 10,
    color: 'black',
    width:'100px'
  },
  dialoge: {
    height:'100%',
    maxHeight: '500px'
}, 
};

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}



const DateBar = ({fromDate, toDate, diff}) => {
    
    return (
        <div style={classes.dateCont}>
            <div style={classes.dateInd}>
                <CalendarTodayIcon/>
            </div>
            <div style={classes.dateInd}>
               {moment(fromDate).format('DD-MM-YYYY')}
            </div>
            <div style={classes.dateInd}> to </div>
            <div style={classes.dateInd}>
                {moment(toDate).format('DD-MM-YYYY')}
            </div>
            <Typography variant="caption" display="block" gutterBottom component="div">{diff}</Typography>
      </div>
    )
}

const Tenant = ({tenant={}}) => {
    
    return (
        <div style={classes.tenantCont}>
            <div style={classes.dateInd}><PersonIcon/></div>
            <div>{tenant.name}</div>
        </div>
      );
}

const ItemSelect = ({items, displayItem, setDisplayItem, setSelectedItems, selectedItems}) => {
    
    const handleSelect = (event, value, reason) => {
        if (value) {
            value.count = 1;
            const index = selectedItems.findIndex(selItem =>selItem._id === value._id)
            if (index < 0) {
                const _selectedItems = [...selectedItems, value]
                setSelectedItems(_selectedItems)
            }
            setDisplayItem({name: ''})
        }
    }
    
    return (
        <div style={classes.itemCont}>
            <Autocomplete
            disablePortal
            ListboxProps={{
              style: {
              maxHeight:'200px'
              }
            }}
            getOptionLabel={option => option.name}
            id="combo-box-item"
            clearIcon={null}
            value={displayItem}
            onInputChange={(event, value, reason)=>{setDisplayItem({name: value})}}
            onChange={handleSelect}
            options={items}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Item" />}
            />
        </div>
      );
}

const SelectedItemsCard = ({existingProducts, product, selectedItems, setSelectedItems}) => {
  
  const handleRemove = (id) => {
      return (e) => {
        const index = selectedItems.findIndex(selItem =>selItem._id === id)
        if (index > -1) {
          const _selectedItems = [...selectedItems];
          _selectedItems.splice(index,1);
          setSelectedItems(_selectedItems)
        }
      }
    }
    const increment = (id) => {
      return (e) => {
        const index = selectedItems.findIndex(selItem =>selItem._id === id)
        if (index > -1 && selectedItems[index].count < selectedItems[index].availableItemsCount) {
          const _selectedItems = [...selectedItems];
          _selectedItems[index].count += 1
          setSelectedItems(_selectedItems)
        }
      }
    }
    const decrement = (id) => {
      return (e) => {
        const index = selectedItems.findIndex(selItem =>selItem._id === id)
        if (index > -1 && selectedItems[index].count > 1) {
          const _selectedItems = [...selectedItems];
          _selectedItems[index].count -= 1
          setSelectedItems(_selectedItems)
        }
      }
    }
    //console.log(product)
    //const productDetail = existingProducts.find(x=>x._id === product.id);
    let rent, availableItemsCount;
    if (product.rent) {
        rent = product.rent
    } else {
        //rent = productDetail.rent
    }
    if (product.availableItemsCount) { 
        availableItemsCount = product.availableItemsCount
    } else {
        //availableItemsCount = productDetail.availableItemsCount
    }
  return(
    <Card sx={classes.selItemCont}>
      <div style={classes.selItemImgCont}><img style={classes.selItemImg} src={product.image.thumb}/></div>
      <div style={classes.selItemDetCont}>
        <Typography variant="subtitle2" gutterBottom component="div">{product.name}</Typography>
        <Typography variant="caption" display="block" gutterBottom component="div">₹ {rent} /-</Typography>
      </div>
      <div style={classes.selItemActionCont}>
        <div><IconButton disabled={product.count === 1} onClick={decrement(product._id)}><RemoveIcon/></IconButton></div>
        <div>{product.count}</div>
        <div><IconButton disabled={product.count === availableItemsCount} onClick={increment(product._id)}><AddIcon/></IconButton></div>
        <div><IconButton  onClick={handleRemove(product._id)}><DeleteIcon/></IconButton></div>
      </div>
    </Card>
  )
}

const TotalCard = ({selectedItems=[], advance, existingProducts, numberofDays, setAmount, discount, setDiscount}) => {
  const [total, setTotal] = useState(0);
  
  useEffect(()=>{
    setAmount(total-discount);
  },[discount, total])
  useEffect(()=>{
    const _total = selectedItems.reduce((acc, item)=>{
            let rent;
            /*if(item.rent) {
                rent = parseInt(item.rent, 10)
            } else {
                const prodDetail = existingProducts.find(x=>x._id === item.id);
                rent = parseInt(prodDetail.rent, 10)
            }*/
            rent = item.rent || 0;
            return acc+(rent * numberofDays*item.count)
    }, 0)
    setTotal(_total)
  },[selectedItems, numberofDays])
  if (Array.isArray(selectedItems) && selectedItems.length > 0) {
    return (
      <Card sx={classes.selTotCont}>
        <div style={classes.totalEl}><TextField variant='standard' sx={classes.totalEl} disabled={true} label="Gross Total" value={`₹ ${total}/-`} /></div>
        <div style={classes.totalEl}> <TextField variant='standard' sx={classes.totalEl} value={discount} onChange={(e)=>{setDiscount(e.target.value)}}  label="Discount (₹)" /></div>
        <div style={classes.totalEl}> <TextField variant='standard' sx={classes.totalEl} value={advance} disabled={true} label="Advance (₹)" /></div>
        <div style={classes.totalEl}><TextField variant='standard' sx={classes.totalEl} disabled={true} label="Net Total" value={`₹ ${total - discount}/-`} /></div>
      </Card>
    )
  }
  return null;
}

export default function CustomizedDialogs(props) {
    const [editLoad, setEditLoad] = useState(false);
    const [bookData, setBookData] = useState({});
    const [selectedItems, setSelectedItems] = useState([]);
    const [displayItem, setDisplayItem] = useState({name:''});
    const [existingProducts, setExistingProducts] = useState([])
    const [items, setItems] = useState([])
    const [numberofDays, setNumberofDays] = useState(1)
    const [note, setNote] = useState('');
    const [amount, setAmount] = useState(0)
    const [discount, setDiscount] = useState(0);
    const [advance, setAdvance] = useState(0);
    const [snackMessage, setSnackMessage] = useState('');
    const [snackType, setSnackType]= useState('error');
    const [showSnack, setShowSnack]= useState(false);
    const saveTitle = 'Update'
    const isMobile = getWindowDimensions().width < 500 ? true : false;
    React.useEffect(()=>{
      if (props.id) {
          const getBookingData  = async(id) => {
            const data =  await getBookingById(id);
            const existingProdIds = data.products.map(x=>x.id)
            const existingProds = await getMultipleProducts(existingProdIds)
            //setExistingProducts(existingProds)
            setFields(data);
            setDiscount(data.discount)
            setBookData(data);
          }
          getBookingData(props.id)          
      }
    }, [props.id])
    useEffect(()=>{
        const getData = async() => {
            if (moment(bookData.to).startOf('day').isSameOrAfter(moment(bookData.from).startOf('day'))) {
                setNumberofDays(moment(bookData.to).startOf('day').diff(moment(bookData.from).startOf('day'), 'days')+1)
                const data = await getAvailableProducts(moment(bookData.from).format('MM-DD-YYYY'), moment(bookData.to).format('MM-DD-YYYY'));
                const _selectedItems = []
                //setSelectedItems([])
                if (Array.isArray(bookData.products)) {
                    bookData.products.forEach(bookedProduct => {
                        const alreadyBooked = data.find(x=>x._id === bookedProduct.id);
                        if (alreadyBooked) {
                            alreadyBooked.availableItemsCount += bookedProduct.count
                            alreadyBooked.count = bookedProduct.count 
                            _selectedItems.push(alreadyBooked)
                        } else {
                            bookedProduct._id = bookedProduct.id;
                            bookedProduct.availableItemsCount = 1;
                            bookedProduct.rent = bookData.items.find(x=>x.product === bookedProduct.id).rent;
                            data.push(bookedProduct)
                            _selectedItems.push(bookedProduct)
                        }
                    })
                }
                setItems(data)
                setSelectedItems(_selectedItems)
            }
        }
        getData()
    }, [bookData])
    useEffect(() => {        
        setSelectedItems(bookData?.products ?? [])
    }, [bookData.products])
    const resetFields = () => {      
      setAdvance(0);
      setDiscount(0);
    }
    const setFields = (data) => {
        
      
    }
    const handleAddBookingClose = (e) => {
        props.setShowEditBooking(false);
        props.setEditableBooking(undefined);
        resetFields()
    }
    const handleClick =()=> {
        return async (e) => {
          if (Array.isArray(selectedItems) && selectedItems.length > 0 ) {
            const data = {
              products: selectedItems.map(item=>{return{id: item._id, count: item.count}}),
              amount:amount,
              discount:discount,
            }
            try {
              setEditLoad(true)
              const resp = await editBook(props.id, data)
              props.setBookingEdited()
              //setSnackMessage('Edited booking')
              //setSnackType('success')
              //setShowSnack(true)
              handleAddBookingClose();
            } catch (e) {
              setEditLoad(false)
              console.log(e)
              setSnackMessage('Could not edit book')
              setSnackType('error')
              setShowSnack(true)
            }
          } else {
            setSnackMessage('Please select at least one item')
            setSnackType('error')
            setShowSnack(true)
          }
        }
    }

    const diffTitle = numberofDays > 1 ? 'days' : 'day';
    let dayName ='';
    for (var m = moment(bookData.from); m.diff(bookData.to, 'days') <= 0; m.add(1, 'days')) {
      dayName += m.format('ddd') + ', ';
    }
    dayName = dayName.slice(0, -2); 
  return (
    <div>
      <Dialog  classes={{ paper: classes.dialoge}} onClose={handleAddBookingClose} fullWidth={true}
        maxWidth={"md"} aria-labelledby="customized-dialog-title" open={props.open}>
        <DialogTitle id="customized-dialog-title" onClose={handleAddBookingClose}>
          Edit Booking
        </DialogTitle>
        <DialogContent dividers style={{height: '100%'}}>
            <Container sx={classes.body} style={isMobile ? {flexDirection:'column'} : {}}>
                <div  style={isMobile ? {...classes.left, width:'100%'} : classes.left}>
                    <DateBar fromDate={bookData.from} toDate={bookData.to} diff={` ${numberofDays} ${diffTitle} (${dayName})`}/>
                    <Tenant tenant={bookData.tenant}/>
                    <div style={classes.tenantCont}><div style={classes.dateInd}><TextSnippetIcon/></div> <div>{bookData?.notes?.bookingNotes || 'No Booking Notes'}</div></div>
                    <ItemSelect items={items} selectedItems={selectedItems} setSelectedItems={setSelectedItems} displayItem={displayItem} setDisplayItem={setDisplayItem}/>
                </div>
                <div style={isMobile ? {...classes.right, width:'100%'} : classes.right}>
                  <div style={{height:'calc(100%)', overflowY:'scroll'}}>
                    {selectedItems.map(sel=><SelectedItemsCard existingProducts={existingProducts} selectedItems={selectedItems} setSelectedItems={setSelectedItems} key={sel.id} product={sel}/>)}
                  </div>
                    <div style={classes.totalCont}>
                      <TotalCard advance={bookData.advance} discount={discount} setDiscount={setDiscount} setAmount={setAmount} existingProducts={existingProducts} numberofDays={numberofDays} selectedItems={selectedItems}/>
                    </div>
                </div>
            </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddBookingClose} color="secondary">
            Cancel
          </Button>
          <Button autoFocus onClick={handleClick()} color="primary">
            {saveTitle}
          </Button>
        </DialogActions>
      </Dialog>
      <SnackBar message={snackMessage} open={showSnack} handleClose={()=>{setShowSnack(false)}} severity={snackType}/>
    </div>
  );
}
