import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import {getStore} from '../util';
const user = getStore('user');

const socket = io('/', {transports: ['websocket'], path : '/socket-io', query:{room:user?.user?.store}});

socket.on('connect', ()=> {
    console.log('Connected')
})
socket.on("connect_error", (error) => {
   console.log('socket connection error:', error) // socket connection error: server error
}); // this event is triggered

export default socket;
