import React from 'react';
import { Snackbar, Select, MenuItem, FormControl, InputLabel, Alert } from '@mui/material';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';




const SnackBar = ({open, handleClose, severity="success", message}) => {
  return (<Snackbar
    open={open}
    anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
    autoHideDuration={6000}
    onClose={handleClose}
    
  >
    <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
      {message}
    </Alert>
  </Snackbar>)
}

const GenderSelect = (props) => {
    return (
        <FormControl sx={{
          minWidth:100,
          marginRight:'25px'
        }}>
        <InputLabel id="demo-simple-select-label">Gender</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props.gender}
          onChange={props.handleGender()}
        >
          <MenuItem value={"Male"}>Male</MenuItem>
          <MenuItem value={"Female"}>Female</MenuItem>
          <MenuItem value={"Others"}>Others</MenuItem>
        </Select>
      </FormControl>
    )
}
const TypeSelect = (props) => {
    console.log(props.type)
    return (
        <FormControl>
        <InputLabel id="demo-simple-select-label">Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props.type}
          onChange={props.handleType()}
        >
          <MenuItem value={"Chef"}>Chef</MenuItem>
          <MenuItem value={"Waiter"}>Waiter</MenuItem>
        </Select>
      </FormControl>
    )
}

const CustomAvatar = ({src, name, style}) => {
  function stringToColor(string) {
    if (string) {
      let hash = 0;
      let i;
    
      /* eslint-disable no-bitwise */
      for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
      }
    
      let color = '#';
    
      for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
      }
      /* eslint-enable no-bitwise */
    
      return color;
    }
  }
    
  function stringAvatar(name='') {
    if (name) {
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')?.[1]?.[0]??''}`,
      };
    }
  }

  if (src) {
      return (<Avatar sx={{
        margin: '0px 10px'
      }} style={style} alt={name} src={src} />)
  }
  return (<Avatar style={{
    ...style, 
    margin: '0px 10px'
  }} {...stringAvatar(name)} />)
}

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const DialogWindow = ({title, Body, Actions, open, setOpen, maxWidth}) => {
  return (
      <Dialog
        onClose={()=>{setOpen(false)}}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={maxWidth}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={()=>{setOpen(false)}}>
          {title}
        </BootstrapDialogTitle>
        <DialogContent dividers>
         {Body}
        </DialogContent>
        {Actions && <DialogActions>
          {<Actions/>}
        </DialogActions>}
      </Dialog>
  );
}

const ConfirmDialog = ({title, message, confirmAction, show, handleClose}) => {
  return (
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={confirmAction} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

  );
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


export {SnackBar, GenderSelect, TypeSelect, DialogWindow, CustomAvatar, ConfirmDialog, getWindowDimensions}