import { Types } from '../constants/actionTypes';
import { getStore } from '../util';
const {accessToken='', user={}} = getStore('user') || {}
const initialState = {
  profile: {
    loading: false,
    username:'',
    password:'',
    accessToken:accessToken,
    user: user,
    error:undefined
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.LOGIN_SUCCESS:
    console.log('login', action.payload.user)
      return {
        ...state,
        profile: {accessToken:action.payload.user.accessToken, user: action.payload.user.user},
        loading: false,
        error: undefined
      }
    case Types.AUTH_LOAD: 
      return {
        ...state,
        loading: true,
      }
    case Types.LOGIN_FAIL:
    console.log(action.payload)
      return {
        ...state,
        loading: false,
        error:action.payload
      }
    case Types.LOGOUT_UNAUTH:
      return {
          ...state,
          loading: false,
          profile:{accessToken:undefined, user:undefined },
      }
    default:
      return state;
  }
}

export default reducer;