import React from 'react';
import { Container, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SendIcon from '@mui/icons-material/Send';

import {checkUserExists, accreditUser} from '../services/user.js';
import {SnackBar} from  '../components/general.js';
import {getStore, getWindowDimensions} from '../util';
const Input = styled('input')({
  display: 'none',
});

const styles = {
  root: {
    margin: 0,
    padding:2,
  },
  closeButton: {
    position: 'absolute',
    right: 1,
    top: 1,
    color: 'palette.grey[500]',
  },
};

const classes = {
  body:{
    width:'100%',
    display:'flex',
    flexDirection: 'row'
  },
  inputCont: {
      width: '60%',
      display: 'flex',
      alignItems: 'center'
  },
  sendIcon: {
      cursor: 'pointer'
  },
  detCont:{display:'flex', width: '100%'},
  left: {
      width: '60%',
      display: 'table',
      borderCollapse: 'separate'
    },
  right: {
    width: '40%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  profImgCont: {borderRadius: '50%', overflow: 'hidden', height:'150px', width: '150px', marginBottom: '20px'},
  profImg: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    objectFit: 'cover'
  },
  detIndCont: {
      display: 'table-row'
  },
  detInd: {
      display: 'table-cell',
      padding: '0px 10px 10px 0px'
  }
};

const UserNotFound = () => {
    return (
        <div>
            <div>The user you searched for is not found. Please add the user if required</div>
        </div>
    )
}
const UserFound = ({user, isMobile, setShowProof}) => {
    
    return (
        <div style={isMobile ? {...classes.detCont, flexDirection:'column-reverse'}: classes.detCont}>
            <div style={isMobile ? {...classes.left, width:'100%'} : classes.left}>
                <div style={classes.detIndCont}><div style={classes.detInd}>Name</div> <div style={classes.detInd}>{user.name}</div></div>
                <div style={classes.detIndCont}><div style={classes.detInd}>Address</div> <div style={classes.detInd}>{user.address || 'NA'}</div></div>
                <div style={classes.detIndCont}><div style={classes.detInd}>Phone No</div> <div style={classes.detInd}>{user.contactNumber}</div></div>
                <div style={classes.detIndCont}><div style={classes.detInd}>Email</div> <div style={classes.detInd}>{user.email}</div></div>
                <div style={classes.detIndCont}><div style={classes.detInd}>AKPA No</div> <div style={classes.detInd}>{user.akpano}</div></div>
                {/*<div style={classes.detIndCont}><div style={classes.detInd}>Affiliated Stores</div> <div style={classes.detInd}>{user.trustedStore.map(x=><div>{x.name}</div>)}</div></div>*/}
            </div>
            <div style={isMobile ? {...classes.right, width:'100%'} : classes.right}>
                <div style={classes.profImgCont}>{user.image.thumb && <img style={classes.profImg} src={user.image.thumb}/>}</div>
                <div>{user.proof && <img onClick={()=>{setShowProof(true)}} height='150' src={user.proof?.front?.thumb}/>}</div>
            </div>
        </div>
    )
}

const Actions = ({store, userNotFound, user, handleCheckUserClose, handleClick, showAddUser, doAccreditUser}) =>{
    if (user) {
        return(
            <DialogActions>
                <Button onClick={handleCheckUserClose} color="secondary">
                    Cancel
                </Button>
                <Button disabled={Boolean(user.trustedStore.find(x=>x._id===store))} autoFocus onClick={doAccreditUser} color="primary">
                    Accredit User
                </Button>
            </DialogActions>
        )
    }
    if (userNotFound) {
        return(
            <DialogActions>
                <Button onClick={handleCheckUserClose} color="secondary">
                    Cancel
                </Button>
                <Button autoFocus onClick={showAddUser} color="primary">
                    Add User
                </Button>
            </DialogActions>
        )
    }
    return null
}

const DialogTitleComponent =(props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography style={{display: 'flex', position: 'relative'}} className={styles.root} {...other}>
      <Typography variant="h6" >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close"  style={{right: '25px', position: 'absolute'}} className={styles.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function CustomizedDialogs(props) {
    const [val, setVal] = React.useState('');
    const [showSnack, setShowSnack] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState('');
    const [user, setUser] = React.useState();
    const [userNotFound, setUserNotFound] = React.useState(false);
    const [showProof, setShowProof] = React.useState(false);
    const store = getStore('user')?.user?.store
    const dim = getWindowDimensions();
    const isMobile = dim.width < 500 ? true : false
    const handleCheckUserClose = (e) => {
        setUser(undefined)
        setUserNotFound(false);
        setVal('')
        props.checkUserClose()(e);
    }
    const handleClick = async(e)=> {
        if (val) {
            try {
                const _user = await checkUserExists(val);
                if (_user){
                    console.log(_user)
                    setUserNotFound(false)
                    setUser(_user)
                } else {
                    setUserNotFound(true)
                }
            } catch (e) {
                if(e.status === 404) {
                    setUserNotFound(true)
                } else {
                    setSnackMessage(`Something went wrong`)
                    setShowSnack(true)
                }
            }
        } else {
            console.log('Mandatory details required');
            setSnackMessage(`Mandatory details required`)
            setShowSnack(true)
        }
        
    }
const doAccreditUser = async () => {
    try {
        await accreditUser(user._id);
        handleCheckUserClose();
        props.setUsersChange('Added')
    } catch (e) {
        setSnackMessage(`Something went wrong`)
        setShowSnack(true)
    }
}

    const showAddUser = () => {
        handleCheckUserClose();
        props.openAddUser();
    }
  return (
    <div>
      <Dialog onClose={handleCheckUserClose} fullWidth={true}
        maxWidth={"md"} aria-labelledby="customized-dialog-title" open={props.checkUserOpen}>
        <DialogTitleComponent id="customized-dialog-title" onClose={handleCheckUserClose}>
          Add New user
        </DialogTitleComponent>
        <DialogContent dividers>
            <Container sx={classes.body}>
                    {!(userNotFound || user)&& (
                        <div style={isMobile ? {...classes.inputCont, width: '100%'} : classes.inputCont}>
                            <TextField variant='standard' value={val} fullWidth={true} id="txt-name" label="Email / Phone Number" onChange={(e)=>{setVal(e.target.value)}}/>
                            <SendIcon onClick={handleClick} sx={classes.sendIcon}/>
                        </div>)
                    }
                    {userNotFound && <UserNotFound/>}
                    {user && <UserFound isMobile={isMobile} user={user} setShowProof={setShowProof}/>}
            </Container>
            
        </DialogContent>
       <Actions doAccreditUser={doAccreditUser} showAddUser={showAddUser} store={store} userNotFound={userNotFound} user={user} handleCheckUserClose={handleCheckUserClose} handleClick={handleClick}/>
      </Dialog>
      <SnackBar message={snackMessage} open={showSnack} handleClose={()=>{setShowSnack(false)}}/>
      {showProof && (<div style={ isMobile ? {height:'90vh', minWidth:'90wv', maxWidth:'90vw', display:'flex',  zIndex: 1301, left : '5vw', top:'5vh', position:'absolute'} : {height:'90vh', minWidth:'50wv', maxWidth:'90vw', display:'flex',  zIndex: 1301, left : '40%', top:'5vh', position:'absolute'}}>
                <img style={{objectFit: 'contain', width:'100%', height:'100%', overflow:'hidden'}} src={user.proof?.front?.url}/>
                <div style={{cursor:'pointer', color:'black', marginLeft:'10px'}} onClick={()=>{setShowProof(false) }}><HighlightOffIcon /></div>
            </div>)}
    </div>
  );
}
