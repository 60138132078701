import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch, useSelector  } from 'react-redux';
import { loginAction } from '../actions/profile';
import { getStore, setStore } from '../util';
import {SnackBar} from  '../components/general.js';

import {Grid, Paper, Avatar, Button, CssBaseline, TextField, Typography} from '@mui/material';

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { style } from '@mui/system';

import CircularProgress from '@mui/material/CircularProgress';

const styles = {
  root: {
    height: "100vh",
  },
  image: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: '8px 4px',
    padding:'0px 10px',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent:'center',
    height:'80%'
  },
  avatar: {
    margin: '',
    backgroundColor: 'secondary.main'
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: 1
  },
  submit: {
    margin: '3, 0, 2'
  }
};

class Login extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      errors: {
        username: 'Enter User Name!',
        password: 'Enter Password!'
      },
      loginStatus: '',
      submitted: false,
      showSnack: false,
      snackMessage: '',
      snackType:'success',
    }    
  }
  
  passwordChange = (event) => {
    const {  value } = event.target;
    this.setState({ 'password': value });
  }
  usernameChange = (event) => {
    const {  value } = event.target;
    this.setState({ 'username': value });
  }
  validationErrorMessage = (event) => {
    const { name, value } = event;
    let errors = this.state.errors;
    switch (name) {
      case 'username': 
        errors.username = value.length < 1 ? 'Enter User Name' : '';
        break;
      case 'password': 
        errors.password = value.length < 1 ? 'Enter Password' : '';
        break;
      default:
        break;
    }
    this.setState({ errors });
  }
    validateForm = (errors) => {
    let valid = true;
    console.log(errors)
    Object.entries(errors).forEach(item => {
      console.log(item)
      item && item[1].length > 0 && (valid = false)
    })
    console.log(valid)
    return valid;
  }
  listener = event => {
    if (event.code === "Enter" || event.keyCode === 13) {      
      this.doLogin(event)
      // callMyFunction();
    }
  };
    doLogin = async (e) => {
      e.preventDefault();
      this.props.dispatch(loginAction({username:this.state.username, password:this.state.password}))
  };
  handleSnackClose = () => {
    this.setState({showSnack:false})
  }
  componentDidMount () {
    document.addEventListener("keydown", this.listener);
  }
  componentWillUnmount () {
    document.removeEventListener("keydown", this.listener);
  }
  componentDidUpdate(prevProps) {
    
    if (this.props.profile?.error && prevProps?.profile?.error !== this.props.profile?.error) {
      console.log(prevProps, this.props)
      this.setState({showSnack: true, snackMessage: 'Error in Logging in', snackType: 'error'})
    }
  }

    render = ()=> {
      console.log(this.props.profile)
      return (
        <Grid container component="main" sx={styles.root}>
          <CssBaseline />
          <Grid item xs={false} sm={4} md={7}  sx={styles.image} />
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <div style={styles.paper}>
              <Avatar sx={styles.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <div style={styles.form} >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  onChange={(e) => {this.usernameChange(e)}}
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  onChange={(e) => {this.passwordChange(e)}}
                  autoComplete="current-password"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={this.doLogin}
                  sx={styles.submit}
                >
                  Sign In
                  {this.props.profile.loading && <CircularProgress size={20} sx={{ color: 'white', marginLeft:'20px' }}/>}
                </Button>
              </div>
            </div>
          </Grid>
          <SnackBar message={this.state.snackMessage} open={this.state.showSnack} severity={this.state.snackType} handleClose={this.handleSnackClose} />
        </Grid>
      );
    };
};


const mapStateToProps = (state) => {
  return {
    profile: state.user
  }
}

export default connect(mapStateToProps)(Login);
