import React, { Component } from 'react';
import {Grid} from '@mui/material';
import  AppBar  from '../components/appbar.js';
import  SideBar  from '../components/sidebar.js';
import WeeklyTopBar from '../components/weeklyTopBar.js'
import WeeklyView from '../components/weeklyView.js'


const classes = {
  root: {
    flexDirection: "column",
  },
  subcontainer:{
    width: '100%',
    margin:'10px 10px 0px 10px'
  },
  image: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: '8, 4',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: 1,
    backgroundColor: 'secondary.main'
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: 1
  },
  submit: {
    margin: '3, 0, 2'
  },
  container : {
    display: "flex",
    flexDirection: "row",
    width: '100%',
  },
  subcontainer:{    
    marginLeft: 10,
    marginTop: 10,
    width: '100%' 
  }
};


class Home extends Component { 
  constructor(props) {
    super(props);
    this.state = {
          type: 'booking',
          selectedStatuses:['booked', 'checkedin', 'checkedout', 'closed'],
          includeCancel: false,
          selectedType:'All' 
    }
    
  }
  componentDidMount() {
      
        
    }
    setSelectedType = (_type) => {
      this.setState({selectedType: _type})
    }
    toggleIncludeCancel = () => {
      this.setState({includeCancel:  !this.state.includeCancel})
    }
    setSelectedStatuses = (e, newStatuses) => {
      this.setState({selectedStatuses: newStatuses})
    }
    toggleType = () => {
      if (this.state.type === "booking") {
        this.setState({type: 'product'})
      } else {
        this.setState({type: 'booking'})
      }
    }
    render = ()=> {
      return (
        <Grid container component="main" sx={classes.root}>
            <AppBar/>
            <div style={classes.container}>
              <SideBar/>
              <div style={classes.subcontainer}>
                <WeeklyTopBar selectedType={this.state.selectedType} setSelectedType={this.setSelectedType} toggleIncludeCancel={this.toggleIncludeCancel} includeCancel={this.state.includeCancel} selectedStatuses={this.state.selectedStatuses} setSelectedStatuses={this.setSelectedStatuses} type={this.state.type} toggleType={this.toggleType}/>
                <WeeklyView selectedType={this.state.selectedType} selectedStatuses={this.state.selectedStatuses}  includeCancel={this.state.includeCancel} type={this.state.type}/>
              </div>
            </div>
        </Grid>
      );
    };
};


export default Home;
