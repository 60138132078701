import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import profile from '../reducers/profile.js';
import products from '../reducers/products.js';
import item from '../reducers/item.js';

const rootReducer = combineReducers({
  user: profile,
  products:products,
  item:item
});

const configureStore = () => {
  return createStore(
    rootReducer,
    compose(applyMiddleware(thunk))
  );
};

export default configureStore;