import React, {useState, useEffect} from 'react';
import moment from 'moment';

import {DialogWindow, CustomAvatar} from  './general';
import { getTicketId } from '../util';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import clsx from  'clsx';
import Chip from '@mui/material/Chip';
import Badge from '@mui/material/Badge';
import Stepper from './customStepper.js'
import { ConstructionOutlined } from '@mui/icons-material';
import { flexbox } from '@mui/system';

const classes = {
    root: {
      width: '100%',
      position:'relative',
      minHeight: 'calc(100vh - 200px)'
    },
    firstRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 20

    },
    tenant :{
        display: 'flex'
    },
    rowInd: {
        margin: '0px 10px'
    },
    chip: {
        fontWeight: 'bold'
    },
    rowItem: {
        fontSize: '14px',
        marginBottom: '5px'
    },
    dates: {
        display:'flex',
        alignItems: 'center',
        fontSize:"14px"
    },
    smallfont: {
        fontSize: '12px',
    },
    cashCont: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        marginBottom: '15px',
        fontSize: 14,
        width: '60%'
    },
    prodCont: {
      display: 'flex',
      flexDirection:'column',
      width: '50%'
    },
    indProductCont: {
      display: 'flex',
      alignItems: 'center'
    },
    indProductItem: {
      marginLeft: '10px'
    },
    bottomRow: {
      display: 'flex',
      marginTop: '20px',
    },
    noteCont:{
      display: flexbox,
      width:'50%'
    },
    indNoteCont: {
      marginBottom: '5px'
    },
    noteTitle:{
      fontWeight: 'bold',
      fontSize: '14px',
      textDecoration: 'underline'
    },
    note: {
      fontSize: '14px'
    }
}
  

const IndProduct = ({product, index}) => {
    return (
      
        <div style={classes.indProductCont}>
          <div style={classes.indProductItem}># {index+1}</div>
          <div style={classes.indProductItem}><img height={50} src={product?.image?.thumb??''}/></div>
          
          <div style={classes.indProductItem}>{product.name}</div>
          <Badge badgeContent={product.count} sx={{marginLeft:'15px'}} color="primary">
          </Badge>
        </div>
    )
  }
const IndNote = ({title, note}) => {
  const titleMap = {
    bookingNotes: 'Booking Notes :-',
    checkoutNotes: 'Checkout Notes :-',
    checkinNotes: 'Checkin Notes :-'
  }
  if (note) {
    return (
      <div style={classes.indNoteCont}>
        <div style={classes.noteTitle}>{titleMap[title]}</div>
        <div style={classes.note}>{note}</div>
      </div>
    )
  }
  return null;

}
const BookDetails = (props) => {
    const getChipStyle = (status) => {
        if (status==='booked') {
          return {border: '2px solid #54A0FF', color: '#54A0FF'}
        }
        if (status === 'checkedout') {
          return {border: '2px solid #F39C12', color: '#F39C12'}
        }
        if (status === 'checkedin') {
          return {border: '2px solid #E74C3C', color: '#E74C3C'}
        }
        if (status === 'closed') {
          return {border: '2px solid #30CB83', color: '#30CB83'}
        }
        if (status === 'cancelled') {
          return {border: '2px solid #34495E', color: '#34495E'}
        }
      }
      const steppers =['booked', 'checkedout', 'checkedin', 'closed'];
      const cancelledSteppers = ['booked', 'cancelled'];
      const {_id, tenant ={}, status, to, from, products =[], advance, notes, amount, discount, timeline} = props
      if (props.isMobile) {
        return (
          <div style={classes.detailCont}>
              <div style={classes.firstRow}>
                  <div style={classes.tenant}>
                      <CustomAvatar style={{...classes.cardItem, ...classes.rowInd}} src={tenant?.image?.thumb??''} name={tenant?.name}/>
                      <div style={classes.tenantText}>
                          <div style={classes.rowItem}>{tenant?.name}</div>
                          <div style={classes.rowItem}>{tenant?.contactNumber}</div>
                      </div>
                  </div>
                  <Chip sx={{...classes.topInd, ...classes.chip}} style={getChipStyle(status)} label={status} />
              </div>
              <div style={{...classes.dates, marginBottom:'10px'}}>
                    <CalendarMonthIcon/>
                    <div style={classes.rowInd}>{moment(from).format('Do MMM YY (ddd)')}</div> -
                    <div style={classes.rowInd}>{moment(to).format('Do MMM YY (ddd)')}</div>
                    <div style={{...classes.rowInd, ...classes.smallfont}}>{moment(to).startOf('day').diff(moment(from).startOf('day'), 'days')+1} days</div>
                </div>
              <div style={{...classes.cashCont, width: '100%'}}>
                  <div>Amount : ₹{amount}</div>
                  <div>Discount : ₹{discount || 0}</div>
                  <div>Advance : ₹{advance || 0}</div>
                  <div>Balance : ₹{amount - advance}</div>
              </div>
              <Stepper timeline={timeline} steppers={status==='cancelled' ? cancelledSteppers : steppers}/>
              <div style={{...classes.bottomRow, marginBottom: '10px'}}>
                <div style={{...classes.prodCont, width: '100%'}}>{products.map((product, index)=><IndProduct index={index} key={`product-${index}`} product={product}/>)}</div>
              </div>
              <div>
                <div style={classes.noteCont}>{Object.keys(notes).map((note, index)=><IndNote index={index} key={`note-${index}`} title={note} note={notes[note]}/>)}</div>
              </div>
          </div>
      )
      }
    return (
        <div style={classes.detailCont}>
            <div style={classes.firstRow}>
                <div style={classes.tenant}>
                    <CustomAvatar style={{...classes.cardItem, ...classes.rowInd}} src={tenant?.image?.thumb??''} name={tenant?.name}/>
                    <div style={classes.tenantText}>
                        <div style={classes.rowItem}>{tenant?.name}</div>
                        <div style={classes.rowItem}>{tenant?.contactNumber}</div>
                    </div>
                </div>
                <div>{getTicketId(_id)}</div>
                <Chip sx={{...classes.topInd, ...classes.chip}} style={getChipStyle(status)} label={status} />
                <div style={classes.dates}>
                    <CalendarMonthIcon/>
                    <div style={classes.rowInd}>{moment(from).format('Do MMM YY (ddd)')}</div> -
                    <div style={classes.rowInd}>{moment(to).format('Do MMM YY (ddd)')}</div>
                    <div style={{...classes.rowInd, ...classes.smallfont}}>{moment(to).startOf('day').diff(moment(from).startOf('day'), 'days')+1} days</div>
                </div>
            </div>
            <div style={classes.cashCont}>
                <div>Advance : ₹{advance || 0}</div>
                <div>Amount : ₹{amount}</div>
                <div>Balance : ₹{amount - advance}</div>
                <div>Discount : ₹{discount || 0}</div>
            </div>
            <Stepper timeline={timeline} steppers={status==='cancelled' ? cancelledSteppers : steppers}/>
            <div style={classes.bottomRow}>
              <div style={classes.prodCont}>{products.map((product, index)=><IndProduct index={index} key={`product-${index}`} product={product}/>)}</div>
              {notes && <div style={classes.noteCont}>{Object.keys(notes).map((note, index)=><IndNote index={index} key={`note-${index}`} title={note} note={notes[note]}/>)}</div>}
            </div>
        </div>
    )
}

const Body = ({bookDetails, isMobile}) => {
    return (
      <div style={classes.delDialogCont}>
        <div style={classes.delDialogLeft}>
          <BookDetails isMobile={isMobile} {...bookDetails}/>
        </div>
        <div style={classes.delDialogRight}>
            
            {/*bookDetails.timeline.map((element, index)=> <IndTimeLine key={`timelineitem-${index}`} element={element}/>)*/}
        </div>
      </div>
    )
  }

export default function BookingList({selectedBooking, bookings, open, setOpen, isMobile}) {
    const [bookDetails, setBookDetails] = useState({});
    useEffect(()=>{
        if (open) {
            const book = bookings.find(book=>book._id === selectedBooking)
            setBookDetails(book)
        }
      }, [open])
    return (
        <DialogWindow title={`Booking Details ${getTicketId(bookDetails?._id)}`} Body={<Body bookDetails={bookDetails} isMobile={isMobile}/>} Actions={null} open={open} setOpen={setOpen}  maxWidth={'md'}/>
    )
}