import React from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, Typography, TableRow} from '@mui/material';

import {getItemsByProduct} from '../services/item.js';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {removeUserAction} from '../actions/profile.js';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {getWindowDimensions} from '../util/index.js'
const headers = [
    {label:'#', minWidth:20},
    {label:'SKU', minWidth:170},
    {label:'Created At', minWidth:150},
    {label:'Actions', minWidth:150},
    ]

const classes = {
  root: {
    width: '100%',
    position:'relative'
  },
  container: {
    maxHeight: '80vh',
  },
  pagination : {
    position: 'absolute',
    bottom:0,
    right:0
  },
  productInfoCont: {
      display: 'flex',
      margin: '20px 0px',
      paddingLeft: '10px'
  },
  productInfoLeft: {
    width: '30%'
  },
  productInfoRight : {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
  },
  actionBtn: {
    marginLeft: '10px',
    cursor: 'pointer'
  }
};


const ProductInfo = ({product, isMobile}) => {
  if (product._id) {
    return (
      <div style={classes.productInfoCont}>
        <div style={isMobile ? {...classes.productInfoLeft, width:'50%'} : classes.productInfoLeft}>
          <img src={product.image.thumb} width="150" style={{objectFit: 'contain'}}/>
        </div>
        <div style={isMobile ? {...classes.productInfoRight, width: '50%'} : classes.productInfoRight}>
          <Typography variant={isMobile ? "subtitle2" : "h6"} gutterBottom component="div"> {product.name} </Typography>
          {product.description && (<Typography variant={isMobile ? "caption": "body1"} gutterBottom>{product.description}</Typography>)}
          <Typography variant={isMobile ? "caption": "body1"}  gutterBottom>Rent : Rs {product.rent}/-</Typography>
          <Typography variant={isMobile ? "caption": "body1"} gutterBottom>Includes : {product.includes.map(inc=> `${inc.item} x ${inc.count}`).join(', ')}</Typography>
        </div>
      </div>
    )
  }
  return null;
}

export default function StickyHeadTable(props) {
  const dim = getWindowDimensions();
  const [page, setPage] = React.useState(0);
  const [selectedMenu, setSelectedMenu] = React.useState(undefined);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [editMenuOpen, setEditMenuOpen] = React.useState(false);
  const [product, setProduct] = React.useState({items:[]});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = dim.width < 500 ? true : false

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleEditClick = (id) => {
    return (e) => {
      const _item = product.items.find(item => item._id === id);
      props.setEdit(_item);
    }
  }
  React.useEffect(()=>{
      const getData = async() => {
        try {
          const product = await getItemsByProduct(props.selectedProduct);
          setProduct(product);
        } catch (e) {
          if (e.status === 401) {
            dispatch(removeUserAction())
            navigate('/');
          }
        }
      }
      if (props.selectedProduct) {
        getData();
      } else {
        setProduct({items:[]})
      }
  },[props.selectedProduct])

  React.useEffect(()=>{
    if (props.addedItem) {
      const _product = {...product};
      _product.items.push(props.addedItem)
      setProduct(_product)
    }
  },[props.addedItem])

  React.useEffect(()=>{
    if (props.editedItem) {
      const _product = {...product};
      const index = _product.items.findIndex(item=>item._id===props.editedItem._id);
      if (index > -1) {
        _product.items[index] = props.editedItem
        setProduct(_product)
      }
    }
  },[props.editedItem])

  React.useEffect(()=>{
    if (props.deletedItem) {
      console.log('>>', props.deletedItem)
      const _product = {...product};
      const index = _product.items.findIndex(item=>item._id===props.deletedItem);
      if (index > -1) {
        _product.items.splice(index, 1);
        setProduct(_product)
      }
    }
  },[props.deletedItem])

  return (
    <Paper sx={classes.root}>
      <ProductInfo product={product} isMobile={isMobile}/>
      <TableContainer sx={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headers.map((column) => (
                <TableCell
                  key={column._id}
                  align={'center'}
                  style={{ width: column.minWidth, fontWeight:600 }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {product.items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, ind) => {
              return (
                <TableRow hover onClick={(e)=>{setSelectedMenu(row); setEditMenuOpen(true); }} role="checkbox" tabIndex={-1} key={row.sku}>
                  <TableCell align={'center'}> {page*rowsPerPage+ind+1}</TableCell>
                <TableCell align={'center'}> {row.sku}</TableCell>
                <TableCell align={'center'}> {moment(row.createdAt).format('DD/MM/YYYY')}</TableCell>
                <TableCell align={'center'}> 
                  <EditIcon sx={classes.actionBtn} onClick={handleEditClick(row._id)}/> 
                  <DeleteIcon onClick={props.handleDeleteClick(row._id)} sx={classes.actionBtn}/>
                </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        classes={classes.pagination}
        count={product.items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
