import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom';
import Login from '../screens/signin.js';
import Home from '../screens/home.js'
import Products from '../screens/products.js'
import Items from '../screens/items.js'
import Users from '../screens/users.js'
import Calendar from '../screens/calendar.js';
import SetPassword from '../screens/setPassword.js';

import { connect } from "react-redux";

import { getStore } from '../util';

class Navigation extends Component {
  componentDidMount() {
    document.title = "Book My Cam"; 
 }
  render() {
    const user  = this?.props?.profile?.user?._id ?? ''; 
    return (
        <Router>
          <Routes>
            <Route exact path="/login" element={!user ? <Login/> : <Navigate to="/"/>} />
            <Route exact path="/" element={user ? <Home/> : <Navigate to="/login"/>} />
            {/*<Route exact path="/" element={<Home/>} />*/}
            <Route exact path="/products" element={user ? <Products/> : <Navigate to="/login"/>} />
            <Route exact path="/items" element={user ? <Items/> : <Navigate to="/login"/>} />
            <Route exact path="/users" element={user ? <Users/> : <Navigate to="/login"/>} />
            <Route exact path="/calendar" element={user ? <Calendar/> : <Navigate to="/login"/>} />
            <Route exact path="/resetpassword" element={<SetPassword/>} />

            <Route path='*' element={<Login/>} />
          </Routes>
        </Router>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    profile: state.user.profile
  }
}

export default connect(mapStateToProps)(

  Navigation

);
//export default Navigation;
