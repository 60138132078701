import React from 'react';
import { RequestProvider } from './context/requestContext';
import Navigation from './navigation';

import { createTheme, ThemeProvider, StyledEngineProvider} from '@mui/material/styles';
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiCollapse: {
      entered: {
        height: "500px",
        overflow: "visible"
      }
    }
  },
  palette: {
    text: {
        primary:'#676767',
        secondary: '#000'
    }
  }
});


class App extends React.Component {
  
  render() {
    return (
      <StyledEngineProvider>
        <ThemeProvider theme={theme}>
            <RequestProvider>
              <Navigation />
            </RequestProvider>
        </ThemeProvider>
        </StyledEngineProvider>
      
    )
  }
}

export default App;


