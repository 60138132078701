import React, {useState} from 'react';
import {Card, CardMedia, CardActions, CardContent, Chip, IconButton, Typography} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ProductDetail from './productDetail.js';

const classes = {
    root: {
      display:'flex',
      flexDirection:'row',
      flexWrap: 'wrap',
      height: '100%'
    },
    listCont: {
      display:'flex',
      flexDirection:'row',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      alignContent: 'flex-start',
      justifyContent: 'flex-start',
      width:'100%'
    },
    container: {
      maxHeight: 440,
    },
    card:{
       //maxWidth:{ xs:'47%', sm: '47%', md: '15%', lg: '15%', xl:'15%'},
       width: { xs:'47%', sm: '47%', md: '15%', lg: '15%', xl:'15%'},
       minWidth: { xs:'47%', sm: '47%', md: '15%', lg: '15%', xl:'15%'},
       marginRight: '10px',
       marginBottom: '10px',
    },
    fab: {
      position: 'absolute',
      left :30,
      bottom:0
    },
    pagination : {
      position: 'absolute',
      bottom:0,
      right:0
    },
    image: {
      objectFit:'contain!important',
    },
    chip : {
      fontSize:{xs:'11px!important', sm: '11px!important', md: '11px!important'},
      width:{xs:'75%', sm: '75%', md: '100%'}
    },
    cardIn: {
      padding: '8px 2px!important'
    }
  };

const IndProduct = props => {
  return (
    <Card sx={classes.card} onClick={()=>{props.setShowDetail(true); props.setSelectedProduct(props._id)}}>
       <CardMedia
        component="img"
        height="75"
        sx={classes.image}
        image={props.image.thumb}
        alt={props.name}
      />
      <CardContent>
      <Typography variant="subtitle2" color="text.secondary">
        {props.name}
      </Typography>
      </CardContent>
      <CardActions disableSpacing classes={{ root: classes.cardIn }}>
        <Chip  classes={{ root: classes.chip }} label={props.type} />
        <IconButton aria-label="Edit" onClick={props.setEdit(props._id)}>
          <EditIcon />
        </IconButton>
        <IconButton aria-label="Delete" onClick={props.deleteProduct(props._id)}>
          <DeleteIcon />
        </IconButton>
      </CardActions>
    </Card>
  )
};  
const ProductList = (props) => {    
    return (
      <div style={classes.root}>
        <div style={classes.listCont}>
        { !props.showDetail && props.products.map(product=><IndProduct deleteProduct={props.deleteProduct}  setEdit={props.setEdit} setShowDetail={props.setShowDetail} setSelectedProduct={props.setSelectedProduct} key={product._id} {...product}/>)}
        </div>
        {props.showDetail && <ProductDetail setOpen={props.setShowDetail} id={props.selectedProduct}/>}
      </div>
      )
}

export default ProductList
     
