import React, { useState, useEffect } from 'react';

import EventIcon from '@mui/icons-material/Event';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';


import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Stack from '@mui/material/Stack';

import {getWindowDimensions} from './general.js'

const classes = {
    stack: {
        marginRight: 10
    }
}

const BookFilter = ({selectedStatuses=['booked', 'checkedout', 'checkedin', 'closed'], setSelectedStatuses}) => {    
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const isMobile = windowDimensions.width < 500 ? true : false
    const mobileDim = {width: 28, height: 28, marginRight:'2px'}
    return (
        <Stack direction="row" sx={classes.stack} spacing={4}>
            <ToggleButtonGroup
                value={selectedStatuses}
                onChange={setSelectedStatuses}
                size="small"
                aria-label="device"
            >
                <ToggleButton sx={isMobile ? mobileDim : {}}  value="booked" aria-label="laptop">
                    <EventIcon style={{color:'#54A0FF'}}/>
                </ToggleButton>
                <ToggleButton sx={isMobile ? mobileDim : {}} value="checkedout" aria-label="tv">
                    <LogoutIcon style={{color:'#F39C12'}}/>
                </ToggleButton>
                <ToggleButton sx={isMobile ? mobileDim : {}} value="checkedin" aria-label="phone">
                    <LoginIcon style={{color:'#E74C3C'}}/>
                </ToggleButton>
            </ToggleButtonGroup>
        </Stack>
    )
}

const FullBookFilter = ({selectedStatuses=['booked', 'checkedout', 'checkedin', 'closed'], setSelectedStatuses}) => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const isMobile = windowDimensions.width < 500 ? true : false
    const mobileDim = {width: 28, height: 28, marginRight:'2px'}
    return (
        <Stack direction="row" sx={classes.stack} style={isMobile? {justifyContent: 'center', marginTop: '10px'}:{}} spacing={4}>
            <ToggleButtonGroup
                value={selectedStatuses}
                onChange={setSelectedStatuses}
                size="small"
                aria-label="device"
            >
                <ToggleButton sx={isMobile ? mobileDim : {}}  value="booked" aria-label="laptop">
                    <EventIcon style={{color:'#54A0FF'}}/>
                </ToggleButton>
                <ToggleButton sx={isMobile ? mobileDim : {}} value="checkedout" aria-label="tv">
                    <LogoutIcon style={{color:'#F39C12'}}/>
                </ToggleButton>
                <ToggleButton sx={isMobile ? mobileDim : {}} value="checkedin" aria-label="phone">
                    <LoginIcon style={{color:'#E74C3C'}}/>
                </ToggleButton>
                <ToggleButton sx={isMobile ? mobileDim : {}} value="closed" aria-label="phone">
                    <LoginIcon style={{color:'#30CB83'}}/>
                </ToggleButton>
            </ToggleButtonGroup>
        </Stack>
    )
}

export  {BookFilter, FullBookFilter};