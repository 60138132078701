import { Types } from '../constants/actionTypes';
const initialState = {
  error:undefined,
  load:false,
  addedItem:undefined,
  editedItem: undefined,
  deletedItem: undefined
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.ADD_ITEM_SUCCESS:
      return {
        ...state,
        load:false,
        addedItem: action.payload.item
      }
      case Types.ADD_ITEM_LOAD:
      return {
        ...state,
        load:true
      }
    case Types.ADD_ITEM_FAIL:
      console.log('>', action.payload);
      return {
        ...state,
        load:false,
        error: action.payload
      }
      case Types.EDIT_ITEM_SUCCESS:
        {
          return {
              ...state,
              load:false,
              editedItem: action.payload.item
          }  
      }
        case Types.EDIT_ITEM_LOAD:
        return {
          ...state,
          load:true
        }
      case Types.EDIT_ITEM_FAIL:
        return {
          ...state,
          load:false,
          error: action.payload.error
        }
    case Types.DEL_ITEM_SUCCESS:
  {
      return {
        ...state,
        load:false,
        deletedItem:action.payload.item,
      }
    }
    case Types.DEL_ITEM_FAIL:
      return {
        ...state,
        laod:false,
        error: action.payload.error
      }
    case Types.DEL_ITEM_LOAD: 
        return {
        ...state,
        load:true,
      }
    case Types.RESET_ITEM: 
      return {
      ...state,
      addedItem:undefined,
      editedItem: undefined,
      deletedItem: undefined
    }
      
    /*case Types.EDIT_MENU_LOAD:
      return {
        ...state,
        load:true,
      }
    case Types.EDIT_MENU:
    {
        const _menus = [...state.menus]
        const menuIndex = _menus.findIndex(x=>x._id===action.payload.id);
        let menu;
        if (menuIndex>-1) {
            _menus[menuIndex] = action.payload.menu;
        }
        return {
            ...state,
            load:false,
            menus:_menus,
            editedMenu: action.payload.menu
        }  
    }
    case Types.EDIT_MENU_FAIL:
        return {
            ...state,
            load:false,
            error: action.payload.error
        }*/
    default:
      return state;
  }
}

export default reducer;