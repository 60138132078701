import React, { Component } from 'react';
import { connect} from 'react-redux';
import { removeItem } from '../util';
import Sidebar from '../components/sidebar';
import Appbar from '../components/appbar';
import UserList from  '../components/userList.js';
import {Grid} from '@mui/material';
import Button from '@mui/material/Button';
import ViewUser from '../components/viewUser.js';
import AddUser from '../components/addUser.js';
import CheckUser from '../components/checkUser.js'

const classes = {
  root: {
    flexDirection: "column",
  },
  image: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: 1
  },
  submit: {
    margin: '3, 0, 2'
  },
  container : {
    display: "flex",
    flexDirection: "row",
    width: '100%',
    overflow: "hidden",
  },
  subcontainer:{
    width: '100%',
    margin:'10px 10px 0px 10px'
  },
  addCont :{
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  }
};


class User extends Component { 
  constructor(props) {
    super(props);
    this.state= {
      addUserOpen: false,
      viewUserOpen: false,
      editableUser:'',
      usersChange: undefined,
      checkUserOpen: false,
      viewUser:undefined,
      viewUserOpen: false
    }
  }
    componentDidMount() {
        
    }
    componentDidUpdate(prevState) { 

      /*if (this.props.products?.editedProduct && prevProps?.products?.editedProduct?._id !== this.props.products?.editedProduct._id) {
        this.setState({addProductOpen: false, showSnack: true, snackMessage: 'Product Updated'})
      }*/
    }
    setUsersChange = (change) => {
      this.setState({usersChange:change})
    }
  
    deleteUser = (userId) => {
      return () => {
        //this.props.dispatch(deleteUserAction(productId))
      }
    }
    editUser = (data, file, id) => {
        //this.props.dispatch(editUserAction(id, data, file))
    }
    addUserClose = () => {
      return ()=>{this.setState({addUserOpen:false, editableUser: ''})}
    }
    viewUserClose = () => {
      return ()=>{this.setState({viewUserOpen:false, editableUser: ''})}
    }
    viewUserClose = () => {
      return ()=>{this.setState({viewUserOpen:false })}
    }

    openAddUser = () => {
      this.setState({addUserOpen: true})
    }

    openViewUser = () => {
        this.setState({viewUserOpen: true})
    }

    checkUserClose = () => {
      return ()=>{this.setState({checkUserOpen:false})}
    }
    handleSnackClose=(e)=> {
      this.setState({showSnack:false})
    }
    setViewUser = (id) => {
      this.setState({viewUser:id, viewUserOpen: true})
    }
    setEdit = (id) => {    
      this.setState({editableUser:id, addUserOpen: true})
    }  
    render = ()=> {
    const { users:{error, users=[], user}={} } = this.props;
    if (error && error.status===401) {
        removeItem('user');
    }
      return (
        <Grid container component="main" sx={classes.root}>
            <Appbar/>
            <div style={classes.container}>
                <Sidebar/>
                
                <div style={classes.subcontainer}>          
                  <div style={classes.addCont}>
                    <Button variant="contained" onClick={()=>{this.setState({checkUserOpen:true})}}>Add User</Button>
                  </div>
                  <UserList setUsersChange={this.setUsersChange} setViewUser={this.setViewUser} usersChange={this.state.usersChange}/>
                </div>
            </div>
            <CheckUser
                setUsersChange={this.setUsersChange}
                openAddUser={this.openAddUser}
                checkUserOpen={this.state.checkUserOpen}
                checkUserClose={this.checkUserClose}
            />
            <ViewUser
                setUsersChange={this.setUsersChange}
                usersChange={this.state.usersChange}
                viewUser={this.state.viewUser}
                editUser={this.editUser}
                viewUserClose={this.viewUserClose}
                showSnack={this.state.showSnack}
                viewUserOpen={this.state.viewUserOpen}
            />
            <AddUser
              addUserOpen={this.state.addUserOpen}
              addUserClose={this.addUserClose}
            />
        </Grid>
      );
    };
};


const mapStateToProps = (state) => {
  return {
    users: state.users
  }
}

export default connect(mapStateToProps)(User);
