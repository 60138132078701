import React, { Component } from 'react';
import { connect} from 'react-redux';
import { getStore, setStore, removeItem } from '../util';
import Sidebar from '../components/sidebar';
import Appbar from '../components/appbar';
import SplitButton from '../components/splitButton.js';
import ProductList from  '../components/productList.js';
import {getAllProductsAction, addProductAction, addProductWithTemplateAction, deleteProductAction, editProductAction} from '../actions/products.js';
import {Grid, Button} from '@mui/material';
import AddProduct from '../components/addProduct.js'
import UseTemplate from '../components/useTemplate.js'
import {SnackBar, ConfirmDialog} from  '../components/general.js';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const classes = {
  root: {
    flexDirection: "column",
  },
  image: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: 1
  },
  submit: {
    margin: '3, 0, 2'
  },
  container : {
    display: "flex",
    flexDirection: "row",
    width: '100%',
    height: '100%',
  },
  subcontainer:{
    height: '100%',
    width: '100%',
    margin:'10px 10px 0px 10px',
    overflowX: 'hidden'
  },
  addCont :{
    width: '100%',
    display: 'flex',
    marginBottom: '20px'
  }
};


class Menu extends Component { 
  constructor(props) {
    super(props);
    this.state= {
      addProductOpen: false,
      showSnack: false,
      snackMessage:'Product Added',
      snackType:'success',
      editableProduct: undefined,
      editableTemplate:undefined,
      delProduct:undefined,
      showConfirm:false,
      showDetail: false,
      selectedProduct:undefined,
      templateOpen: false
    }
  }

    componentDidMount() {
        this.props.dispatch(getAllProductsAction())
    }
    componentDidUpdate(prevProps) {
      if (this.props.products?.product && prevProps?.products?.product?._id !== this.props.products?.product._id) {
        this.setState({addProductOpen: false, showSnack: true, snackType: 'success', snackMessage: 'Product Added'})
      }
      if (this.props.products?.editedProduct && prevProps?.products?.editedProduct?._id !== this.props.products?.editedProduct._id) {
        this.setState({addProductOpen: false, editableProduct:undefined, showSnack: true, snackType: 'success', snackMessage: 'Product Updated'})
      }
      if (this.props.products?.error && prevProps?.products?.error !== this.props.products?.error) {
        console.log(this.props.products?.error)
        let message = 'Could not complete action'
        this.setState({ showSnack: true, snackType:'error', snackMessage: message})
        this.addProductClose()();
      }
    }
    addProduct = (data, file)=> {
        this.props.dispatch(addProductAction(data, file))
    }
    addProductWithTemplate = (data, file) => {
        this.props.dispatch(addProductWithTemplateAction(data, file))
    }
    deleteProduct = (productId) => {
      return (e) => {
        e.stopPropagation();
        this.setState({delProduct:productId, showConfirm:true})
      }
    }
    editProduct = (data, file, id) => {
        this.props.dispatch(editProductAction(id, data, file))
    }
    addProductClose = () => {
      return ()=>{this.setState({addProductOpen:false, editableProduct:undefined, editableTemplate: undefined})}
    }
    handleSnackClose=(e)=> {
      this.setState({showSnack:false})
    }
    handleConfirmClose = ()=> {
      this.setState({delProduct:undefined, showConfirm: false})
    }
    setEdit = (id) => {
      return (e) => {
        e.stopPropagation();
        this.setState({editableProduct:id, addProductOpen:true})
      }
    }
    setShowDetail = (_show) => {
      this.setState({showDetail: _show})
    }
    setSelectedProduct = (_productId) => {
      this.setState({selectedProduct: _productId})
    }
    handleCloseTemplate = () => {
        this.setState({...this.state, templateOpen: false})
    }
    handleTemplateClick = () => {
        this.setState({...this.state, templateOpen: true})
    }
    doUseTemplate = (_product) => {
        this.setState({...this.state, addProductOpen: true, templateOpen:false, editableTemplate: _product})
    }
    render = ()=> {
    const { products:{error, products=[], product}={} } = this.props;
    if (error && error.status===401) {
        removeItem('user');
    }
    const getContentStyle = () => {
      if (this.state.showDetail) { 
        return {justifyContent: 'flex-start'}
      }
      return {justifyContent: 'flex-end'}
    }
      return (
        <Grid container component="main" sx={classes.root}>
            <Appbar/>
            <div style={classes.container}>
                <Sidebar/>
                <div style={classes.subcontainer}>
                  <div style={{...getContentStyle(), ...classes.addCont}}>
                    {!this.state.showDetail && (<SplitButton handleClick={()=> {this.setState({addProductOpen:true})}} handleTemplateClick={this.handleTemplateClick}/>)}
                    {this.state.showDetail && ( <Button variant="contained" startIcon={<ArrowBackIosNewIcon />}  onClick={()=>{this.setShowDetail(false)}}>Back</Button>)}
                  </div>
                    <ProductList setShowDetail={this.setShowDetail} showDetail={this.state.showDetail} selectedProduct={this.state.selectedProduct} setSelectedProduct={this.setSelectedProduct} products={products} product={product} deleteProduct={this.deleteProduct} setEdit={this.setEdit}/>
                    <AddProduct editableProduct={this.state.editableProduct} editableTemplate={this.state.editableTemplate} editProduct={this.editProduct} addProductClose={this.addProductClose} showSnack={false} addProductOpen={this.state.addProductOpen} addProduct={this.addProduct} addProductWithTemplate={this.addProductWithTemplate}/>
                    <UseTemplate
                        handleCloseTemplate={this.handleCloseTemplate}
                        templateOpen={this.state.templateOpen}
                        doUseTemplate={this.doUseTemplate}
                    />
                </div>
            </div>
            <SnackBar message={this.state.snackMessage} open={this.state.showSnack} severity={this.state.snackType} handleClose={this.handleSnackClose}/>
            <ConfirmDialog title={'Are you sure ?'} message={'This action cannot be undone'} confirmAction={()=>{ this.props.dispatch(deleteProductAction(this.state.delProduct)); this.handleConfirmClose()}} show={this.state.showConfirm} handleClose={this.handleConfirmClose}/>
        </Grid>
      );
    };
};


const mapStateToProps = (state) => {
  return {
    profile: state.user.profile,
    products: state.products,
  }
}

export default connect(mapStateToProps)(Menu);
