import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';
import EventIcon from '@mui/icons-material/Event';
import LogoutIcon from '@mui/icons-material/Logout';
import CheckIcon from '@mui/icons-material/Check';
import LoginIcon from '@mui/icons-material/Login';
import moment from 'moment';

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState, status }) => {
  console.log(status)
  return ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 25,
    height: 25,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
  })});


  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 11,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));


  const getColorlibStepIcon = (status) => {
    return function ColorlibStepIcon(props) {
      const { active, completed, className } = props;
      const icons = {
        1: <EventIcon sx={{fontSize: '12px'}}/>,
        2: <LogoutIcon sx={{fontSize: '12px'}}/>,
        3: <LoginIcon sx={{fontSize: '12px'}}/>,
        4: <CheckIcon sx={{fontSize: '12px'}}/>,
      };
    
      return (
        <ColorlibStepIconRoot ownerState={{ completed, active, status }} className={className}>
          {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
      );
    }
  }
  const StepComp = ({status, timelineObject={}}) => {
    return (
      <div>
        <div>{status}</div>
        {timelineObject.at && (<div>{moment(timelineObject.at).format('DD/MM/YYYY')}</div>)}
      </div>
    )
  }
  export default function CustomizedSteppers({timeline=[], steppers}) {

    return (
      <Stack sx={{ width: '100%' }} spacing={4}>
        <Stepper alternativeLabel activeStep={timeline.length - 1} connector={<ColorlibConnector />}>
          {steppers.map((status) => {
            const timelineObj = timeline.find(tl=>tl.status===status)
            return(
            <Step key={status}>
              <StepLabel StepIconComponent={getColorlibStepIcon(timelineObj?.status)}><StepComp status={status} timelineObject={timelineObj}/></StepLabel>
            </Step>
          )})}
        </Stepper>
      </Stack>
    );
  }