import React, {useState, useEffect} from 'react';

import moment from 'moment';

import {removeUserAction} from '../actions/profile.js';
import {SnackBar, CustomAvatar} from  './general';
import {getAllItems, cancelBook, updateStatus} from '../services/booking.js'
import UpdateStatusDialog from './updateStatusDialog.js'
import { getTicketId } from '../util/index.js';
import socket from '../components/socket.js'

import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Collapse from '@mui/material/Collapse';
import Badge from '@mui/material/Badge';
import CardActions from '@mui/material/CardActions';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import EditIcon from '@mui/icons-material/Edit';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloseIcon from '@mui/icons-material/Close';
import PhoneIcon from '@mui/icons-material/Phone';

import { pink } from '@mui/material/colors';
import { green } from '@mui/material/colors';

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import clsx from  'clsx';




const classes = {
  root: {
    width: '100%',
    position:'relative',
    height: '100%'
  },
  container: {
    maxHeight: 440,
  },
  pagination : {
    position: 'absolute',
    bottom:0,
    right:0
  },
  paneHead: {
    marginBottom:20
  },
  indCard: {
    display: 'flex',
    flexDirection:'column',
    padding: '10px 0px',
    marginBottom: '10px',
    overflow: 'visible'
  },
  indCardHead: {
    display: 'flex',
    alignItems:'center',
    padding: '10px 0px',
    position: 'relative'
  },
  cardItem : {
    margin: '0px 10px'
  },
  topbar: {
    display:'flex',
    justifyContent:'space-between',
    alignItems: 'center',
    marginBottom: '20px'
  },
  indProductCont: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  indProductItem :{
    margin: '0px 10px'
  },
  chip: {
    textTransform: 'capitalize',
    fontWeight: 'bold'
  },
  topInd: {
    marginRight: '10px',
    display: 'flex',
    alignItems:'center'
  },
  lbl: {
    marginRight: '5px'
  },
  advanceCont: {
    color: green[500]
  },
  balanceCont: {
    color: pink[500]
  },
  paneHead: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position:'relative'
  },
  prev: {
    position:'absolute',
    cursor: 'pointer',
    left: 0
  },
  next:{
    position:'absolute',
    cursor: 'pointer',
    right: 0
  },
  fullWidthHead: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  fullWidthBody: {
    display:'flex',
    justifyContent: 'space-around'
  },
  expandCont: {
    position: 'absolute',
    right: 10
  },
  checkProd: {
    marginLeft: 15
  },
  delDialogCont: {
    width: '100%',
  },
  collapse :{
    minHeight: '500px'
  }
};


const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const CancelDialogActions = ({doCancelBooking, setOpen}) => {
  return([
    <Button key={'cancel_book_action_1'} variant="outlined" onClick={()=>{setOpen(false)}}startIcon={<ArrowBackIosNewIcon />}>Back </Button>,
    <Button key={'cancel_book_action_2'} variant="contained" onClick={doCancelBooking} color="error" endIcon={<DoDisturbIcon/>}>Cancel Booking </Button>
  ])
}

const CancelDialogBody = ({charge, setCharge}) => {

  return (
    <div style={classes.delDialogCont}>
      <TextField id="cancelCharge" label="Cancellation Charge" value={charge} onChange={(e)=>{setCharge(e.target.value)}} inputProps={{ inputMode: 'numeric' }}/>
    </div>
  )
}
const CancelDialog = ({charge, setCharge, open, setOpen, doCancelBooking}) => {

  return (
    <Dialog
        onClose={()=>{setOpen(false)}}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={()=>{setOpen(false)}}>Cancel Booking</BootstrapDialogTitle>
        <DialogContent dividers>
          <CancelDialogBody charge={charge} setCharge={setCharge}/>
        </DialogContent>
        <DialogActions>
          <CancelDialogActions doCancelBooking={doCancelBooking} setOpen={setOpen}/>
        </DialogActions>
      </Dialog>
  )
  /*return (
    <DialogWindow title={'Cancel Booking'} Body={<Body/>} Actions={Actions} open={open} setOpen={setOpen}  maxWidth={'sm'}/>
  )*/
}

const IndProduct = ({product, index}) => {
  return (
    
      <div style={classes.indProductCont}>
        <div style={classes.indProductItem}># {index+1}</div>
        <div style={classes.indProductItem}><img height={50} src={product?.image?.thumb??''}/></div>
        <Badge badgeContent={product.count} color="primary">
          <div style={classes.indProductItem}>{product.name}</div>      
        </Badge>
      </div>
    
  )
}

const ButtonGroup = ({status, id,setSelectedBooking,setEditableBooking, setShowEditBooking, setShowCancelDialog, setShowUpdateStatusDialog}) => {
  let ActionButton = [];
  if (status === 'booked') {
    ActionButton = <Button key={`action1`} onClick={()=>{setSelectedBooking(id); setShowUpdateStatusDialog(true)}} variant="contained" endIcon={<LogoutIcon />}>Checkout </Button>
  }
  if (status === 'checkedout') {
    ActionButton = [<Button key={`action2`} variant="contained" onClick={()=>{setSelectedBooking(id); setShowUpdateStatusDialog(true)}} endIcon={<LoginIcon />}>Checkin </Button>]
  }
  if (status === 'checkedin') {
    ActionButton =  <Button key={`action3`} onClick={()=>{setSelectedBooking(id); setShowUpdateStatusDialog(true)}} variant="contained" endIcon={<CloseIcon />}>Close </Button>
  }
  return (
    <CardActions>
      {status === 'booked' && (<Button onClick={()=>{setSelectedBooking(id); setShowCancelDialog(true)}}variant="outlined" color="error" endIcon={<DoDisturbIcon sx={{ color: pink[500] }} />}>Cancel </Button>)}
      {status === 'booked' && (<Button onClick={()=>{setEditableBooking(id); setShowEditBooking(true)}}variant="outlined" endIcon={<EditIcon/>}>Edit </Button>)}
      {ActionButton}
    </CardActions>
  )
}

const BookingDetails = ({expanded, _id, setSelectedBooking, windowDimensions, selectedBooking,setEditableBooking, setShowEditBooking, setShowCancelDialog, setShowUpdateStatusDialog, status, advance, products, amount, tenant }) => {
  const isMobile = windowDimensions.width < 500 ? true : false;
  const getChipStyle = (status) => {
    if (status==='booked') {
      return {border: '2px solid #54A0FF', color: '#54A0FF'}
    }
    if (status === 'checkedout') {
      return {border: '2px solid #F39C12', color: '#F39C12'}
    }
    if (status === 'checkedin') {
      return {border: '2px solid #E74C3C', color: '#E74C3C'}
    }
    if (status === 'closed') {
      return {border: '2px solid #30CB83', color: '#30CB83'}
    }
    if (status === 'cancelled') {
      return {border: '2px solid #34495E', color: '#34495E'}
    }
  }
  return (
    <Collapse in={expanded[_id]} classes={{ root: classes.collapse }} timeout="auto" unmountOnExit>
      <CardContent sx={isMobile ? {padding:0}: {}}>
        <div style={classes.topbar}>
          <Chip sx={{...classes.topInd, ...classes.chip}} style={getChipStyle(status)} label={status} />
          <div style={{...classes.topInd, ...classes.advanceCont}}>{!isMobile && (<div style={classes.lbl}>Advance</div>)}<div>₹ {advance}</div></div>
          <div style={{...classes.topInd, ...classes.balanceCont}}>{!isMobile && (<div style={classes.lbl}>Balance</div>)}<div>₹ {amount-advance}</div></div>
          <div style={{...classes.topInd, ...classes.contactCont}}> <PhoneIcon sx={classes.lbl}/><a style={{textDecoration: 'none'}} href={`tel:${tenant.contactNumber}`}> {tenant.contactNumber} </a></div>
        </div>
        <div>
          <div style={{display: 'flex', flexDirection: 'column'}}>{products.map((product, index)=><IndProduct index={index} key={`product-${index}`} product={product}/>)}</div>
        </div>
        
      </CardContent>
      <ButtonGroup setEditableBooking={setEditableBooking} setShowEditBooking={setShowEditBooking} setSelectedBooking={setSelectedBooking} id={_id} setShowCancelDialog={setShowCancelDialog} setShowUpdateStatusDialog={setShowUpdateStatusDialog} status={status}/>
    </Collapse>
  )
}

const IndCard = ({book, setEditableBooking, setShowEditBooking, setShowCancelDialog, windowDimensions, setShowUpdateStatusDialog, selectedBooking, setSelectedBooking}) => {
    const [expanded, setExpanded] = useState({});
    const Icon = expanded[book._id] ? ExpandLessIcon : ExpandMoreIcon
    const getChipStyle = (status) => {
      if (status==='booked') {
        return {border: '2px solid #54A0FF', color: '#54A0FF'}
      }
      if (status === 'checkedout') {
        return {border: '2px solid #F39C12', color: '#F39C12'}
      }
      if (status === 'checkedin') {
        return {border: '2px solid #E74C3C', color: '#E74C3C'}
      }
      if (status === 'closed') {
        return {border: '2px solid #30CB83', color: '#30CB83'}
      }
      if (status === 'cancelled') {
        return {border: '2px solid #34495E', color: '#34495E'}
      }
    }
    return (
        <Card sx={classes.indCard} style={{overflow: 'visible'}}>
            <div style={classes.indCardHead}>
              <CustomAvatar sx={classes.cardItem} src={book?.tenant?.image?.thumb??''} name={book.tenant.name}/>
              <div style={classes.cardItem}>{book.tenant.name} ({getTicketId(book._id)})</div>
              {!expanded[book._id] && <Chip sx={{...classes.topInd, ...classes.chip}} style={getChipStyle(book.status)} label={book.status} />}
              <div style={classes.expandCont}>
                <IconButton onClick={
                  ()=>{
                    const _expanded = {...expanded};
                    if (_expanded.hasOwnProperty(book._id)) {
                      _expanded[book._id] = !_expanded[book._id]
                      
                    } else {
                      _expanded[book._id] = true
                    }
                    setExpanded(_expanded);
                    setSelectedBooking(book._id)
                  }
                  }><Icon/></IconButton>
              </div>
            </div>            
            <BookingDetails setEditableBooking={setEditableBooking} setShowEditBooking={setShowEditBooking} expanded={expanded} windowDimensions={windowDimensions} {...book} selectedBooking={selectedBooking} setShowUpdateStatusDialog={setShowUpdateStatusDialog} setShowCancelDialog={setShowCancelDialog} setSelectedBooking={setSelectedBooking}/>
        </Card>
    )
}
const DayBody = ({bookings, windowDimensions, setShowCancelDialog, setShowUpdateStatusDialog, selectedBooking, setEditableBooking, setShowEditBooking, setSelectedBooking}) => {
  console.log(bookings)
  if (Array.isArray(bookings) && bookings.length > 0) {
    return (
        <div style={{display:'flex', flexDirection:'column', width:'100%', margin: '0px 5px', padding: '0px 5px', height: 'calc(100vh - 200px)', overflowY:'scroll'}}>
          {bookings.map(book=> <IndCard setEditableBooking={setEditableBooking} setShowEditBooking={setShowEditBooking} key={`day-body-${bookings._id}`} selectedBooking={selectedBooking} setShowCancelDialog={setShowCancelDialog} windowDimensions={windowDimensions} setShowUpdateStatusDialog={setShowUpdateStatusDialog} setSelectedBooking={setSelectedBooking} book={book}/>)}
        </div>
    )
  }
  return (<div style={{display:'flex', alignItems:'center', justifyContent: 'center', flexDirection:'column', width:'100%', margin: '0px 5px'}}>No Booking Found</div>)
}
const DayHeader = ({date}) => {
    const getDateLabel = (_date) => {
        /*const REFERENCE = moment(); // fixed just for testing, use moment();
        const TODAY = _date === moment().format('DD-MM-YYYY') ? true : false;
        const YESTERDAY = _date === REFERENCE.clone().subtract(1, 'days').startOf('day').format('DD-MM-YYYY') ? true : false;
        const TOMORROW = _date === REFERENCE.clone().add(1, 'days').startOf('day').format('DD-MM-YYYY') ? true : false;
        if (TODAY) {
            return `Today (${ _date})`
        }
        if (YESTERDAY) {
            return `Yesterday (${ _date})`
        }
        if (TOMORROW) {
            return `Tomorrow (${ _date})`
        }
        return _date*/
        return `${_date} (${moment(_date, 'DD-MM-YYYY').format('ddd')})`

    }
    const getDateStyle = (_date) => {
      const REFERENCE = moment(); // fixed just for testing, use moment();
      const TODAY = _date === moment().format('DD-MM-YYYY') ? true : false;
      if (TODAY) {
        return {fontWeight: 'bold'}
      }
      return {};
    }
    const dateLabel = getDateLabel(date);

    return (
        <div style={classes.paneHead}>
            <div style={{...classes.day, ...getDateStyle(date)}}> { dateLabel  } </div>
        </div>
    )
}
const SingleDayPane = ({bookings, setShowCancelDialog, setShowUpdateStatusDialog, selectedStatuses, setSelectedBooking, setEditableBooking, setShowEditBooking, selectedBooking, theDay, moveNext, movePrev, windowDimensions}) => {
  let days = []
  if (windowDimensions.width >1500) {    
    days = Array(3).fill('').map((el,index)=>moment(theDay, 'DD-MM-YYYY').subtract(index-1, 'days').format('DD-MM-YYYY')).reverse()
    //days = [ moment(theDay, 'DD-MM-YYYY').subtract(1, 'days').format('DD-MM-YYYY'),moment().format('DD-MM-YYYY'), moment().add(1, 'days').format('DD-MM-YYYY')]
  } else if (windowDimensions.width > 1000) {
    days = Array(2).fill('').map((el,index)=>moment(theDay, 'DD-MM-YYYY').subtract(index-1, 'days').format('DD-MM-YYYY')).reverse()
  } else {
    days =[ moment(theDay, 'DD-MM-YYYY').format('DD-MM-YYYY')]
  }
  let checker = (arr, target) => target.every(v => arr.includes(v));
  const fitlerdBooking = days.map(day=>bookings.filter(book=>{
    let newStat = [...selectedStatuses];
    const bookInd = newStat.indexOf('booked');
    if (bookInd > -1) {
      newStat.splice(bookInd, 1)
    }
    return (moment(book.from).format('DD-MM-YYYY') === day && book.status === 'booked' && selectedStatuses.indexOf('booked') > -1) ||  (moment(book.to).format('DD-MM-YYYY') === day && newStat.includes(book.status)) 
  })
  )
    return (
        <div>
          <div style={classes.fullWidthHead}>
            <div style={classes.prev}><ChevronLeftIcon onClick={movePrev} sx={{ fontSize: 40 }} /></div>
            {days.map((day, index)=> <DayHeader key={`head-${index}`} date={day}/>)}
            <div style={classes.next}><ChevronRightIcon onClick={moveNext} sx={{ fontSize: 40 }} /></div>
          </div>
          <div style={{...classes.fullWidthBody, height: '100%'}}>
            {fitlerdBooking.map((book, index)=> <DayBody selectedBooking={selectedBooking} setShowCancelDialog={setShowCancelDialog} setShowUpdateStatusDialog={setShowUpdateStatusDialog} setSelectedBooking={setSelectedBooking} setEditableBooking={setEditableBooking} setShowEditBooking={setShowEditBooking} key={book._id || `day-${index}`} windowDimensions={windowDimensions} bookings={book}/>)}
          </div>
        </div>
    )

}


export default function BookingList(props) {
  const [listLoad, setListLoad] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [showCancelDialog, setShowCancelDialog] = useState(false)
  const [showUpdateStatusDialog, setShowUpdateStatusDialog] = useState(false)
  const [selectedBooking, setSelectedBooking] = useState()
  const [cancellationCharge, setCancellationCharge] = useState(0)
  const [advance, setAdvance] = useState(0)
  const [showSnack, setShowSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackType, setSnackType] = useState('success');
  const [currentNote, setCurrentNote] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [theDay, setTheDay] = useState(moment().format('DD-MM-YYYY'))

  const moveNext = () => {
    setTheDay(moment(theDay, 'DD-MM-YYYY').add(1, 'days').format('DD-MM-YYYY'))
  }
  const movePrev = () => {
    setTheDay(moment(theDay, 'DD-MM-YYYY').subtract(1, 'days').format('DD-MM-YYYY'))
  }
  const doCancelBooking = async(e) => {
    try {
      await cancelBook(selectedBooking, cancellationCharge)
      const _bookings = [...bookings];
      const index = _bookings.findIndex(book => book._id === selectedBooking)
      _bookings.splice(index, 1)
      setBookings(_bookings);
      setShowSnack(true)
      setSnackMessage('Booking Cancelled')
      setSnackType('success')
      setShowCancelDialog(false)

    } catch (e) {
      setShowSnack(true)
      setSnackMessage('Could not cancel booking')
      setSnackType('error')
      setShowCancelDialog(false)
    }
  }
  const doCheckout = async(checks) => {
    try {
      const result = await updateStatus(selectedBooking, 'checkedout', currentNote, advance, checks)
      const _bookings = [...bookings];
      const index = _bookings.findIndex(book => book._id === selectedBooking)
      _bookings[index].status='checkedout'
      _bookings[index].advance=advance
      _bookings[index].notes.checkoutNotes = currentNote
      _bookings[index].checks = checks
      setBookings(_bookings);
      setShowSnack(true)
      setSnackMessage('Checked Out')
      setSnackType('success')
      setShowUpdateStatusDialog(false)
      setCurrentNote('')
      setAdvance(0)
    } catch (e) {
      setShowSnack(true)
      setSnackMessage('Could not checkout')
      setSnackType('error')
      console.log(`checkout ${selectedBooking}`)
    }
  }
  const doCheckIn = async() => {    
    try {
      const result = await updateStatus(selectedBooking, 'checkedin', currentNote)
      const _bookings = [...bookings];
      const index = _bookings.findIndex(book => book._id === selectedBooking)
      _bookings[index].status='checkedin'
      _bookings[index].notes.checkinNotes = currentNote
      setBookings(_bookings);
      setShowSnack(true)
      setSnackMessage('Checked In')
      setSnackType('success')
      setShowUpdateStatusDialog(false)
      setCurrentNote('')
    } catch (e) {
      setShowSnack(true)
      setSnackMessage('Could not checkin')
      setSnackType('error')
    }
  }
  const doClose = async() => {
    try {
      const result = await updateStatus(selectedBooking, 'closed')
      const _bookings = [...bookings];
      const index = _bookings.findIndex(book => book._id === selectedBooking)
      _bookings[index].status='close'
      setBookings(_bookings);
      setShowSnack(true)
      setSnackMessage('Closed')
      setSnackType('success')
      setShowUpdateStatusDialog(false)
      setCurrentNote('')
    } catch (e) {
      setShowSnack(true)
      setSnackMessage('Could not close')
      setSnackType('error')
    }
  }
  useEffect(()=>{
    if (showCancelDialog) {
      const cancellationCharges = {
          1: 100,
          7: 40
      };
      let amount
      const selectBook = bookings.find(book => book._id === selectedBooking)
      if (selectBook) {
        const today = moment()
        const bookingDate = moment(selectBook.from)
        const difference = bookingDate.diff(today, 'days')
        if (difference >= 0) {
          let charge = 0;
          let days = Object.keys(cancellationCharges);
          for(let i = 0; i < days.length; i+=1) {
              if (difference <= days[i]) {
                  charge = cancellationCharges[days[i]]
                  break;
              }
          }
          if (!charge) {
            amount = charge
          } else {
            amount = charge/100 * selectBook.amount
          }
          setCancellationCharge(amount)
        }
      } else {
        setCancellationCharge(0)
      }
    }
  }, [showCancelDialog, bookings, selectedBooking])
  useEffect(()=>{
    const getData = async () => {
        try {
          setListLoad(true)
          let startDate, endDate;
          if (windowDimensions.width >1500) {
            startDate = moment(theDay, 'DD-MM-YYYY').subtract(1, 'days').format('DD-MM-YYYY');
            endDate = moment(theDay, 'DD-MM-YYYY').add(1, 'days').format('DD-MM-YYYY');
          } else if (windowDimensions.width > 1000) {
            //startDate = moment(theDay, 'DD-MM-YYYY').subtract(1, 'days').format('DD-MM-YYYY');
            startDate = theDay;
            endDate = moment(theDay, 'DD-MM-YYYY').add(1, 'days').format('DD-MM-YYYY');
          } else {
            startDate = endDate = theDay;
          }
          const data  = await getAllItems(startDate, endDate, props.includeCancel)
          setBookings(data)
          setListLoad(false)
        } catch (e) {
          setListLoad(false)
          if (e.status === 401) {
            dispatch(removeUserAction())
            navigate('/');
          }
        }
    }
    getData()
    if (props.bookingAdded) {
      props.resetBookingAdded();
      setShowSnack(true)
      setSnackMessage('Booking Success')
      setSnackType('success')
      //getData()
    }
    if (props.bookingEdited) {
      props.resetBookingEdited();
      setShowSnack(true)
      setSnackMessage('Edit Booking Success')
      setSnackType('success')
      //getData()
    }
      console.log(`Attaching`)
      socket.on('BOOKED', bookingId => {
        console.log(bookingId);
          getData();
      })
      socket.on('CANCELLED', bookingId => {
        console.log(bookingId);
          getData();
      })
      return ()=> {
            socket.removeAllListeners('BOOKED')
            socket.removeAllListeners('CANCELLED')
      }

  }, [theDay, props.includeCancel, props.bookingAdded, props.bookingEdited])
  useEffect(()=>{
    if (props.bookingError) {
      props.resetBookingError();
      setShowSnack(true)
      setSnackMessage('Could not book')
      setSnackType('error')
    }

  },[props.bookingError])
  if (listLoad) {
    return (
      <Paper sx={{...classes.root, display:'flex', justifyContent:'center', alignItems:'center', height:'100%'}}>
        <CircularProgress/>
      </Paper>
    )
  }
  return (
    <Paper sx={classes.root}>
        <SingleDayPane
          bookings={bookings}
          selectedStatuses={props.selectedStatuses}
          setShowCancelDialog={setShowCancelDialog}
          setShowUpdateStatusDialog={setShowUpdateStatusDialog}
          setSelectedBooking={setSelectedBooking}
          setEditableBooking={props.setEditableBooking}
          setShowEditBooking={props.setShowEditBooking}
          selectedBooking={selectedBooking}
          theDay={theDay}
          moveNext={moveNext}
          movePrev={movePrev}
          windowDimensions={windowDimensions}
        />
        <CancelDialog doCancelBooking={doCancelBooking} setCharge={setCancellationCharge} charge={cancellationCharge} open={showCancelDialog} setOpen={setShowCancelDialog}/>
        <UpdateStatusDialog
          advance={advance}
          setAdvance={setAdvance}
          bookings={bookings}
          selectedBooking={selectedBooking}
          doCheckout={doCheckout}
          currentNote={currentNote}
          setCurrentNote={setCurrentNote}
          doCheckIn={doCheckIn}
          doClose={doClose}
          open={showUpdateStatusDialog}
          setOpen={setShowUpdateStatusDialog}
        />
        <SnackBar message={snackMessage} open={showSnack} handleClose={()=>{setShowSnack(false)}} severity={snackType}/>
    </Paper>
  );
}
