
import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, MenuItem, Menu } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import {getStore} from '../util/index.js'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { createTheme, alpha } from '@mui/material/styles';

const styleObj = {
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: 2,
  },
  title: {
    display: 'block'
  },
  search: {
    position: 'relative',
    borderRadius: 1,
    backgroundColor: alpha('rgb(256, 256, 256)', 0.15),
    '&:hover': {
      backgroundColor: alpha('rgb(256, 256, 256)', 0.25),
    },
    marginRight: 2,
    marginLeft: {xs:3, sm: 3},
    width: {xs:'auto', sm: 'auto',  md: '100%', lg: '100%', xl:'100%'}
  
  },
  searchIcon: {
    padding: '0 2',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: '1, 1, 1, 0',
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + 4px)`,
    width: {xs:'100%', sm: '100%', md: '20ch', lg: '20ch', xl:'20ch'},
  },
  sectionDesktop: {
    display: {xs: 'none', sm:'none', md: 'flex',  lg: 'flex', xl:'flex'}
  },
  container: {
    width:'100%'
  },
  sectionMobile: {
    display: {xs: 'flex', sm:'flex', md: 'none',  lg: 'none', xl:'none'}
  },
};

const theme = createTheme();

export default function PrimarySearchAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  }

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
     <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  return (

      <div style={styleObj.container}>
        <AppBar position="static">
          <Toolbar>

            <Typography sx={styleObj.title} variant="h6" noWrap>
              {getStore('user')?.user?.storeName}
            </Typography>

            <div style={styleObj.grow} />
            <div style={styleObj.sectionDesktop}>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMenu}
      </div>
  );
}
