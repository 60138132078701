import {getStore, removeItem, checkStatusError} from '../util';
import {ValidationError} from '../util/errors';

const getItemsByProduct = async (productId) => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
      }
  const response =  await fetch(`/api/admin/product/${productId}/items` ,{
         headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        }
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
};

const addItem = async(data) => {
    const user = getStore('user')  
  const response =  await fetch(`/api/admin/item`,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        },
        body: JSON.stringify(data)
    });
    if (response.ok) {
        return response.json();
    }
    return response.json().then(text => { throw  {message:text.error, status: response.status }})
    
}
const editItem = async(id, data) => {
    const user = getStore('user')
  const response =  await fetch(`/api/admin/item/${id}`,{
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        },
        body: JSON.stringify(data)
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
}
const delItem = async(itemId) => {
    const user = getStore('user')
  const response =  await fetch(`/api/admin/item/${itemId}`,{
        method: 'DELETE',
        headers: {
            'x-access-token': user.accessToken
        },
    });
    console.log(response.ok);
    if (response.ok) {
        return ;
    }
    throw response;
}

export {getItemsByProduct, addItem, editItem, delItem}
