import React, { startTransition, useEffect, useState } from 'react';
import { Container, Card, TextareaAutosize, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography, TextField, Stack, Avatar } from '@mui/material';

import {getAvailableProducts} from '../services/products.js';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';

import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { addBook } from '../services/booking.js';
import {getActiveUsers} from '../services/user.js';

const styles = {
  root: {
    margin: 0,
    padding:2,
  },
  closeButton: {
    position: 'absolute',
    right: 1,
    top: 1,
    color: 'palette.grey[500]',
  },
};

const styleObj = {
  body:{
    width:'100%',
    display:'flex',
    height: {xs:'60vh', sm:'60vh', md:'100%', lg:'100%'}
  },
  dateCont: {
      display: 'flex',
      marginBottom: 10
  },
  dateInd: {
      marginRight: 20
  },
  tenantCont: {
      marginBottom : 20
  },
  itemCont: {
    marginBottom : 20
  },
  left: {
      width: '55%'
  },
  right: {
    width: '50%',
    position: 'relative',
    height: 'calc(100% - 55px)',
    paddingBottom: '55px',
  },
  selItemCont: {
    display: 'flex',
    marginBottom:5,
    position: 'relative',
    minHeight:'80px'
  },
  selItemImg: {
    width: 100,
    objectFit:'contain'
  },
  selItemImgCont: {
    marginRight: 10,
    marginBottom: 5,
    display:'flex'
  },
  selItemDetCont: {
    marginTop:5
  },
  selItemActionCont: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: 0
  },
  totalCont: {
    position:'absolute',
    bottom:0,
    width: '100%'
  },
  selTotCont: {
    display: 'flex',
    height: 60,
    alignItems:'center'
  },
  totalEl: {
    width: 100,
    marginRight: 10,
    color: 'black',
    height: '15px'
  },
  dialoge: {
    height:'100%',
    maxHeight: '500px'
}, 
};

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const DialogTitleComponent =(props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography style={{display: 'flex', position: 'relative'}} className={styles.root} {...other}>
      <Typography variant="h6" >{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close"  style={{right: '25px', position: 'absolute'}} className={styles.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DialogContentComponent = DialogContent;

const DialogActionsComponent = DialogActions;

const DateBar = ({fromDate, setFromDate, toDate, setToDate}) => {
    return (
        <div style={styleObj.dateCont}>
            <div style={styleObj.dateInd} >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    {/*<DatePicker
                        label="From Date"
                        value={fromDate}
                        inputFormat='DD/MM/YYYY'
                        onChange={(newValue) => {
                            setFromDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}                
                      />*/}
                    <DatePicker
                        label="From Date"
                        value={fromDate}
                        minDate={moment()}
                        inputFormat='DD/MM/YYYY'
                        onChange={(newValue) => {
                            setFromDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}                
                      />
                </LocalizationProvider>
            </div>
            <div style={styleObj.dateInd}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                            label="To Date"
                            value={toDate}
                            minDate={moment(fromDate)}
                            inputFormat='DD/MM/YYYY'
                            onChange={(newValue) => {
                                setToDate(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </div>
      </div>
    )
}

const Tenant = ({tenants, setSelectedTenant}) => {
    return (
        <div style={styleObj.tenantCont}>
            <Autocomplete
            disablePortal
            getOptionLabel={option => option.user.name}
            id="combo-box-tenant"
            options={tenants}
            onChange={(e, v, r)=>{setSelectedTenant(v.user._id)}}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                <Avatar alt={option.user.name} src={option?.user?.image?.thumb}/>
                <Typography>{option.user.name}</Typography>
                </Stack>
              </li>
            )}
            sx={{ width: { xs: 250, sm: 300, md:300, lg: 300, xl: 300 } }}
            ListboxProps={{
              style: {
              maxHeight:'200px',
              zIndex:5
              }
            }}
            renderInput={(params) => <TextField {...params} label="Tenant" />}
            />
        </div>
      );
}

const ItemSelect = ({items, displayItem, setDisplayItem, setSelectedItems, selectedItems}) => {
    const handleSelect = (event, value, reason) => {
        if (value) {
            value.count = 1;
            const index = selectedItems.findIndex(selItem =>selItem._id === value._id)
            if (index < 0) {
                const _selectedItems = [...selectedItems, value]
                setSelectedItems(_selectedItems)
            }
            setDisplayItem({name: ''})
        }
    }
    
    return (
        <div style={styleObj.itemCont}>
            <Autocomplete
            disablePortal
            ListboxProps={{
              style: {
              maxHeight:'200px',
              zIndex:5
              }
            }}
            getOptionLabel={option => option.name}
            id="combo-box-item"
            clearIcon={null}
            value={displayItem}
            onInputChange={(event, value, reason)=>{setDisplayItem({name: value})}}
            onChange={handleSelect}
            options={items}
            sx={{ width: { xs: 250, sm: 300, md:300, lg: 300, xl: 300 } }}
            renderInput={(params) => <TextField {...params} label="Item" />}
            />
        </div>
      );
}

const SelectedItemsCard = ({product, isMobile, selectedItems, setSelectedItems}) => {
  const handleRemove = (id) => {
      return (e) => {
        const index = selectedItems.findIndex(selItem =>selItem._id === id)
        if (index > -1) {
          const _selectedItems = [...selectedItems];
          _selectedItems.splice(index,1);
          setSelectedItems(_selectedItems)
        }
      }
    }
    const increment = (id) => {
      return (e) => {
        const index = selectedItems.findIndex(selItem =>selItem._id === id)
        if (index > -1 && selectedItems[index].count < selectedItems[index].availableItemsCount) {
          const _selectedItems = [...selectedItems];
          _selectedItems[index].count += 1
          setSelectedItems(_selectedItems)
        }
      }
    }
    const decrement = (id) => {
      return (e) => {
        const index = selectedItems.findIndex(selItem =>selItem._id === id)
        if (index > -1 && selectedItems[index].count > 1) {
          const _selectedItems = [...selectedItems];
          _selectedItems[index].count -= 1
          setSelectedItems(_selectedItems)
        }
      }
    }
  return(
    <Card sx={styleObj.selItemCont}>
      <div  style={styleObj.selItemImgCont}><img style={styleObj.selItemImg} src={product.image.thumb}/></div>
      <div style={styleObj.selItemDetCont}>
        <Typography variant="subtitle2" gutterBottom component="div">{product.name}</Typography>
        <Typography variant="caption" display="block" gutterBottom component="div">₹ {product.rent} /-</Typography>
      </div>
      <div style={isMobile ? {bottom:'0px', ...styleObj.selItemActionCont} : styleObj.selItemActionCont}>
        <div><IconButton disabled={product.count === 1} onClick={decrement(product._id)}><RemoveIcon/></IconButton></div>
        <div>{product.count}</div>
        <div><IconButton disabled={product.count === product.availableItemsCount} onClick={increment(product._id)}><AddIcon/></IconButton></div>
        <div><IconButton  onClick={handleRemove(product._id)}><DeleteIcon/></IconButton></div>
      </div>
    </Card>
  )
}

const TotalCard = ({selectedItems=[], isMobile, advance, setAdvance, numberofDays, setAmount, discount, setDiscount}) => {
  const [total, setTotal] = useState(0);
  useEffect(()=>{
    setAmount(total-discount);
  },[discount, total])
  useEffect(()=>{
    const _total = selectedItems.reduce((acc, item)=>{return acc+(parseInt(item.rent, 10)*numberofDays*item.count)}, 0)
    setTotal(_total)
  },[selectedItems, numberofDays])
  if (Array.isArray(selectedItems) && selectedItems.length > 0) {
    return (
      <Card sx={styleObj.selTotCont}>
        <div><TextField sx={styleObj.totalEl} variant="standard" style={isMobile ? {...styleObj.totalEl, width:'75px'} : styleObj.totalEl} disabled={true} label="Gross Total" value={`₹ ${total}/-`} /></div>
        <div> <TextField sx={styleObj.totalEl} variant="standard" style={isMobile ? { ...styleObj.totalEl, width:'75px'} : styleObj.totalEl} value={discount} onChange={(e)=>{setDiscount(e.target.value)}} defaultValue={0} label="Discount (₹)" /></div>
        <div> <TextField sx={styleObj.totalEl} variant="standard" style={isMobile ? {...styleObj.totalEl, width:'75px'} : styleObj.totalEl} value={advance} onChange={(e)=>{setAdvance(e.target.value)}} defaultValue={0} label="Advance (₹)" /></div>
        <div><TextField sx={styleObj.totalEl} variant="standard" style={isMobile ? { ...styleObj.totalEl, width:'75px'} : styleObj.totalEl} disabled={true} label="Net Total" value={`₹ ${total - discount}/-`} /></div>
      </Card>
    )
  }
  return null;
}

export default function CustomizedDialogs(props) {
    const [saveLoad, setSaveLoad] = useState(false);
    const [fromDate, setFromDate] = useState(new Date())
    const [toDate, setToDate] = useState(new Date())
    const [tenants, setTenants] = useState([])
    const [selectedTenant, setSelectedTenant] = useState();
    const [selectedItems, setSelectedItems] = useState([]);
    const [displayItem, setDisplayItem] = useState({name:''});
    const [items, setItems] = useState([])
    const [numberofDays, setNumberofDays] = useState(1)
    const [note, setNote] = useState('');
    const [amount, setAmount] = useState(0)
    const [discount, setDiscount] = useState(0);
    const [advance, setAdvance] = useState(0);
    const saveTitle = 'Save'
    const isMobile = getWindowDimensions().width < 500 ? true : false;
    React.useEffect(()=>{
      if (props.editableProduct) {
          const getProductData  = async(id) => {
            //const data =  await getProductById(id);
            //console.log(data);
            //setFields(data);
            //setProduct(data);
          }
          getProductData(props.editableProduct)          
      }
    }, [props.editableProduct])
    useEffect(()=>{
        const fetchTenants = async() => {
          const _tenants = await getActiveUsers();
          setTenants(_tenants.filter(x=>x.status=== "APPROVED"))
        }
        fetchTenants()
    }, [])
    useEffect(()=>{
        const getData = async() => {
            if (moment(toDate).startOf('day').isSameOrAfter(moment(fromDate).startOf('day'))) {
                setNumberofDays(moment(toDate).startOf('day').diff(moment(fromDate).startOf('day'), 'days')+1)
                const data = await getAvailableProducts(moment(fromDate).format('MM-DD-YYYY'), moment(toDate).format('MM-DD-YYYY'));
                setSelectedItems([])
                setItems(data)
            }
        }
        getData()
    }, [fromDate, toDate])
    const resetFields = () => {
      setFromDate(new Date())
      setToDate(new Date())
      setSelectedTenant('')
      setAdvance(0);
      setDiscount(0);
    }
    const handleAddBookingClose = (e) => {
        props.setShowAddBooking(false);
        resetFields()
    }
    const handleClick =()=> { 
        return async (e) => {
          const data = {
            from:fromDate,
            to: toDate,
            tenant: selectedTenant,
            products: selectedItems.map(item=>{return{id: item._id, count: item.count}}),
            note:note,
            amount:amount,
            discount:discount,
            advance:advance
          }
          try {
            setSaveLoad(true)
            const resp = await addBook(data)
            props.setBookingAdded()
            setSaveLoad(false)
            handleAddBookingClose();
          } catch (e) {
            console.log(e)
            setSaveLoad(false)
            props.setBookingError('Could not book')
          }
        }
    }

    const diffTitle = numberofDays > 1 ? 'days' : 'day';
    let dayName ='';
    for (var m = moment(fromDate); m.diff(toDate, 'days') <= 0; m.add(1, 'days')) {
      dayName += m.format('ddd') + ', ';
    }
    dayName = dayName.slice(0, -2); 
  return (
      <Dialog sx={{height:{xs:'90vh', sm:'90vh'}}} onClose={handleAddBookingClose} fullWidth={true}
        maxWidth={"md"} aria-labelledby="customized-dialog-title" open={props.open}>
        <DialogTitleComponent id="customized-dialog-title" onClose={handleAddBookingClose}>
          Add New Booking
        </DialogTitleComponent>
        <DialogContentComponent sx={{height:{xs:'90vh', sm:'90vh'}}} dividers style={{height: '100%'}}>
            <Container sx={styleObj.body} style={isMobile ? {flexDirection:'column'} : {}}>
                <div style={isMobile ? {...styleObj.left, width:'100%' } : styleObj.left}>
                    <DateBar fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate}/>
                    <Typography variant="caption" display="block" gutterBottom component="div">{` ${numberofDays} ${diffTitle} (${dayName})`   } </Typography>
                    <Tenant tenants={tenants} selectedTenant={selectedTenant} setSelectedTenant={setSelectedTenant}/>
                    <ItemSelect items={items} selectedItems={selectedItems} setSelectedItems={setSelectedItems} displayItem={displayItem} setDisplayItem={setDisplayItem}/>
                    {!isMobile && (<TextareaAutosize
                        aria-label="Notes"
                        placeholder="Notes"
                        minRows={5}
                        value={note}
                        onChange={(e)=>{setNote(e.target.value)}}
                        style={{ width: '90%' }}
                    />)}
                </div>
                <div style={isMobile ? { ...styleObj.right, width:'100%'} : styleObj.right}>
                  <div style={{maxHeight:'300px', overflowY:'scroll'}}>
                    {selectedItems.map(sel=><SelectedItemsCard isMobile={isMobile} selectedItems={selectedItems} setSelectedItems={setSelectedItems} key={sel._id} product={sel}/>)}
                  </div>
                    <div style={styleObj.totalCont}>
                      <TotalCard isMobile={isMobile} advance={advance} setAdvance={setAdvance} discount={discount} setDiscount={setDiscount} setAmount={setAmount} numberofDays={numberofDays} selectedItems={selectedItems}/>
                    </div>
                </div>
            </Container>
        </DialogContentComponent>
        <DialogActionsComponent>
          <Button onClick={handleAddBookingClose} color="secondary">
            Cancel
          </Button>
          <Button disabled={saveLoad} autoFocus onClick={handleClick()} color="primary">
            {saveTitle} {saveLoad && <CircularProgress size={20} sx={{ color: 'blue', marginLeft:'20px' }}/>}
          </Button>
        </DialogActionsComponent>
      </Dialog>
  );
}
