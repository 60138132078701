import { Types } from '../constants/actionTypes';
import { addItem, delItem, editItem} from "../services/item.js";

const addItemAction = (data, file) => dispatch => {
    dispatch({type: Types.ADD_ITEM_LOAD});
    return addItem(data).then(
        (data) => {
            dispatch({
            type: Types.ADD_ITEM_SUCCESS,
            payload: { item: data },
        });
        return Promise.resolve();
    },
    (error) => {
        console.log('>>', error.status)
        if (error.status===401) {
            dispatch({type: Types.LOGOUT_UNAUTH})
        }
        dispatch({
            type: Types.ADD_ITEM_FAIL,
            payload:error
        });
        return Promise.reject();
        }
    );
}

const editItemAction = (id, data) => dispatch => {
    dispatch({type: Types.EDIT_ITEM_LOAD});
    return editItem(id, data).then(
        (data) => {
            dispatch({
            type: Types.EDIT_ITEM_SUCCESS,
            payload: { id: id, item: data },
        });
        return Promise.resolve();
    },
    (error) => {
        console.log('>>', error.status)
        if (error.status===401) {
            dispatch({type: Types.LOGOUT_UNAUTH})
        }
        dispatch({
            type: Types.EDIT_ITEM_FAIL,
            payload:error
        });
        return Promise.reject();
        }
    );
}

const deleteItemAction = (itemId) => dispatch => {
    dispatch({type: Types.DEL_ITEM_LOAD});
    return delItem(itemId).then(data=>{
        dispatch({
            type: Types.DEL_ITEM_SUCCESS,
            payload: { item: itemId }
        })
    }, error=>{
        console.log('>>', error.status)
        if (error.status===401) {
            dispatch({type: Types.LOGOUT_UNAUTH})
        }
        dispatch({
            type: Types.DEL_ITEM_FAIL,
            payload:error
        });
        return Promise.reject();
    })
}

const resetItemAction = () => dispatch => {
    dispatch({type: Types.RESET_ITEM})
}

export {addItemAction, editItemAction, deleteItemAction, resetItemAction}