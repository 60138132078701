
export const Types = {
    LOGIN: 'LOGIN',
    LOGIN_SUCCESS:'LOGIN_SUCCESS',
    LOGIN_FAIL:'LOGIN_FAIL',
    AUTH_LOAD:'AUTH_LOAD',
    GET_ALL_PRODUCTS_SUCCESS: 'GET_ALL_PRODUCTS_SUCCESS',
    GET_ALL_PRODUCTS_LOAD: 'GET_ALL_PRODUCTS_LOAD',
    GET_ALL_PRODUCTS_FAIL: 'GET_ALL_PRODUCTS_FAIL',
    ADD_PRODUCT_SUCCESS:'ADD_PRODUCT_SUCCESS',
    ADD_PRODUCT_LOAD: 'ADD_PRODUCT_LOAD',
    ADD_PRODUCT_FAIL: 'ADD_PRODUCT_FAIL',
    ADD_USER: 'ADD_USER',
    EDIT_PRODUCT_SUCCESS:'EDIT_PRODUCT_SUCCESS',
    EDIT_PRODUCT_LOAD: 'EDIT_PRODUCT_LOAD',
    EDIT_PRODUCT_FAIL: 'EDIT_PRODUCT_FAIL',
    DEL_PRODUCT_LOAD: 'DEL_PRODUCT_LOAD',
    DEL_PRODUCT_SUCCESS:'DEL_PRODUCT_SUCCESS',
    DEL_PRODUCT_FAIL: 'DEL_PRODUCT_FAIL',
    ADD_ITEM_SUCCESS:'ADD_ITEM_SUCCESS',
    ADD_ITEM_LOAD: 'ADD_ITEM_LOAD',
    ADD_ITEM_FAIL: 'ADD_ITEM_FAIL',
    EDIT_ITEM_SUCCESS:'EDIT_ITEM_SUCCESS',
    EDIT_ITEM_LOAD: 'EDIT_ITEM_LOAD',
    EDIT_ITEM_FAIL: 'EDIT_ITEM_FAIL',
    DEL_ITEM_LOAD: 'DEL_ITEM_LOAD',
    DEL_ITEM_SUCCESS:'DEL_ITEM_SUCCESS',
    DEL_ITEM_FAIL: 'DEL_ITEM_FAIL',
    RESET_ITEM: 'RESET_ITEM',
    LOGOUT_UNAUTH:'LOGOUT_UNAUTH'
  }