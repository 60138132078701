import React, { Component } from 'react';
import {Grid} from '@mui/material';
import  AppBar  from '../components/appbar.js';
import  SideBar  from '../components/sidebar.js';
import BookingList from '../components/bookingList.js'
import TopBar from '../components/bookingTopBar.js'
import AddBooking from '../components/addBooking.js'
import EditBooking from '../components/editBooking.js'
import socket from '../components/socket.js'
import {getStore} from '../util/index.js'


const classes = {
  root: {
    flexDirection: "column",
  },
  subcontainer:{
    width: '100%',
    margin:'10px 10px 0px 10px'
  },
  image: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: '8 4',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: 1,
    backgroundColor: 'secondary.main'
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: 1
  },
  submit: {
    margin: '3, 0, 2'
  },
  container : {
    display: "flex",
    flexDirection: "row",
    width: '100%',    
  },
  subcontainer:{    
    marginLeft: 10,
    marginTop: 10,
    width: '100%' 
  }
};

class Home extends Component { 
  constructor(props) {
    super(props);
    this.state = {
          includeCancel:false,
          showAddBooking: false,
          showEditBooking: false,
          bookingAdded: false,
          bookingEdited: false,
          bookingError:undefined,
          editableBooking : undefined,
          selectedStatuses: ['booked', 'checkedin', 'checkedout', 'closed']
    }
    
  }
  componentDidMount() {
      const store = getStore('user')?.user?.store;
      socket.emit('REG', store)
    }
    setSelectedStatuses = (e, newStatuses) => {
      this.setState({selectedStatuses: newStatuses})
    }
    toggleIncludeCancel = () => {
      this.setState({includeCancel: !this.state.includeCancel})
    }
    setShowAddBooking = (shouldShow) => {
      this.setState({showAddBooking: shouldShow})
    }
    resetBookingAdded = () => {
      this.setState({bookingAdded: false})
    }
    setBookingAdded = () => {
      this.setState({bookingAdded: true})
    }
    setBookingEdited = () => {
      this.setState({bookingEdited: true})
    }
    resetBookingEdited = () => {
      this.setState({bookingEdited: false})
    }
    setBookingError = (message) => {
      this.setState({bookingError: message})
    }
    resetBookingError = () => {
      this.setState({bookingError: undefined})
    }
    setEditableBooking = (bookingId) => {
      this.setState({editableBooking: bookingId})
    }
    setShowEditBooking = (val) => {
      this.setState({showEditBooking: val})
    }
    render = ()=> {
      return (
        <Grid container component="main" sx={classes.root}>
            <AppBar/>
            <div style={classes.container}>
              <SideBar/>
              <div style={classes.subcontainer}>
                <TopBar
                  includeCancel={this.state.includeCancel}
                  selectedStatuses={this.state.selectedStatuses}
                  setSelectedStatuses={this.setSelectedStatuses}
                  setShowAddBooking={this.setShowAddBooking}
                  toggleIncludeCancel={this.toggleIncludeCancel}
                />
                <BookingList
                  selectedStatuses={this.state.selectedStatuses}
                  resetBookingError={this.resetBookingError}
                  bookingError={this.state.bookingError}
                  resetBookingAdded={this.resetBookingAdded}
                  resetBookingEdited={this.resetBookingEdited}
                  bookingEdited={this.state.bookingEdited}
                  bookingAdded={this.state.bookingAdded}
                  setShowEditBooking={this.setShowEditBooking}
                  setEditableBooking={this.setEditableBooking}
                  includeCancel={this.state.includeCancel}
                />
                <AddBooking
                  setBookingError={this.setBookingError}
                  open={this.state.showAddBooking}
                  setBookingAdded={this.setBookingAdded}
                  setShowAddBooking={this.setShowAddBooking}
                />
                <EditBooking
                  setBookingError={this.setBookingError}
                  open={this.state.showEditBooking}
                  setBookingEdited={this.setBookingEdited}
                  setShowEditBooking={this.setShowEditBooking}
                  id={this.state.editableBooking}
                  setEditableBooking={this.setEditableBooking}
                />
              </div>
            </div>
        </Grid>
      );
    };
};




export default Home;
