import { createContext, useState, useEffect} from 'react';
import {checkNewRequest} from '../services/user.js'
import socket  from '../components/socket.js'
const RequestContext = createContext();

export function RequestProvider({ children }) {
    const [hasRequest, setHasRequest] = useState(false);
    const getRequestData = async () => {
        try {
            const {count} = await checkNewRequest();
            console.log(count)
            if (count) {
                setHasRequest(true);
            } else {
                setHasRequest(false)
            }
        } catch (e) {
            setHasRequest(false)
        }
    };
    useEffect(() => {       
        getRequestData();
    }, [])
    socket.on('REQUEST_ADD_STORE', ()=> {
        console.log('new request')
        getRequestData();
    })
    return (
        <RequestContext.Provider value={{ hasRequest, setHasRequest }}>
            {children}
        </RequestContext.Provider>
    );
}

export default RequestContext;
