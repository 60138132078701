import React, { useEffect, useState } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, PieChart, Pie, Sector, Cell } from 'recharts';

  const ProductMonthChart = ({schedule, product, isMobile, width}) => {
    const dim = isMobile ? {width: 2*(width-80), height: 200} : {width: 1200, height: 450}
  return (
    <div>
      <div style={{marginTop: '10px'}}>
        <div style={{fontSize: '1.75rem',fontWeight: 'bold', marginBottom : '20px'}}> Booking Distribution</div>
          <div style={{width: '100%',overflowX: 'scroll',overflowY:'hidden'}}>
            <LineChart width={dim.width} height={dim.height} data={schedule} margin={{ top: 10, right: 10, bottom: 5, left: 0 }}>
              <Line type="monotone" dataKey="booked" stroke="#8884d8" />
              {!isMobile && <CartesianGrid stroke="#ccc" strokeDasharray="10 10" />}
              <XAxis dataKey={isMobile ? "shortDate" : "date"} />
              <YAxis type="number" domain={[0, product?.noOfAllItems??0]}  allowDecimals={false} />
              <Tooltip />
            </LineChart>
          </div>
      </div>
      <div>
        <div style={{fontSize: '1.75rem',fontWeight: 'bold',marginBottom : '20px'}}> Revenue Distribution</div>
          <div style={{width: '100%',overflowX: 'scroll',overflowY:'hidden'}}>
            <LineChart width={dim.width} height={dim.height} data={schedule} margin={{ top: 10, right: 10, bottom: 5, left: 1 }}>
              <Line type="monotone" dataKey="totalClosed" stroke="#1399a7" />
              <Line type="monotone" dataKey="totalExpected" stroke="#FA2FB5" />
              <Line type="monotone" dataKey="totalPending" stroke="#FF6700" />
              <Line type="monotone" dataKey="total" stroke="#000" />
              {!isMobile && <CartesianGrid stroke="#ccc" strokeDasharray="10 10" />}
              <XAxis dataKey={isMobile ? "shortDate" : "date"} />
              <YAxis type="number" domain={[0, product.rent * product.noOfItems]}  allowDecimals={false} />
              <Tooltip />
            </LineChart>
          </div>
        </div>
  </div>
  );
  }

  const CountPie = ({data}) => {
    console.log(data)
    return (
      <PieChart width={30} height={30}>
      <Pie
        data={data}
        cx={'50%'}
        cy={'50%'}
        innerRadius={8}
        outerRadius={14}
        fill="#8884d8"
        paddingAngle={1}
        dataKey="value"
      >
        {data.map((entry, index) => 
        {
          console.log(entry)
          return(
          <Cell key={`cell-${index}`} fill={entry.name === 'booked' ? 'red':'green'} />
        )
        })}
      </Pie>
      </PieChart>
    )
  }


  export  {ProductMonthChart, CountPie};
