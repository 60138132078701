import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {DialogWindow, CustomAvatar} from  './general';
import {getBookDetails} from '../services/booking.js';
import PhoneIcon from '@mui/icons-material/Phone';
import clsx from  'clsx';
const classes = {
    detCont: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
        height: '40px'
    },
    detInd: {
        marginRight: '10px',
        marginLeft: '10px',
        whiteSpace: 'nowrap'
    },
    status: {
        borderRadius: '5px',
        color:'white',
        padding: '4px'
    },
    tel: {
        textDecoration: 'none',
        color: 'black'
    },
    statusBar: {
      height: '100%',
      minWidth: '5px'
    }
}
const DateRow = ({from , to, format, isMobile}) => {
  if (from === to ) {
    return (
      <div style={isMobile ? {...classes.detInd, marginLeft:'3px', marginRight: '3px'} : classes.detInd}>{moment(from).format(format)}</div>
    )
  }
  return (
    <>
    <div style={isMobile ? {...classes.detInd, marginLeft:'3px', marginRight: '3px'} : classes.detInd}>{moment(from).format(format)}</div>
    to
    <div style={isMobile ? {...classes.detInd, marginLeft:'3px', marginRight: '3px'} : classes.detInd}>{moment(to).format(format)}</div>
    </>
  )
}
const IndBooking = ({detail, isMobile}) => {
    const getStyle= (status) =>{
        if (status==='booked') {
            return {backgroundColor: '#54A0FF', filter: `drop-shadow(0 0 0.5rem #54A0FF)`}
            
          }
          if (status === 'checkedout') {
            return {backgroundColor: '#F39C12', filter: `drop-shadow(0 0 0.5rem #F39C12)`}
          }
          if (status === 'checkedin') {
            return {backgroundColor: '#E74C3C', filter: `drop-shadow(0 0 0.5rem #E74C3C)`}
          }
          if (status === 'closed') {
            return {backgroundColor: '#30CB83', filter: `drop-shadow(0 0 0.5rem #30CB83)`}
          }
          if (status === 'cancelled') {
            return {backgroundColor: '#34495E', filter: `drop-shadow(0 0 0.5rem #34495E)`}
          }
    }
    const format = isMobile ? 'DD-MM' : 'DD-MM-YYYY'
    return (
        <div style={classes.detCont}>
            {isMobile && (<div style={{...classes.statusBar, backgroundColor: getStyle(detail.status).backgroundColor}}></div>)}
            <CustomAvatar  src={detail?.tenant?.image?.thumb??''} name={detail?.tenant?.name}/>
            <div style={isMobile ? {...classes.detInd, marginLeft:'3px', marginRight: '3px'} : classes.detInd}>{detail?.tenant?.name}</div>
            <DateRow from={detail.from} to={detail.to} format={format} isMobile={isMobile}/>
            {!isMobile && (<div style={{...classes.detInd, ...classes.status, ...getStyle(detail.status)}}>{detail.status}</div>)}
            {!isMobile &&<div style={classes.detInd}><a style={classes.tel} href={`tel:${detail?.tenant?.contactNumber}`}>{detail?.tenant?.contactNumber}</a></div>}
            {isMobile && (<div style={classes.detInd}><a style={classes.tel} href={`tel:${detail?.tenant?.contactNumber}`}><PhoneIcon/></a></div>)}
        </div>
        
    )
}

const Body = ({bookDetails=[], isMobile}) => {    
    return (
      <div style={classes.delDialogCont}>
        <div style={classes.delDialogLeft}>
          {bookDetails.map(det => <IndBooking isMobile={isMobile} detail={det}/>)}
        </div>
      </div>
    )
  }

export default function BookingList({isMobile, selectedBookings, open, setOpen}) {
    const [bookDetails, setBookDetails] = useState([]);
    useEffect(()=>{
        const getDetail = async() => {
             const _details = await getBookDetails(selectedBookings)
             setBookDetails(_details)
        }
        if (open) {
            getDetail()
        }
      }, [open])
    return (
        <DialogWindow title={'Booking Details'} Body={<Body isMobile={isMobile} bookDetails={bookDetails}/>} Actions={null} open={open} setOpen={setOpen}  maxWidth={'sm'}/>
    )
}