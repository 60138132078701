import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
} from '@mui/material';

const FlagConfirmation = ({flagConfirmOpen, handleClose, flagReason, setFlagReason, doFlag}) => {

  return (
      <Dialog open={flagConfirmOpen} onClose={handleClose}  maxWidth="sm" fullWidth>
        <DialogTitle>Enter reason for flagging</DialogTitle>
        <DialogContent>
          <TextareaAutosize
              style={{width:'100%', height:100}}
            placeholder="Type your reason here..."
            value={flagReason}
            onChange={(e)=>{setFlagReason(e.target.value)}}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={doFlag} variant="contained" color="primary">
            Flag
          </Button>
        </DialogActions>
      </Dialog>
  );
};

export default FlagConfirmation;
