import {getStore, removeItem, checkStatusError} from '../util';
import {ValidationError} from '../util/errors';

const getAllItems = async (from, to, includeCancel) => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
      }
      let url = `/api/admin/actions?from=${from}&to=${to}`;
      if (includeCancel) {
        url += `&includeCancel=true`
      }
  const response =  await fetch(url, {
         headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        }
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
};


const cancelBook = async (id, amount) => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
    }
    const response =  await fetch(`/api/admin/booking/${id}` ,{
        method: 'DELETE',
        headers: {
           'Content-Type': 'application/json',
           'x-access-token': user.accessToken
       },
       body: JSON.stringify({amount: amount})
   });
   if (response.ok) {
        return ;
    }
    throw response;

}
const getBookingById = async (id) => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
      }
    const response =  await fetch(`/api/admin/booking/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        }
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
};
const updateStatus = async (id, status, notes, advance, checks) => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
    }
    const response =  await fetch(`/api/admin/booking/${id}/status` ,{
        method: 'PUT',
        headers: {
           'Content-Type': 'application/json',
           'x-access-token': user.accessToken
       },
       body: JSON.stringify({status: status, notes:notes, advance:advance, checks})
   });
   if (response.ok) {
        return response.json();
    }
    throw response;

}

const editBook = async(id, data) => {
    const user = getStore('user')  
    const response =  await fetch(`/api/admin/booking/${id}`,{
        method: 'PUT',
        headers: {
        'Content-Type': 'application/json',
        'x-access-token': user.accessToken
        },
        body: JSON.stringify(data)
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
}

const addBook = async(data) => {
    const user = getStore('user')  
    const response =  await fetch(`/api/admin/booking`,{
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        'x-access-token': user.accessToken
        },
        body: JSON.stringify(data)
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
}

const getAllBookings = async(from, to, statuses, includeCancel) => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
    }
    const statusesString = statuses.map(status=>`statuses[]=${status}&`).join('')
    statusesString.slice(0, -2)
    let url = `/api/admin/booking?from=${from}&to=${to}&${statusesString}&includeCancel=${includeCancel}`;
    const response =  await fetch(url, {
         headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        }
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
}
const getBookDetails = async(ids) => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
    }
    const idString = ids.map(id=>`ids[]=${id}&`).join('')    
    idString.slice(0, -2)
    let url = `/api/admin/bookings?${idString}`;
    const response =  await fetch(url, {
         headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        }
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
}
export {getAllItems, cancelBook, updateStatus, addBook, getAllBookings, getBookDetails, getBookingById, editBook}
