import {getStore, removeItem, checkStatusError} from '../util';
import {ValidationError} from '../util/errors';

const getUsers = async () => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
      }
  const response =  await fetch(`/api/admin/user` ,{
         headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        }
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
};

const getUserById = async (id) => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
      }
  const response =  await fetch(`/api/admin/user/${id}` ,{
         headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        }
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
};

const checkUserExists = async (val) => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
      }
  const response =  await fetch(`/api/admin/customerexists?inuser=${val}` ,{
         headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        }
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
};

const addUser = async(data, file, proof, proofBack) => {
    const user = getStore('user')
    const formData = new FormData();
    formData.append('image', file);
    formData.append('proof', proof)
    formData.append('proofBack', proofBack)
    formData.append('data', JSON.stringify(data));
    const response =  await fetch(`/api/admin/user`,{
        method: 'POST',
        headers: {
            'x-access-token': user.accessToken
        },
        body: formData
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
}

const editUser = async(data, file, proof, id) => {
    const user = getStore('user')
    const formData = new FormData();
    if (file) {
        formData.append('image', file);
    }
    if (proof) {
        formData.append('proof', proof);
    }
    formData.append('data', JSON.stringify(data));
    const response =  await fetch(`/api/admin/user/${id}`, {
        method: 'PUT',
        headers: {
            'x-access-token': user.accessToken
        },
        body: formData
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
}
const accreditUser = async (id) => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
    }
  const response =  await fetch(`/api/admin/user/approve/${id}` ,{
        method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        }
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
}
const declineUser = async (id) => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
    }
  const response =  await fetch(`/api/admin/user/decline/${id}` ,{
        method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        }
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
}
const doBlockUser = async (id) => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
    }
  const response =  await fetch(`/api/admin/user/${id}/block` ,{
        method: 'PUT',
         headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        }
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
}

const doUnBlockUser = async (id) => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
    }
  const response =  await fetch(`/api/admin/user/${id}/unblock` ,{
        method: 'PUT',
         headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        }
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
}

const getActiveUsers = async () => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
      }
  const response =  await fetch(`/api/admin/user` ,{
         headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        }
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
};

const getUserStoreInfo = async (id) => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
      }
  const response =  await fetch(`/api/admin/stores/${id}` ,{
         headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        }
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
};

const doFlag = async (id, reason) => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
    }
  const response =  await fetch(`/api/admin/user/${id}/flag` ,{
        method: 'PUT',
         headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        },
      body: JSON.stringify({reason: reason})
    });
    if (response.ok) {
        return response.json();
    }
    throw response;

};

const doUnFlag = async (id) => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
    }
  const response =  await fetch(`/api/admin/user/${id}/unflag` ,{
        method: 'PUT',
         headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        }
    });
    if (response.ok) {
        return response.json();
    }
    throw response;

};

const checkNewRequest = async () => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
    }
  const response =  await fetch(`/api/admin/new-request` ,{
         headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        }
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
}
export {
    getUsers,
    getActiveUsers,
    addUser,
    editUser,
    getUserById,
    checkUserExists,
    accreditUser,
    declineUser,
    doUnBlockUser,
    doBlockUser,
    doFlag,
    doUnFlag,
    getUserStoreInfo,
    checkNewRequest
}
