import React, {Fragment, useState, useEffect, useContext} from 'react';
import {Box, Container, Tabs, Tab, Button, Dialog, DialogTitle, DialogActions, DialogContent, Typography, Stack, Card, CardContent } from '@mui/material';
import Chip from '@mui/material/Chip';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ImageUpload from './imageUpload';
import {getUserById, addUser, editUser, getUserStoreInfo, accreditUser, declineUser} from '../services/user.js';
import {SnackBar} from  '../components/general.js';
import {getStore, getWindowDimensions} from '../util/index.js'
import { ClassNames } from '@emotion/react';
import RequestContext from '../context/requestContext';
import {checkNewRequest} from '../services/user.js'

const classes = {
  body:{
    width:'100%',
    display:'flex',
    flexDirection: {xs:'column-reverse', sm: 'column', md:'row', lg:'row', xl:'row'}
  },
  left: {
    width:{xs:'100%', sm: '100%', md:'70%', lg:'70%', xl:'70%'} 
  },
  right: {
    width:{xs:'100%', sm: '100%', md:'30%', lg:'30%', xl:'30%'},
    display:'flex',
    flexDirection: 'column',
    alignItems:'center',
    justifyContent:'center' 
  },
  textArea: {
    width:'100%',
    marginTop:20
  },
  indItem: {
    marginTop: '10px'
  },
  proofCont: {
    display: 'flex',
    marginTop: 25,
    justifyContent: 'center',    
  },
  textEl: {
    marginBottom:'10px'
  }
};


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, minHeight:250 }}>
          { children}
        </Box>
      )}
    </div>
  );
}



const Row = ({label, value}) => {
    const _classes= {
        label: {
            position: "absolute",
            top: 0,
            left: 0,
            marginBottom: "10px",
            fontSize: "12px"
        },
        container: {
            position: "relative",
            paddingTop:2,
            paddingLeft:2,
            marginBottom:2
        }
    }
    return (
        <Box sx={_classes.container}>
            <Box sx={_classes.label}>{label}</Box>
        {value || " -- "}
        </Box>
    )
}

const SingleFlagRow = ({store}) => {
    return (
        <Card >
            <CardContent>
                <Stack spacing={6} direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6"> {store?.store?.name} </Typography>
                    <Typography variant="overline"> {store.store.flag} </Typography>
                </Stack>
                <Stack>
                  <Typography variant="caption"> {store?.store?.location?.name} </Typography>
                  <Typography>{store.store.contactNumber}</Typography>  
                  <Typography>{store.reason}</Typography>  
                </Stack>
            </CardContent>
        </Card>
    
    )
}

const SingleStoreRow = ({store}) => {
    let frequencyText = `Upto ${store.bookingFrequency} bookings`;
    if (store.bookingFrequency === 0) {
        frequencyText = 'No Bookings yet'
    }
    
    if (store.bookingFrequency === 101) {
        frequencyText = 'More than 100 bookings'
    }

    return (
        <Card sx={{mb: 2}}>
            <CardContent>
                <Stack spacing={6} direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6"> {store?.store?.name} </Typography>
                    {store.status === "APPROVED" && (<Typography variant="overline"> {frequencyText} </Typography>)}
                </Stack>
                <Stack>
                  <Typography variant="caption"> {store?.store?.location?.name} </Typography>
                  <Typography>{store.store.contactNumber}</Typography>  
                </Stack>
            </CardContent>
        </Card>
    
    )
}

const StoreInfo = ({storeInfo}) => {
    return (
        <Stack direction="row" spacing={5}>
            <Box sx={{maxHeight: "100%", overflowY: "auto", xs:{width:'100%' }, sm: {width:'100%'}, md: {width:'40%'}}}>
                {storeInfo.stores.map(_store=> <SingleStoreRow key={_store.id} store={_store}/>)}
            </Box>
        {/*<Box sx={{width:'40%'}}>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom:2}}>
                    <Typography variant="h5">DECLINED</Typography>
                </Box>
                {declined.length > 0 && declined.map(_store=> <SingleStoreRow key={_store.id} store={_store}/>)}
                {declined.length === 0 && (<Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}><Typography variant="body">No stores</Typography></Box>)}
            </Box>*/}

        </Stack>
    )
}

const FlagInfo = ({storeInfo}) => {
    if (storeInfo.flags.length > 0) {
        return (
            <Box sx={{width:'40%'}}>
                {storeInfo.flags.map(_store=> <SingleFlagRow key={_store.id} store={_store}/>)}
            </Box>
        )
    }
    return (<Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}><Typography variant="body">No Flags</Typography></Box>)
}

const UserInfo = ({user, setShowProof}) => {
    return (
        <Container sx={classes.body}>
            <Container maxWidth={"md"} sx={classes.left}>
                <Row label="Name" value={user?.name}/>
                <Row label="Email" value={user?.email}/>
                <Row label="Phone" value={user?.contactNumber}/>
                <Row label="AKPA NO" value={user?.akpano}/>                       
                <Row label="Address" value={user?.address}/>
            </Container>
            <Container maxWidth={"md"} sx={classes.right}>
                <img style={{height:200, width:200, borderRadius:"50%", objectFit:"cover"}} src={user?.image?.thumb}/>
                {user?.proof?.front?.thumb  && (<div style={classes.proofCont}>
                  <img onClick={()=>{setShowProof(true)}} height="100" style={{cursor:'pointer'}} src={user?.proof?.front?.thumb}/>                      
                </div>)}
            </Container>
        </Container>
    )
}

const TabLabel = ({label, count = 0}) => {
    return (
        <Stack direction="row" spacing={2} alignItems="center">
            <Typography>{label}</Typography>
            {count !== undefined && <Chip label={count}/>}
        </Stack>
    ) 
}
const TabContent = ({showProof, isMobile, setShowProof, handleAddUserClose, viewUserOpen, user, status, handleApprove, handleDecline, setStatus}) => {
    const [selectedTab, setSelectedTab] = React.useState(0)
    const [storeInfo, setStoreInfo] = useState({stores:[], flags:[]})

    useEffect(() => {
        const _getStoreData = async() => {
            if (user) {
                const _storeInfo = await getUserStoreInfo(user._id);
                const _stores = _storeInfo.filter(_store=>_store.status === "APPROVED")
                const _flags = _storeInfo.filter(_store=>_store.status === "FLAGGED")
                setStoreInfo({...storeInfo, stores: _stores, flags: _flags})
            }
        }
        _getStoreData();
    }, [user])

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    let chipVal;
    let chipColor
    if (status === 'APPROVED') {
        chipVal = 'Approved'
        chipColor = 'success'
    }
    if (status === 'DECLINED') {
        chipVal = 'Declined'
        chipColor = 'error'
    }
    if (status === 'REQUESTED') {
        chipVal = 'Requested'
        chipColor = 'default'
    }
    return (
        <div>
            {showProof && (<div style={ isMobile ? {height:'90vh', minWidth:'90wv', maxWidth:'90vw', display:'flex',  zIndex: 1301, left : '5vw', top:'5vh', position:'absolute'} : {height:'90vh', minWidth:'50wv', maxWidth:'90vw', display:'flex',  zIndex: 1301, left : '40%', top:'5vh', position:'absolute'}}>
            <img style={{objectFit: 'contain', width:'100%', height:'100%', overflow:'hidden'}} src={user?.proof?.front?.url}/>
            <div style={{cursor:'pointer', color:'black', marginLeft:'10px'}} onClick={()=>{setShowProof(false) }}><HighlightOffIcon /></div>
          </div>)}
          <Dialog onClose={handleAddUserClose} fullWidth={true}
            maxWidth={"md"} aria-labelledby="customized-dialog-title" open={viewUserOpen}>
            <DialogTitle id="customized-dialog-title" onClose={handleAddUserClose} sx={{display:'flex', alignItems:'center'}}>
                <Typography> User Details | {user?.name} </Typography>
                {chipVal && (<Chip sx={{marginLeft:2}} label={chipVal} color={chipColor} />)}
            </DialogTitle>
            <DialogContent dividers>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs sx={{ width: "100%" }} variant="scrollable" value={selectedTab} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="User Info" />
                            <Tab label={<TabLabel label="Stores" count={storeInfo.stores.length}/>} />
                            <Tab label={<TabLabel label="Flags" count={storeInfo.flags.length}/>} />
                        </Tabs>
                    </Box>
                    <TabPanel value={selectedTab} index={0}>
                        <UserInfo user={user} setShowProof={setShowProof}/>
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                        <StoreInfo storeInfo={storeInfo}/>
                    </TabPanel>
                    <TabPanel value={selectedTab} index={2}>
                        <FlagInfo storeInfo={storeInfo}/>
                    </TabPanel>
                </Box>        
            </DialogContent>
            <DialogActions>
              {status === "REQUESTED" && (
                  <Fragment>
                      <Button onClick={handleApprove} variant="contained" color="success">
                        Approve
                      </Button>
                      <Button onClick={handleDecline} variant="contained" color="error">
                        Decline
                      </Button>
                  </Fragment>
              )}
              <Button onClick={handleAddUserClose} variant="outlined" color="secondary">
                Cancel
              </Button>

            </DialogActions>
     
          </Dialog>
         
        </div>
    )
}

export default function CustomizedDialogs(props) {
    const [user, setUser] = React.useState();
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [akpano, setAkpano] = React.useState('');    
    const [file, setFile] = React.useState(undefined);    
    const [avatarUrl, setAvatarUrl] = React.useState('');
    const [proofFile, setProofFile] = React.useState(undefined);    
    const [proofUrl, setProofUrl] = React.useState('');
    const [showSnack, setShowSnack] = React.useState(false);
    const [snackType, setSnackType] = React.useState("success");
    const [snackMessage, setSnackMessage] = React.useState('');
    const [showProof, setShowProof] = React.useState(false);
    const [status, setStatus] = React.useState();
    const { hasRequest, setHasRequest } = useContext(RequestContext);
    const dim = getWindowDimensions();
    const isMobile = dim.width < 500 ? true : false

    const loggedinStore = getStore('user')?.user?.store ?? '';
    React.useEffect(()=>{
      if (props.viewUser) {
          const getUserData  = async(editUser) => {
            const data =  await getUserById(editUser.userId);
              setStatus(editUser.status)
            setUser(data);
            setFields(data);
          }
          getUserData(props.viewUser)          
      } 
    }, [props.viewUser])
    React.useEffect(()=>{
        if (props.usersChange) {
            setSnackMessage(`User ${props.usersChange}`)
            setShowSnack(true)
            handleAddUserClose()
            props.setUsersChange(undefined);
        }
    },[props.usersChange])
    
    const setFields = (data) => {
      setName(data.name)
      setFile('')
      setEmail(data.email)
      setPhone(data.contactNumber)
      setAvatarUrl(data.image.thumb)
      setProofUrl(data.proof)
      setAddress(data.address)
      setAkpano(data.akpano)
  }
    const updateCount = async () => {
        try {
            const {count} = await checkNewRequest();
            console.log(count)
            if (count) {
                setHasRequest(true);
            } else {
                setHasRequest(false)
            }
        } catch (e) {
            setHasRequest(false)
        }
    } 
    const handleAddUserClose = (e) => {
        setShowProof(false)
        props.viewUserClose()(e); 
    }

    const handleDecline = async () => {
        try {
            await declineUser(props.editableUser.userId)
            await updateCount()
            setShowSnack(true)
            setSnackMessage('User declined')
            setSnackType("success")
            setStatus('DECLINED')
            props.setUsersChange('declined');
        }
        catch (e) {
            console.log(`Error in declining user`, e)
            setShowSnack(true)
            setSnackMessage('User declining failed')
            setSnackType("error")

        }

    }

    const handleApprove = async () => {
        try {
            await accreditUser(props.editableUser.userId)
            await updateCount()
            setShowSnack(true)
            setStatus('APPROVED')
            setSnackMessage('User approved')
            setSnackType("success")
            props.setUsersChange('approved');
        }
        catch (e) {
            console.log(`Error in approving user`, e)
            setShowSnack(true)
            setSnackMessage('User approving failed')
            setSnackType("error")      
        }

    }

  return (
    <Fragment>
        <TabContent
            showProof={showProof}
            isMobile={isMobile}
            handleApprove={handleApprove}
            handleDecline={handleDecline}
            setShowProof={setShowProof}
            handleAddUserClose={handleAddUserClose}
            viewUserOpen={props.viewUserOpen}
            user={user}
            status={status}
        /> 
        <SnackBar message={snackMessage} open={showSnack} severity={snackType} handleClose={()=>{setShowSnack(false)}}/>
    </Fragment>
  );
}
