import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import {BookFilter} from './bookFilter.js';

const classes = {
    addCont :{
      width: '95%',
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: '20px',
    },
    filterCont: {
        display: 'flex',
        width: '60%',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    filtBookCont: {
        display: 'flex',
        alignItems:'center',
        width: '40%',
        justifyContent: 'flex-end'
    },
    filtBookContMobile: {
        display: 'flex',
        alignItems:'center',
        width: '100%',
        justifyContent: 'flex-end'
    }
}
const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
const getBp = (width) => {
    if (width < 600) {
        return 'xs'
    }
    if (width < 900) {
        return 'sm'
    }
    if (width < 1200) {
        return 'md'
    }
    if (width < 1500) {
        return 'lg'
    }
    return 'xl'
}
const TopBar = ({includeCancel, toggleIncludeCancel, setShowAddBooking, selectedStatuses, setSelectedStatuses}) => {
    const windowDimensions = getWindowDimensions();
    const dim = getBp(windowDimensions.width); 
    return (
        <div style={classes.addCont}>
            <div style={dim === 'xs' ? classes.filtBookContMobile : classes.filtBookCont}>
                <BookFilter selectedStatuses={selectedStatuses} setSelectedStatuses={setSelectedStatuses}/>
            </div>
            <div style={classes.filterCont}>
                {/*<FormControlLabel control={<Switch checked={includeCancel}/>} onChange={toggleIncludeCancel} label="Include Cancelled" />*/}
                <Button variant="contained" onClick={()=>{setShowAddBooking(true)}}>Book</Button>
            </div>
            
        </div>
    )
}

export default TopBar;