
import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import configureStore from './store';
import App from './App';
import './index.css';
const store = configureStore();
document.body.style.margin=0;
document.body.style.overflow='hidden';
const container = document.getElementById('root')
const root = createRoot(container);
root.render(
  <>
    <Provider store={store}>
      <App />
    </Provider>
  </>,
  
);
