import React, { useEffect, useState } from 'react';
import {SnackBar} from  '../components/general.js';
import {Paper, Grid, Button, Avatar, Typography, TextField, InputAdornment, IconButton, CssBaseline} from '@mui/material';

import { useSearchParams } from "react-router-dom";
import {userexists, doSetPassword, requestCode} from '../services/auth.js'

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const classes = {
  root: {
    height: "100vh",
  },
  image: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: '8 4',
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  title: {
      fontSize: '16px',
      margin: '30px 0px'
  }
};


const ResetPassword = ({email, setPassword, password, code, setCode, submitRequest, submitCodeRequest, codeRequested}) => {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    return (
        <div>
             <>
            <div>
                {!codeRequested && (

                <div>
                    <div style={classes.title}>Please enter your email and request for a code </div>
                    <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    onChange={(e) => {this.usernameChange(e)}}
                    fullWidth
                    id="email"
                    value={email}
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    />
                    <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={submitCodeRequest}
                    sx={classes.submit}
                    >
                    Request Code 
                    </Button>
                </div>
                )}
                {codeRequested && (
                <div>
                     <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    value={email}
                    label="Email Address"
                    name="email"
                    disabled={true}
                    autoComplete="email"
                    autoFocus
                    />
                    <TextField
                        label='Password'
                        variant="outlined"
                        value={password}
                        fullWidth
                        type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                        onChange={(e)=>{setPassword(e.target.value)}}
                        InputProps={{ // <-- This is where the toggle button is added.
                            endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                >
                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                            )
                        }}
                    />
                    <TextField
                    variant="outlined"
                    value={code}
                    margin="normal"
                    required
                    onChange={(e) => {setCode(e.target.value)}}
                    fullWidth
                    id="code"
                    label="Verification Code"
                    name="code"
                    />
                    <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={submitRequest}
                    sx={classes.submit}
                    >
                    Set 
                    </Button>
                </div>
                )}
            </div>
            </>
        </div>
    )
}
const SetFirstPassword = ({email, setPassword, password, code, setCode, submitRequest}) => {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    return (
            <>
            <div style={classes.title}>Hey <b>{email} </b>, Please set you password and code from the email to get started </div>
            <div>
                <TextField
                    label='Password'
                    variant="outlined"
                    value={password}
                    fullWidth
                    type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                    onChange={(e)=>{setPassword(e.target.value)}}
                    InputProps={{ // <-- This is where the toggle button is added.
                        endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            >
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                        </InputAdornment>
                        )
                    }}
                />
                <TextField
                  variant="outlined"
                  value={code}
                  margin="normal"
                  required
                  onChange={(e) => {setCode(e.target.value)}}
                  fullWidth
                  id="code"
                  label="Verification Code"
                  name="code"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={submitRequest}
                  sx={classes.submit}
                >
                  Set 
                </Button>
            </div>
            </>
        
    )
}
const LoadingMessage = () => {
    return (
        <div>
            Loading ... 
        </div>
    )
} 
const ErrorMesssage = ({error}) => {
    console.log(error)
    return (<div>
        Could not find user
    </div>)
}
const SetPassword = () => {
    const [searchParams, setSearchParams] = useSearchParams();    
    const email = searchParams.get("email");
    const [userEmail, setUserEmail] = useState(email)
    const [password, setPassword] = useState('');
    const [code, setCode] = useState('');
    const [userDetails, setUserDetails] = useState();
    const [loading, setLoading] = useState(false)
    const [initLoad, setInitLoad] = useState(false);
    const [err, setErr] = useState(false);
    const [showSnack, setShowSnack] = useState(false)
    const [snackType, setSnackType] = useState('success')
    const [snackMessage, setSnackMessage] = useState('')
    const [codeRequested, setCodeRequested] = useState(false)
    console.log('setpassword')
    useEffect(()=>{
        const getData = async (_email) => {
            setInitLoad(true)
            try {
                const _userDetails =  await userexists(_email)
                setUserDetails(_userDetails)
                setInitLoad(false)
            } catch (e) {
                setErr(e);
                setInitLoad(false)
            }
        }
        if (userEmail) {
            getData(userEmail)
        }
    },[])
    const handleSnackClose = () => {
        setShowSnack(false);
    }
    const submitRequest = async () => {
        try {
            await doSetPassword(userEmail, password, code);
            setSnackType('success')
            setSnackMessage('Password set successfully, you will be redirected to login')
            setShowSnack(true);
            window.setTimeout(()=>{window.location.href='/login'}, 2000)
        } catch (e) {
            let message = 'Could not update password'
            if (e.status === 403) {
                message = 'Your verification code is expired, contact admin'
            }
            if (e.status === 401) {
                message = 'Your verification code does not match'
            }
            if (e.status === 404) {
                message = 'User is not found'
            }
            setSnackType('error')
            setSnackMessage(message)
            setShowSnack(true);
        }
    }
    const submitCodeRequest = async () => {
        try {
            await requestCode(userEmail);
            setSnackType('success')
            setSnackMessage('Verification code has been sent to you email')
            setShowSnack(true);
            setCodeRequested(true)
        } catch (e) {
            let message = 'Request for code failed'
            if (e.status === 404) {
                message = 'Request for code failed. User is not found'
            }
            setSnackType('error')
            setSnackMessage(message)
            setShowSnack(true);
        }
    }
    let comp; 

    if (email && userDetails?.isNew) {
        comp = <SetFirstPassword email={userEmail} setUserEmail={setUserEmail} password={password} submitRequest={submitRequest} setPassword={setPassword} code={code} setCode={setCode}/>
    }
    if ((email && !userDetails?.isNew) || !email) {
        comp = <ResetPassword codeRequested={codeRequested} submitCodeRequest={submitCodeRequest} email={userEmail} setUserEmail={setUserEmail} password={password} submitRequest={submitRequest} setPassword={setPassword} code={code} setCode={setCode}/>
    }
    if (initLoad) {
        comp = <LoadingMessage/>    
    }
    if (err) {
        comp = <ErrorMesssage error={err}/>
    }
    return (
        <Grid container component="main" sx={classes.root}>
          <CssBaseline />
          <Grid item xs={false} sm={4} md={7} sx={classes.image} />
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <div style={classes.paper}>
                <Avatar sx={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Set Password
                </Typography>
                {comp}
            </div>
          </Grid>
          <SnackBar message={snackMessage} open={showSnack} severity={snackType} handleClose={handleSnackClose}/>
        </Grid>
    )
};




export default SetPassword;
