import React, { Component } from 'react';
import { connect} from 'react-redux';
import { getStore, setStore, removeItem } from '../util';
import Sidebar from '../components/sidebar';
import Appbar from '../components/appbar';
import {addItemAction, deleteItemAction, editItemAction, resetItemAction} from '../actions/item.js';
import {Grid, Button} from '@mui/material';
import {SnackBar, ConfirmDialog} from  '../components/general.js';
import SelectProducts from '../components/selectProducts.js'
import ItemList from '../components/itemList.js'
import AddItem from '../components/addItem.js'

const classes = {
  root: {
    flexDirection: "column",
  },
  image: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop:1
  },
  submit: {
    margin: '3, 0, 2'
  },
  container : {
    display: "flex",
    flexDirection: "row",
    width: '100%',
  },
  subcontainer:{
    width: '100%',
    margin:'10px 10px 0px 10px'
  },
  addCont :{
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  }
};


class Item extends Component { 
  constructor(props) {
    super(props);
    this.state= {
      addItemOpen: false,
      showSnack: false,
      snackMessage:'Product Added',
      editableItem: undefined,
      selectedProduct:undefined,
      selectedProductName:undefined,
      showConfirm:false,
      delItem: undefined,
    }
  }
    componentDidMount() {
        
    }
    componentDidUpdate(prevProps) {
      console.log('>>', this.props.item)
      if (this.props.item?.addedItem && prevProps?.item?.addedItem?._id !== this.props.item?.addedItem._id) {
        this.setState({addItemOpen: false, showSnack: true, snackMessage: 'Item Added'})
      }
      if (this.props.item?.editedItem && prevProps?.item?.edited?._id !== this.props.item?.editedItem._id) {
        this.setState({addItemOpen: false, showSnack: true, editableItem: undefined, snackMessage: 'Item Updated'})
        this.props.dispatch(resetItemAction())
      }
      if (this.props.item?.error && prevProps?.item?.error !==  this.props.item?.error) {
        let message = 'Could not complete action'
        if (this.props.item?.error.status === 409) {
          console.log(this.props.item?.error)
          message = this.props.item?.error.message;
        }
        this.setState({ showSnack: true, snackMessage: message})
        //this.props.dispatch(resetItemAction())
      }
    }
    addItem = (data, file)=> {
        this.props.dispatch(addItemAction(data))
    }

    editItem = (data, id) => {
        this.props.dispatch(editItemAction(id, data))
    }
    deleteItem = id => {
      return () => {
        this.setState({delItem:id, showConfirm:true})
        //this.props.dispatch(deleteItemAction(id))
      }
    }
    addItemClose = () => {
      return ()=>{this.setState({addItemOpen:false, editableItem:undefined})}
    }
    handleSnackClose=(e)=> {
      this.setState({showSnack:false})
    }
    handleConfirmClose=(e)=> {
      this.setState({showConfirm:false, delItem: undefined})
    }
    setEdit = (item) => {
      this.setState({editableItem:item, addItemOpen:true})
    }
    selectProduct = (id, name) =>{
        return()=> {
            this.setState({selectedProduct:id, selectedProductName: name})
        }
    }
    render = ()=> {
      const { item:{error, load, addedItem, deletedItem, editedItem}={} } = this.props;
      if (error && error.status===401) {
          removeItem('user');
      }
      return (
        <Grid container component="main" sx={classes.root}>
            <Appbar/>
            <div style={classes.container}>
                <Sidebar/>
                
                <div style={classes.subcontainer}>
                  <div style={classes.addCont}>
                      <SelectProducts selectProduct={this.selectProduct} selectedProductName={this.state.selectedProductName}/>
                    <Button variant="contained" disabled={!this.state.selectedProduct} onClick={()=>{this.setState({addItemOpen:true})}}>Add Item</Button>
                  </div>
                    <ItemList addedItem={addedItem} deletedItem={deletedItem} editedItem={editedItem} selectedProduct={this.state.selectedProduct} handleDeleteClick={this.deleteItem} setEdit={this.setEdit}/>
                </div>
            </div>
            <SnackBar message={this.state.snackMessage} open={this.state.showSnack} handleClose={this.handleSnackClose}/>
            <AddItem editableItem={this.state.editableItem} selectedProduct={this.state.selectedProduct} editItem={this.editItem} addItemClose={this.addItemClose} showSnack={this.state.showSnack} addItemOpen={this.state.addItemOpen} addItem={this.addItem}/>
            <ConfirmDialog title={'Are you sure ?'} message={'This action cannot be undone'} confirmAction={()=>{ this.props.dispatch(deleteItemAction(this.state.delItem)); this.handleConfirmClose()}} show={this.state.showConfirm} handleClose={this.handleConfirmClose}/>
        </Grid>
      );
    };
};


const mapStateToProps = (state) => {
  return {
    profile: state.user.profile,
    item: state.item,
  }
}

export default connect(mapStateToProps)(Item);
