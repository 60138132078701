
import React, {useState, useContext} from 'react';
import { useNavigate} from "react-router-dom";
import {List, ListItem, ListItemIcon, ListItemText, Divider, Badge} from '@mui/material';

import DashboardIcon from '@mui/icons-material/Dashboard';
import CategorySharpIcon from '@mui/icons-material/CategorySharp';
import ListSharpIcon from '@mui/icons-material/ListSharp';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthSharpIcon from '@mui/icons-material/CalendarMonthSharp';
import RequestContext from '../context/requestContext';
const styleObj = {
  root: {
    backgroundColor: 'background.paper',
  },
  listIcon:{
    minWidth:'20px'
  },
  listIconFull: {
    minWidth:'50px'
  }
};
const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}
const ModifiedListItemText = ({text, windowDimensions}) => {
 
  if (windowDimensions.width < 500) {
    return null
  }
  return <ListItemText primary={text} />
}

const SimpleList = (props) => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const isMobile = windowDimensions.width < 500 ? true : false
  const navigate = useNavigate();
  const goToProducts = () => (e)=> {navigate('/products')}
  const goToItems = () => (e)=> {navigate('/items')}
  const goToHome = () => (e)=> {navigate('/')}
  const goToUsers = () => (e)=> {navigate('/users')}
  const goToCalendar = () => (e)=> {navigate('/calendar')}
  const { hasRequest } = useContext(RequestContext);

  return (
    <div style={{backgroundColor: 'background.paper'}} >
      <List component="nav" aria-label="main mailbox folders">
        <ListItem button onClick={goToHome()}>
          <ListItemIcon sx={isMobile ? styleObj.listIcon: styleObj.listIconFull}>
            <DashboardIcon />
          </ListItemIcon>
          <ModifiedListItemText text="Bookings" windowDimensions={windowDimensions} />
        </ListItem>
        <Divider />
        <ListItem button onClick={goToProducts(props.history)}>
          <ListItemIcon sx={isMobile ? styleObj.listIcon: styleObj.listIconFull}>
            <CategorySharpIcon />
          </ListItemIcon>
          <ModifiedListItemText text="Products" windowDimensions={windowDimensions}/>
        </ListItem>
        <Divider />
        <ListItem button onClick={goToItems(props.history)}>
          <ListItemIcon sx={isMobile ? styleObj.listIcon: styleObj.listIconFull}>
            <ListSharpIcon />
          </ListItemIcon>
          <ModifiedListItemText text="Items" windowDimensions={windowDimensions}/>
        </ListItem>
        <Divider />
        <ListItem button onClick={goToUsers(props.history)}>
          <ListItemIcon sx={isMobile ? styleObj.listIcon: styleObj.listIconFull}>
            <Badge color="secondary" variant="dot" invisible={!hasRequest}>
                <PersonIcon />
            </Badge>
          </ListItemIcon>
          <ModifiedListItemText text="Users" windowDimensions={windowDimensions}/>
        </ListItem>
        <Divider />
        <ListItem button onClick={goToCalendar(props.history)}>
          <ListItemIcon sx={isMobile ? styleObj.listIcon: styleObj.listIconFull}>
            <CalendarMonthSharpIcon />
          </ListItemIcon>
          <ModifiedListItemText text="Calendar" windowDimensions={windowDimensions}/>
        </ListItem>
        <Divider />
        {/*<ListItem button onClick={goToItems(props.history)}>
          <ListItemIcon className={isMobile ? classes.listIcon: classes.listIconFull}>
            <AssessmentSharpIcon />
          </ListItemIcon>
          <ModifiedListItemText text="Reports" windowDimensions={windowDimensions}/>
  </ListItem>*/}
       
      </List>
    </div>
  );
}

export default SimpleList;
