/**
 * Set localStorage
 */
 export const setStore = (name, content) => {
    if (!name) return
    if (typeof content !== 'string') {
      content = JSON.stringify(content)
    }
    return window.localStorage.setItem(name, content)
  }
  
  /**
    * Get localStorage
  */
  export const getStore = (name) => {
    if (!name) return undefined;
    return JSON.parse(window.localStorage.getItem(name))
  }
  
  /**
   * Clear localStorage
  */
  export const removeItem = (name) => {
    if (!name) return
    return window.localStorage.removeItem(name)
  }
  
  /**
   * Validate Email address
   */
  export const isValidEmail = (value) => {
    return !(value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,64}$/i.test(value))
  }
  
  /**
   * Format Phone Number
   */
  export const formatPhoneNumber = (value) => {
    if (!value) return
    const currentValue = value.replace(/[^\d]/g, '');
    const mobileNoLength = currentValue.length;
    if (mobileNoLength >=7) {
      if (mobileNoLength < 4) return currentValue;
      if (mobileNoLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    } else{
      return currentValue;
    }
  }
  
  
  export const checkStatusError = (res) => {
      if (!res.ok) {
          throw new Error(res.statusText);
      }
      return res;
  }

  export const getTicketId = (id) => {
    if (id) {
      return id.slice(-6)
    }
    return ''
  }

  export const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
