import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {getProductName} from '../services/products.js';

export default function BasicMenu({selectProduct, selectedProductName}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [products, setProducts] = React.useState([]);
  React.useEffect(()=>{
    const getData = async () => {
        let _products = await getProductName();
        _products.unshift({_id:0, name: 'Select Product'})
        setProducts(_products);
        selectProduct(_products[1]._id, _products[1].name)();
    }
    getData();
  }, [])
  const open = Boolean(anchorEl);  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleProductClick = (product) => {
    return (e) => {
      handleClose()
      console.log(product)
      selectProduct(product._id, product.name)()
    }
  }
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {selectedProductName}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {products.map(product=>(<MenuItem key={product._id} onClick={handleProductClick(product)}>{product.name}</MenuItem>))}
      </Menu>
    </div>
  );
}
