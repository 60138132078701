import React from 'react';
import { Container, Button, Menu, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography, TextField, MenuItem, FormControlLabel, Switch} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';



const classes  = {
  body:{
    width:'100%',
    display:'flex',
    flexDirection: 'row'
  },
  left: {
    width:{xs:'100%', sm: '100%', md:'70%',  lg:'70%', xl: '70%'}
  },
  right: {
    width: '30%'
  },
  rowcont :{
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  innerright: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column'
  },
  textEl: {
    marginBottom:'10px'
  }
};

export default function CustomizedDialogs(props) {
    const [sku, setSku] = React.useState('');
    const [purchasePrice, setPurchasePrice] = React.useState(0);
    const [isAvailable, setIsAvailable] = React.useState(true);    
    const saveTitle = props.editableItem ? 'Update Changes' : 'Save Item';
    const saveFunc = props.editableItem ? props.editItem : props.addItem;
    React.useEffect(()=>{
      if (props.editableItem) {            
            setFields(props.editableItem);
          }    
    }, [props.editableItem])
    React.useEffect(()=>{
        console.log(props.editableItem, props.addItemOpen)
      if (!props.editableItem && props.addItemOpen) {
        resetFields();
      }
    }, [props.addItemOpen, props.editableItem])
    const resetFields = () => {
        setSku('')
        setIsAvailable(true)
        setPurchasePrice(0)
    }
    const setFields = (data) => {
      setSku(data.sku)
      setIsAvailable(data.isAvailable)
      setPurchasePrice(data.purchasePrice)
  }
    const handleAddProductClose = (e) => {
        props.addItemClose()(e);
        resetFields()
    }
    const handleClick =()=> { 
        return (e) => {
            if (sku) {
                let data = {};
                if (props.editableItem) {
                  if (props.editableItem.sku !== sku) {
                    data.sku = sku; 
                  }
                  if (props.editableItem.purchasePrice !== purchasePrice) {
                    data.purchasePrice = purchasePrice; 
                  }
                  if (props.editableItem.isAvailable !== isAvailable) {
                    data.isAvailable = isAvailable; 
                  }
                } else {
                  data.sku = sku;
                  data.productId = props.selectedProduct;
                  data.purchasePrice = purchasePrice;
                  data.isAvailable = isAvailable;
                }
                saveFunc(data, props?.editableItem?._id)
                //resetFields();
            } else {
                console.log('Mandatory details required');
            }
        }
    }
  return (
    <div>
      <Dialog onClose={handleAddProductClose} fullWidth={true}
        maxWidth={"md"} aria-labelledby="customized-dialog-title" open={props.addItemOpen}>
        <DialogTitle id="customized-dialog-title" onClose={handleAddProductClose}>
          Add Product
        </DialogTitle>
        <DialogContent dividers>
            <Container sx={classes.body}>
                <Container maxWidth={"md"} sx={classes.left}>
                    <TextField sx={classes.textEl} variant="standard" value={sku} fullWidth={true} id="standard-basic" label="SKU" onChange={(e)=>{setSku(e.target.value)}}/>
                    <TextField  sx={classes.textEl} variant="standard" value={purchasePrice} fullWidth={true} id="item-price" label="Purchase Price" onChange={(e)=>{setPurchasePrice(e.target.value)}}/>
                    <div style={classes.rowcont}>
                        <div style={classes.innerright}>
                              <FormControlLabel
                                style={{marginTop:'25px'}}
                                  control={
                                  <Switch
                                    checked={isAvailable}
                                    onChange={(e)=>{setIsAvailable(e.target.checked)}}
                                    name="checkedB"
                                    color="primary"
                                  />
                                  }
                                  label="Available"
                              />                  
                        </div>                  
                  </div>
                </Container>
            </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddProductClose} color="secondary">
            Cancel
          </Button>
          <Button autoFocus onClick={handleClick()} color="primary">
            {saveTitle}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
