import { Types } from '../constants/actionTypes';
import {login} from "../services/auth.js";
import {setStore} from "../util";

const loginAction = (username, password) => (dispatch) => {
    dispatch({type: Types.AUTH_LOAD});
    return login(username, password).then(
        (data) => {
            console.log(data)
            setStore('user', data.payload);
            dispatch({
            type: Types.LOGIN_SUCCESS,
            payload: { user: data.payload },
        });
    },
    (error) => {
        console.log('>>', error)
        dispatch({
            type: Types.LOGIN_FAIL,
            payload:error
        });
        }
    );
};
const removeUserAction = () => dispatch => {
    dispatch({type: Types.LOGOUT_UNAUTH})
}

const addUserAction = () => dispatch => {
    dispatch({type: Types.ADD_USER})
}

export {loginAction, removeUserAction}