import { Types } from '../constants/actionTypes';
const initialState = {
  products: [],
  product:undefined,
  error:undefined,
  load:false,
  editedProduct:undefined,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload.products,
        error: false
      }
    case Types.GET_ALL_PRODUCTS_FAIL:
      return {
        ...state,
        error: action.payload.error
      }
    case Types.GET_ALL_PRODUCTS_LOAD:
      return {
        ...state,
        load:true
      }
    case Types.ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        load:false,
        products:[...state.products, action.payload.product],
        product: action.payload.product
      }
      case Types.ADD_PRODUCT_LOAD:
      return {
        ...state,
        load:true
      }
    case Types.ADD_PRODUCT_FAIL:
      console.log(action.payload)
      return {
        ...state,
        load:false,
        error: action.payload
      }
      case Types.EDIT_PRODUCT_SUCCESS:
        {
          const _products = [...state.products]
          const productIndex = _products.findIndex(x=>x._id===action.payload.id);
          let product;
          if (productIndex>-1) {
            _products[productIndex] = action.payload.product;
          }
          return {
              ...state,
              load:false,
              products:_products,
              editedProduct: action.payload.product
          }  
      }
        case Types.EDIT_PRODUCT_LOAD:
        return {
          ...state,
          load:true
        }
      case Types.EDIT_PRODUCT_FAIL:
        return {
          ...state,
          load:false,
          error: action.payload.error
        }
    case Types.DEL_PRODUCT_SUCCESS:
  {
      let _products = [...state.products]
      const vIndex = _products.findIndex(x=>x._id===action.payload.product)
      _products.splice(vIndex, 1);
      return {
        ...state,
        load:false,
        products:_products,
      }
    }
    case Types.DEL_PRODUCT_FAIL:
      return {
        ...state,
        laod:false,
        error: action.payload.error
      }
    case Types.DEL_PRODUCT_LOAD: 
        return {
        ...state,
        load:true,
      }
    /*case Types.EDIT_MENU_LOAD:
      return {
        ...state,
        load:true,
      }
    case Types.EDIT_MENU:
    {
        const _menus = [...state.menus]
        const menuIndex = _menus.findIndex(x=>x._id===action.payload.id);
        let menu;
        if (menuIndex>-1) {
            _menus[menuIndex] = action.payload.menu;
        }
        return {
            ...state,
            load:false,
            menus:_menus,
            editedMenu: action.payload.menu
        }  
    }
    case Types.EDIT_MENU_FAIL:
        return {
            ...state,
            load:false,
            error: action.payload.error
        }*/
    default:
      return state;
  }
}

export default reducer;