import { Types } from '../constants/actionTypes';
import {getAllProducts, addProduct, addProductWithTemplate, delProduct, editProduct} from "../services/products.js";

const getAllProductsAction = () => (dispatch) => {
    dispatch({type: Types.GET_ALL_PRODUCTS_LOAD});
    return getAllProducts().then(
        (data) => {
            dispatch({
            type: Types.GET_ALL_PRODUCTS_SUCCESS,
            payload: { products: data },
        });
        return Promise.resolve();
    },
    (error) => {
        console.log('>>', error.status)
        if (error.status===401) {
            dispatch({type: Types.LOGOUT_UNAUTH})
        }
        dispatch({
            type: Types.GET_ALL_PRODUCTS_FAIL,
            payload:error
        });
        return Promise.reject();
        }
    );
};

const addProductAction = (data, file) => dispatch => {
    dispatch({type: Types.ADD_PRODUCT_LOAD});
    return addProduct(data, file).then(
        (data) => {
            dispatch({
            type: Types.ADD_PRODUCT_SUCCESS,
            payload: { product: data },
        });
    },
    (error) => {
        console.log('>>', error.status)
        if (error.status===401) {
            dispatch({type: Types.LOGOUT_UNAUTH})
        }
        dispatch({
            type: Types.ADD_PRODUCT_FAIL,
            payload:error
        });
        }
    );
}

const addProductWithTemplateAction = (data, file) => dispatch => {
    dispatch({type: Types.ADD_PRODUCT_LOAD});
    return addProductWithTemplate(data, file).then(
        (data) => {
            dispatch({
            type: Types.ADD_PRODUCT_SUCCESS,
            payload: { product: data },
        });
    },
    (error) => {
        console.log('>>', error.status)
        if (error.status===401) {
            dispatch({type: Types.LOGOUT_UNAUTH})
        }
        dispatch({
            type: Types.ADD_PRODUCT_FAIL,
            payload:error
        });
        }
    );
}


const editProductAction = (id, data, file) => dispatch => {
    dispatch({type: Types.EDIT_PRODUCT_LOAD});
    return editProduct(id, data, file).then(
        (data) => {
            dispatch({
            type: Types.EDIT_PRODUCT_SUCCESS,
            payload: { id: id, product: data },
        });
        return Promise.resolve();
    },
    (error) => {
        console.log('>>', error.status)
        if (error.status===401) {
            dispatch({type: Types.LOGOUT_UNAUTH})
        }
        dispatch({
            type: Types.EDIT_PRODUCT_FAIL,
            payload:error
        });
        return Promise.reject();
        }
    );
}

const deleteProductAction = (productId) => dispatch => {
    dispatch({type: Types.DEL_PRODUCT_LOAD});
    return delProduct(productId).then(data=>{
        dispatch({
            type: Types.DEL_PRODUCT_SUCCESS,
            payload: { product: productId }
        })
    }, error=>{
        console.log('>>', error.status)
        if (error.status===401) {
            dispatch({type: Types.LOGOUT_UNAUTH})
        }
        dispatch({
            type: Types.DEL_PRODUCT_FAIL,
            payload:error
        });
        return Promise.reject();
    })
}

export {getAllProductsAction, addProductAction, deleteProductAction, editProductAction, addProductWithTemplateAction}
