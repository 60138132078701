import React, {useState, useEffect} from 'react';

import moment from 'moment';

import {removeUserAction} from '../actions/profile.js';
import {SnackBar, DialogWindow, CustomAvatar} from  './general';
import {getAllBookings} from '../services/booking.js'
import {getProductOccupancy} from '../services/products.js'
import WeeklyBookPopup from './weeklyBookPopup.js';

import {IconButton, Card, Paper, Badge, Tooltip} from '@mui/material';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


import EventIcon from '@mui/icons-material/Event';
import DoneIcon from '@mui/icons-material/Done';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

import { pink } from '@mui/material/colors';
import { green } from '@mui/material/colors';

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import clsx from  'clsx';
import ClearIcon from '@mui/icons-material/Clear';




const classes = {
  root: {
    width: '100%',
    position:'relative',
    minHeight: 'calc(100vh - 200px)',
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'scroll',
    paddingTop:'20px'
  },
  container: {
    maxHeight: 440,
  },
  pagination : {
    position: 'absolute',
    bottom:0,
    right:0
  },
  paneHead: {
    marginBottom:20
  },
  indCard: {
    display: 'flex',
    flexDirection:'column',
    height: '60px',
    marginBottom: '10px'
  },
  indCardHead: {
    display: 'flex',
    alignItems:'center',
    padding: '10px 0px',
    position: 'relative',

  },
  cardItem : {
    margin: '0px 5px 0px 0px',
    maxWidth: '80%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: '13px'  
  },
  topbar: {
    display:'flex',
    justifyContent:'space-between',
    alignItems: 'center',
    marginBottom: '20px'
  },
  indProductCont: {
    display: 'flex',
    alignItems: 'center'
  },
  indProductItem :{
    margin: '0px 10px'
  },
  chip: {
    textTransform: 'capitalize',
    fontWeight: 'bold'
  },
  topInd: {
    marginRight: '10px',
    display: 'flex',
    alignItems:'center'
  },
  lbl: {
    marginRight: '5px'
  },
  advanceCont: {
    color: green[500]
  },
  balanceCont: {
    color: pink[500]
  },
  paneHead: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position:'relative',
    width: `calc(100% / 7)`
  },
  prev: {
    position:'absolute',
    cursor: 'pointer',
    left: 0,
    zIndex:5
  },
  mobPrev: {
    position:'absolute',
    cursor: 'pointer',
    left: 0,
    marginTop: '-35px',
    zIndex:5
  },
  next:{
    position:'absolute',
    cursor: 'pointer',
    right: 0,
    zIndex:5
  },
  mobNext:{
    position:'absolute',
    cursor: 'pointer',
    right: 0,
    marginTop: '-35px',
    zIndex:5
  },
  fullWidthHead: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  fullWidthBody: {
    display:'flex',
    justifyContent: 'space-around'
  },
  expandCont: {
    position: 'absolute',
    right: '0px'
  },
  checkProd: {
    marginLeft: 15
  },
  prodHeadCont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    position: 'relative',
    height: '44px',
    width:'100%',
  },
  prodHead: {
    width: '250px'
  },
  indCardProdHead: {
    display: 'flex',
    alignItems:'center',
    padding: '10px 0px',
    position: 'relative',
    height: '25px'
  },
  indCardProdBody: {
    display: 'flex',
    alignItems:'center',
    position: 'relative',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  bar: {
    width: '100%',
    display:'flex',
    height: '5px',
  },
  booked: {
    backgroundColor: 'red'
  },
  available: {
    backgroundColor: 'green'
  },
  nonBooked: {
    background: 'yellow'
  },
  txtBooked: {
    color: 'red'
  },
  txtAvailable :{
    color: 'green'
  },
  textCont :{
    marginBottom: '5px'
  }
};
const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const IndProduct = ({product, index}) => {
  return (
    <Badge badgeContent={product.count} color="primary">
      <div style={classes.indProductCont}>
        <div style={classes.indProductItem}># {index+1}</div>
        <div style={classes.indProductItem}><img height={50} src={product?.image?.thumb??''}/></div>
        <div style={classes.indProductItem}>{product.name}</div>      
      </div>
    </Badge>
  )
}


const IndCard = ({isMobile, book, setShowDetailPopup, setSelectedBooking}) => {
    const [expanded, setExpanded] = useState(false);

    const getChipStyle = (status) => {
      if (status==='booked') {
        return {icon: <EventIcon sx={{ color: '#54A0FF' }}/>}
      }
      if (status === 'checkedout') {
        return {icon: <LogoutIcon sx={{ color: '#F39C12' }}/>}
      }
      if (status === 'checkedin') {
        return {icon: <LoginIcon sx={{ color: '#E74C3C' }}/>}
      }
      if (status === 'closed') {
        return {icon: <DoneIcon sx={{ color: '#30CB83' }}/>}
      }
      if (status === 'cancelled') {
        return {icon: <CancelPresentationIcon sx={{ color: '#34495E' }}/>}
      }
    }
    const getMobileStyle = (status) =>{
      let style = {width: 30, height: 30, margin: 0}
      if (status==='booked') {
        style.border = '2px solid #54A0FF';
        style.filter = `drop-shadow(0 0 0.5rem #54A0FF)`;
        return style
      }
      if (status === 'checkedout') {
        style.border = '2px solid #F39C12';
        style.filter = `drop-shadow(0 0 0.5rem #F39C12)`;
        return style
      }
      if (status === 'checkedin') {
        style.border = '2px solid #E74C3C';
        style.filter = `drop-shadow(0 0 0.5rem #E74C3C)`;
        return style
      }
      if (status === 'closed') {
        style.border = '2px solid #30CB83';
        style.filter = `drop-shadow(0 0 0.5rem #30CB83)`;
        return style
      }
      if (status === 'cancelled') {
        style.border = '2px solid #34495E';
        style.filter = `drop-shadow(0 0 0.5rem #34495E)`;
        return style
      }
    }
    const IconDetail = getChipStyle(book.status)
    if (isMobile) {
      return (
            <div onClick={()=>{setShowDetailPopup(true); setSelectedBooking(book._id)}} style={{...classes.indCardHead, cursor: 'pointer', justifyContent: 'center'}}>
              <CustomAvatar  style={{...classes.cardItem, ...getMobileStyle(book.status)}} src={book?.tenant?.image?.thumb??''} name={book.tenant.name}/>
            </div>
      )
    }
    return (
        <Card sx={classes.indCard}>
            <div style={classes.indCardHead}>
              <CustomAvatar style={{margin:'0px 5px'}} sx={classes.cardItem} src={book?.tenant?.image?.thumb??''} name={book.tenant.name}/>
              <Tooltip title={<div>{book.tenant.name}</div>}>
                <div style={classes.cardItem}>{book.tenant.name}</div>
              </Tooltip>
              {IconDetail.icon}
              <div style={classes.expandCont}>
                <IconButton><OpenInFullIcon onClick={()=>{setShowDetailPopup(true); setSelectedBooking(book._id)}}/></IconButton>
              </div>
            </div>
        </Card>
    )
}
const IndProdCard = ({product, date, selectedProduct, isMobile, setSelectedProduct, productId}) => {
  const [expanded, setExpanded] = useState(false);
  const isBefore = moment(date, 'DD-MM-YYYY').isBefore(moment(), 'day')
  const title = isBefore ? 'Non Booked' : 'Available';
  const className = isBefore ? classes.nonBooked : classes.available;
  const availableCount = product.noOfItems-product.bookedCount
  if (isMobile) {
    return (
      <Card sx={classes.indCard} style={{height:'80px', flexDirection:'row', justifyContent: 'flex-end'}}>
          <div style={{...classes.indCardProdBody,justifyContent: 'center'}}>
            <div  style={{...classes.textCont, fontSize: '12px'}}>
              <div style={classes.txtBooked}>
                { product.bookedCount} / {product.noOfItems} {!isMobile && 'Booked'}
              </div>
              {availableCount > 0 && (<div style={classes.txtAvailable}>
                {product.noOfItems-product.bookedCount}
              </div>)}
            </div>
          </div>
          <div style={{...classes.bar, width:'10%', height: '100%', flexDirection: 'column'}}>
              <div style={{...classes.booked, height: `${product.bookedCount/product.noOfItems*100}%`, width:'100%'}}></div>
              <div style={{...className, height: `${(product.noOfItems-product.bookedCount)/product.noOfItems*100}%`, width:'100%'}}></div>
            </div>
      </Card>
  )
  } else {
    return (
        <Card sx={classes.indCard} >
            <div style={classes.indCardProdBody}>
              <div style={classes.textCont} >
                <div style={classes.txtBooked}>
                  { product.bookedCount} / {product.noOfItems} Booked
                </div>
                {availableCount > 0 && (<div style={classes.txtAvailable}>
                  {product.noOfItems-product.bookedCount} {title}
                </div>)}
              </div>
              <div style={classes.bar}>
                <div style={{...classes.booked, width: `${product.bookedCount/product.noOfItems*100}%`}}></div>
                <div style={{...className, width: `${(product.noOfItems-product.bookedCount)/product.noOfItems*100}%`}}></div>
              </div>
            </div>
        </Card>
    )
  }
}
const IndProdHeadCard =  ({product, productId, isMobile}) => {
  return (
      <Card style={isMobile ? {...classes.indCard, height:'80px'} : classes.indCard}>
          <div style={isMobile ? {...classes.indCardProdHead, flexDirection: 'column', height:'auto'} : classes.indCardProdHead}>
            <CustomAvatar style={classes.cardItem} src={product?.image?.thumb??''} name={product.name}/>
            <div style={isMobile ? {...classes.cardItem, fontSize: '2.2vw', maxWidth:'90%', whiteSpace:'break-spaces', textAlign:'center'} : classes.cardItem}>{product.name}</div>
          </div>
      </Card>
  )
}

const DayBody = ({isMobile, bookings, setShowDetailPopup, selectedBooking, setSelectedBooking}) => {
  if (Array.isArray(bookings) && bookings.length > 0) {
    return (
        <div style={{display:'flex', flexDirection:'column', width:'100%', margin: '0px 5px'}}>
          {bookings.map(book=> <IndCard isMobile={isMobile} selectedBooking={selectedBooking} setShowDetailPopup={setShowDetailPopup}  setSelectedBooking={setSelectedBooking} book={book}/>)}
        </div>
    )
  }
  return (<div style={{display:'flex', alignItems:'center', justifyContent: 'center', flexDirection:'column', width:'100%', margin: '0px 5px'}}>{isMobile ? (<ClearIcon style={{color:'#BA0021'}}/>) : 'No Booking Found'}</div>)
}
const ProductDayBody = ({products, date, selectedProduct, setSelectedProduct, isMobile}) => {
  return (
    <div style={{display:'flex', flexDirection:'column', width:'100%', margin: '0px 5px'}}>
      {Object.keys(products).map(productId=> <IndProdCard key={productId} isMobile={isMobile}  date={date} setSelectedProduct={setSelectedProduct}  selectedProduct={selectedProduct} product={products[productId]} productId={productId}/>)}
    </div>
  )
}
const DayHeader = ({date, isMobile, book}) => {
    const getDateLabel = (_date) => {
      if (isMobile) {
        return moment(_date, 'DD-MM-YYYY').format('DD')
      }
        return moment(_date, 'DD-MM-YYYY').format('ddd - MMM Do')
    }
    const getStyle = (_date) => {
      const iscurrentDate = moment(_date, 'DD-MM-YYYY').isSame(new Date(), "day");
      if (iscurrentDate) {
        return {fontWeight: 'bold'}
      }

    }
    let expected = 0;
    let closed = 0;
    let pending = 0;
    if (book) {
    book.forEach(_book => {
        if (_book.status === 'closed') {
          closed += Number(_book.amount);
        } else if (_book.status === 'booked' || _book.status === 'checkedout') {
          closed += Number(_book.advance);
          expected += (Number(_book.amount) - Number(_book.advance))
        } else if (_book.status === 'checkedin') {
          closed += Number(_book.advance);
          pending += (Number(_book.amount) - Number(_book.advance));
        }
      })
    }
    //const expected = book.filter(x=> x.status==='booked' || x.status==='checkedout').reduce((acc, curr)=>{return acc+(curr.amount-curr.advance)},0);
    //const closed = book.filter(x=> x.status==='closed').reduce((acc, curr)=>{return acc+curr.amount},0);
    //const pending = book.filter(x=> x.status==='c').reduce((acc, curr)=>{return acc+curr.amount},0);;
    const dateLabel = getDateLabel(date);
    if (book) {
        return (
          <div style={classes.paneHead}>
              <Tooltip title={<div style={{fontSize:'15px'}}><div style={{textShadow:'1px 1px 0 #1399a7'}}>Closed : ₹{closed}</div><div style={{textShadow:'1px 1px 0 #FA2FB5'}}>Expected : ₹{expected}</div><div style={{textShadow:'1px 1px 0 #FF6700'}}>Pending : ₹{pending}</div></div>}>
                <div style={{...getStyle(date), ...classes.day}}> { dateLabel } </div>
              </Tooltip>
          </div>
      ) 
    } else {
        return (
          <div style={classes.paneHead}>
                <div style={{...getStyle(date), ...classes.day}}> { dateLabel } </div>
          </div>
      ) 
    }
    
}
const SingleWeekPane = ({bookings=[], setSelectedBooking, selectedBooking, theWeek, moveNext, movePrev, setShowDetailPopup, isMobile}) => {
  let days = []
  days = Array(7).fill('').map((el,index)=>moment(moment().day("Saturday").week(theWeek), 'DD-MM-YYYY').subtract(index, 'days').format('DD-MM-YYYY')).reverse()
  const fitlerdBooking = days.map(day=>bookings.filter(book=>{
    return moment(book.from).format('DD-MM-YYYY') === day  
  }))
  console.log(days[0])
    return (
        <div>

          <div style={classes.fullWidthHead}>
            <div style={isMobile ? classes.mobPrev : classes.prev} ><ChevronLeftIcon onClick={movePrev} sx={{ fontSize: 40 }} /></div>
            {isMobile && (<div style={{position:'absolute', marginTop: '-35px'}}>{moment(days[0], 'DD-MM-YYYY').format('MMMM')}</div>)}
            {days.map((day, index)=> <DayHeader isMobile={isMobile} key={`head-${index}`} date={day} book={fitlerdBooking[index]}/>)}
            <div style={isMobile ? classes.mobNext:classes.next}><ChevronRightIcon onClick={moveNext} sx={{ fontSize: 40 }} /></div>
          </div>
          <div style={classes.fullWidthBody}>
            {fitlerdBooking.map(book=> <DayBody isMobile={isMobile} setShowDetailPopup={setShowDetailPopup} selectedBooking={selectedBooking} setSelectedBooking={setSelectedBooking} key={book._id}  bookings={book}/>)}
          </div>
        </div>
    )

}
const SingleWeekProductPane = ({ products, isMobile, selectedProduct, setSelectedProduct, theWeek, moveNext, movePrev}) => {
  let days = []
  days = Array(7).fill('').map((el,index)=>moment(moment().day("Saturday").week(theWeek), 'DD-MM-YYYY').subtract(index, 'days').format('DD-MM-YYYY')).reverse()
  if (isMobile) {
    return (
        <div style={{display: 'flex'}}>
          <div style={isMobile ? {...classes.prodHead, width: '50px'} : classes.prodHead}>
            <div style={{width: '50px', height:'44px'}}> </div>
            {products[days[0]] && Object.keys(products[days[0]]).map(productId => <IndProdHeadCard  isMobile={isMobile} key={productId} product={products[days[0]][productId]} productId={productId}/>)}
          </div>
          <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <div style={classes.prodHeadCont}>
              <div style={isMobile ? classes.mobPrev : classes.prev} ><ChevronLeftIcon onClick={movePrev} sx={{ fontSize: 40 }} /></div>
              {isMobile && (<div style={{position:'absolute', marginTop: '-35px'}}>{moment(days[0], 'DD-MM-YYYY').format('MMMM')}</div>)}
              {days.map((day, index)=> <DayHeader isMobile={isMobile} key={`head-${index}`} date={day}/>)}
              <div style={isMobile ? classes.mobNext:classes.next}><ChevronRightIcon onClick={moveNext} sx={{ fontSize: 40 }} /></div>
            </div>
          <div style={classes.fullWidthBody}> 
            {Object.keys(products).map(date=> <ProductDayBody isMobile={isMobile} selectedProduct={selectedProduct} date={date} setSelectedProduct={setSelectedProduct} key={`prod-cont-${date}`}  products={products[date]}/>)}
          </div>
          </div>
        </div>
    )
  }
  else { 
    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
           <div style={{display: 'flex'}}>
            <div style={{width: '250px'}}> </div>
            <div style={classes.prodHeadCont}>
              <div style={classes.prev}><ChevronLeftIcon onClick={movePrev} sx={{ fontSize: 40 }} /></div>
              {days.map((day, index)=> <DayHeader key={`head-${index}`} date={day}/>)}
              <div style={classes.next}><ChevronRightIcon onClick={moveNext} sx={{ fontSize: 40 }} /></div>
            </div>
          </div>
          <div style={{display: 'flex', height: `calc(100vh - 200px)`, overflowY: 'scroll'}}>
            <div style={classes.prodHead}>
              {products[days[0]] && Object.keys(products[days[0]]).map(productId => <IndProdHeadCard key={productId} product={products[days[0]][productId]} productId={productId}/>)}
            </div>
            <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <div style={classes.fullWidthBody}>            
              {Object.keys(products).map(date=> <ProductDayBody selectedProduct={selectedProduct} date={date} setSelectedProduct={setSelectedProduct} key={`prod-cont-${date}`}  products={products[date]}/>)}
            </div>
            </div>
          </div>
        </div>
    )
  }

}


export default function BookingList(props) {
  const [bookings, setBookings] = useState([])
  const [products, setProducts] = useState({})
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedBooking, setSelectedBooking] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [showDetailPopup, setShowDetailPopup] = useState(false)
  const [theWeek, setTheWeek] = useState(moment().week())
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const isMobile = windowDimensions.width < 500 ? true : false

  const moveNext = () => {
    setTheWeek(theWeek+1)
  }
  const movePrev = () => {
    setTheWeek(theWeek-1)
  }


  useEffect(()=>{
      let startDate, endDate;
      startDate = moment().day("Sunday").week(theWeek).format('DD-MM-YYYY');
      endDate = moment().day("Saturday").week(theWeek).format('DD-MM-YYYY');
      const getBookingData = async() => {
        try {
          const data  = await getAllBookings(startDate, endDate, props.selectedStatuses, props.includeCancel);
          setBookings(data)
        } catch (e) {
          if (e.status === 401) {
            dispatch(removeUserAction())
            navigate('/');
          }
        }
      }
      const getProductData = async () => {
        try {
          const data = await getProductOccupancy(startDate, endDate, props.selectedType);
          setProducts(data)
        } catch (e) {
          if (e.status === 401) {
            dispatch(removeUserAction())
            navigate('/');
          }
        }
      }
      if (props.type==='booking') {
          getBookingData()
          
      }
      if (props.type==='product') {
          getProductData()
      }
  }, [props.type, theWeek, props.selectedStatuses, props.includeCancel, props.selectedType])
  return (
    <Paper sx={classes.root}>
        {props.type==='booking'&&(<SingleWeekPane
          bookings={bookings}
          setSelectedBooking={setSelectedBooking}
          selectedBooking={selectedBooking}
          setShowDetailPopup={setShowDetailPopup}
          theWeek={theWeek}
          isMobile={isMobile}
          moveNext={moveNext}
          movePrev={movePrev}
        />)}
        {props.type==='product'&&(<SingleWeekProductPane
          products={products}    
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          theWeek={theWeek} 
          isMobile={isMobile}         
          moveNext={moveNext}
          movePrev={movePrev}
        />)}
        <WeeklyBookPopup isMobile={isMobile} bookings={bookings} open={showDetailPopup} setOpen={setShowDetailPopup} selectedBooking={selectedBooking}/>
    </Paper>
  );
}
