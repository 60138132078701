import {getStore, removeItem, checkStatusError} from '../util' 
import {ValidationError} from '../util/errors' 

const getProductBrands = async() => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
      }
  const response =  await fetch(`/api/admin/productbrands` ,{
         headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        }
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
}

const getAllProducts = async () => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
      }
  const response =  await fetch(`/api/admin/product` ,{
         headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        }
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
};
const getProductById = async (id) => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
      }
  const response =  await fetch(`/api/admin/product/${id}` ,{
         headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        }
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
};
const addProduct = async(data, file) => {
    const user = getStore('user')
  const formData = new FormData();
  formData.append('image', file);
  formData.append('data', JSON.stringify(data));
  const response =  await fetch(`/api/admin/product`,{
        method: 'POST',
        headers: {
            'x-access-token': user.accessToken
        },
        body: formData
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
}

const addProductWithTemplate = async(data, file) => {
    const user = getStore('user')
  const formData = new FormData();
  formData.append('image', file);
  formData.append('data', JSON.stringify(data));
  const response =  await fetch(`/api/admin/product-with-template`,{
        method: 'POST',
        headers: {
            'x-access-token': user.accessToken
        },
        body: formData
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
}

const editProduct = async(id, data, file) => {
    const user = getStore('user')
  const formData = new FormData();
  if (file) {
    formData.append('image', file);
  }
  if (data) {
    formData.append('data', JSON.stringify(data));
  }
  const response =  await fetch(`/api/admin/product/${id}`,{
        method: 'PUT',
        headers: {
            'x-access-token': user.accessToken
        },
        body: formData
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
}
const delProduct = async(productId) => {
    const user = getStore('user')
  const response =  await fetch(`/api/admin/product/${productId}`,{
        method: 'DELETE',
        headers: {
            'x-access-token': user.accessToken
        },
    });
    console.log(response.ok);
    if (response.ok) {
        return ;
    }
    throw response;
}
const getProductName = async () => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
      }
  const response =  await fetch(`/api/admin/productname` ,{
         headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        }
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
}
const getMultipleProducts  = async(ids) => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
      }
      const idString = ids.map(id=>`ids[]=${id}&`).join('')    
      idString.slice(0, -2)
      //idString.slice(0, -1)
  const response =  await fetch(`/api/admin/products?${idString}` ,{
         headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        }
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
}
const getAvailableProducts  = async(fromDate, toDate) => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
      }
      
    const response =  await fetch(`/api/admin/availableproduct?from=${fromDate}&to=${toDate} ` ,{
         headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        }
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
}
const getProductOccupancy  = async(fromDate, toDate, selectedType) => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
      }
    let url = `/api/admin/productoccupancy?from=${fromDate}&to=${toDate}`;
    if (selectedType !== 'All') {
        url += `&type=${selectedType}`
    }
    const response =  await fetch(url, {
         headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        }
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
}
const getProductTypes = async () => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
      }
  const response =  await fetch(`/api/admin/producttype` ,{
         headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        }
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
}

const getProductDetail = async(id, date) => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
    }
    const response =  await fetch(`/api/admin/productoccupancy/${id}?date=${date}`, {
         headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        }
    });
    if (response.ok) {
        return response.json();
    }
    throw response;

}
const getTemplateById = async (id) => {
    const user = getStore('user');
    if (!user) {
      throw new ValidationError('token is not found');
      }
  const response =  await fetch(`/api/admin/template/${id}` ,{
         headers: {
            'Content-Type': 'application/json',
            'x-access-token': user.accessToken
        }
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
}
export {getAllProducts, addProduct, delProduct, getProductById, editProduct, getMultipleProducts, getProductName, getAvailableProducts, getProductOccupancy, getProductTypes, getProductDetail, getProductBrands, getTemplateById, addProductWithTemplate}
