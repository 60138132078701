import React, {useState, useEffect} from 'react';
import { Box, Button, Dialog, DialogTitle, Typography, DialogContent, DialogActions, TextField, Autocomplete, Chip, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {SnackBar} from  '../components/general.js';
import {getWindowDimensions} from '../util/index.js'
import CircularProgress from '@mui/material/CircularProgress';
import {getStore} from '../util';

const styles = {
    secondRow: {
        width:'100%',
        display:'flex',
        flexDirection: 'row'
    },
    left : {
        width: '70%'
    },
    right: {
        width: '3%'
    },
    imgCont: {
           borderRadius: '50%'
    },
    img: {
        objectFit: 'contain',
        width: '25%',
        height: '100px'
    }
}
function TemplateNameSelect({options, setSelectedTemplate}) {
  const [open, setOpen] = useState(false);
  const [selectedOpt, setSelectedOpt] = useState()

    return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
          console.log('close')
      }}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => `${option.name} - ${option.brand}`}
      options={options}
      value={selectedOpt}
      onChange={
          (e, value)=> {
              if (value) {
                  setSelectedTemplate(value._id);
                  setSelectedOpt(value)
              } else {
                    setSelectedTemplate(undefined)
                    setSelectedOpt(undefined)
              }
          }
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Product Template"
        />
      )}
    />
  );
}

const IndIncludes = ({item}) => {
    console.log(item)
    return (
        <Box sx={{marginTop:1}}>
            <Stack direction="row" alignItems="center">
                <Typography sx={{marginRight:5}}> > </Typography>
                <Typography sx={{marginRight:2}} >{item.item}</Typography>
                <Typography  sx={{marginRight:2}}>x</Typography>
                <Typography sx={{marginRight:10}}>{item.count}</Typography>
            </Stack>
        </Box>
    )
}

const Includes = ({includes=[]}) => {
    return (
        <Box sx={{marginTop:2}}>
            <Typography variant="h6"> Included Items </Typography>
                {includes?.map(_include=><IndIncludes key={`inclde-${_include.item}`} item={_include}/>)}
        </Box>
    )
}

const IndSpecs = ({spec}) => {
    return (
        <Box sx={{marginTop:1}}>
            <Stack direction="row" alignItems="center">
                <Typography sx={{marginRight:5}}> > </Typography>
                <Typography sx={{marginRight:2}} >{`${spec.label} -  ${spec.value}`}</Typography>
            </Stack>
        </Box>
    )
}

const Specs = ({specs=[]}) => {
    return (
        <Box sx={{marginTop:2}}>
            <Typography variant="h6"> Specifications </Typography>
                {specs?.map(_spec=><IndSpecs key={`spec-${_spec.label}`} spec={_spec}/>)}
        </Box>
    )
}

const TemplateBody = ({templateId}) => {
    const [templateData, setTemplateData] = useState()
    const user = getStore('user');
    useEffect(()=> {
        const getTemplateData = async() => {
            if (user && templateId) {
                const response =  await fetch(`/api/admin/template/${templateId}` ,{
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': user.accessToken
                    }
                });
                if (response.ok) {
                    const _templateData = await response.json();
                    setTemplateData(_templateData)
                    console.log(_templateData)
                } else {
                    console.log(`Error`, response.status)
                }
            }
        }
        if (templateId) {
            getTemplateData()
        } else {
            setTemplateData(undefined)
        }
    }, [templateId])
    if (templateData) {
        return (
            <Box sx={{marginTop:2}}>
                <Typography sx={{width:'100%', display: 'flex', alignItems:'center', justifyContent:'center'}} variant="h4">{templateData.name}</Typography>
                <Stack direction="row" spacing={1}>
                    <Chip label={templateData.type} />
                    <Chip label={templateData.brand} />
                </Stack>
                <Box style={styles.secondRow}>
                    <Box style={styles.left}>
                        <Includes includes={templateData?.includes}/>
                        <Specs specs={templateData?.specs}/>
                    </Box>
                    <Box style={styles.right}>
                        <Box style={styles.imgCont}>
                            <img src={templateData.image?.thumb}/>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }
}


export default function CustomizedDialogs(props) {

    const [showSnack, setShowSnack] = React.useState(false);
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState()
    const [snackMessage, setSnackMessage] = React.useState('Something went wrong');
    const [snackType, setSnackType] = React.useState('error');
    const dim = getWindowDimensions();
    const isMobile = dim.width < 500 ? true : false
    const user = getStore('user');

    useEffect(()=>{
        const getAllTemplateNames  = async() => {
            if (user) {
                const response =  await fetch(`/api/admin/templates-name` ,{
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': user.accessToken
                    }
                });
                if (response.ok) {
                    const templateNames = await response.json();
                    setTemplates(templateNames)
                } else {
                    console.log(`Error`, response.status)
                }
            }
        }
        getAllTemplateNames();
    }, [])

    React.useEffect(()=>{
      if (!props.editableProduct && props.addProductOpen) {
        resetFields();
      }
    }, [props.editableProduct, props.addProductOpen])

    const resetFields = () => {

    }
    const setFields = (data) => {

  }
    const handleAddProductClose = (e) => {
        props.addProductClose()(e);
        resetFields()
    }
    const handleSnackClose=(e)=> {
      setShowSnack(false)
    }
    const handleClose =()=> {
        setSelectedTemplate(undefined)
        props.handleCloseTemplate();
    }


  return (
    <div>
      <Dialog PaperProps={{ sx: { minHeight:500 } }} onClose={handleClose} fullWidth={true}
        maxWidth={"md"} aria-labelledby="customized-dialog-title" open={props.templateOpen}>
        <DialogTitle id="customized-dialog-title" onClose={props.handleCloseTemplate}>
          Use Template
        </DialogTitle>
        <DialogContent dividers>
           <TemplateNameSelect options={templates} setSelectedTemplate={setSelectedTemplate}/>
            <TemplateBody templateId={selectedTemplate}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button autoFocus onClick={()=> {setSelectedTemplate(undefined); props.doUseTemplate(selectedTemplate)}} color="primary">
      Use template
          </Button>
        </DialogActions>
      </Dialog>
      <SnackBar message={snackMessage} open={showSnack} severity={snackType} handleClose={handleSnackClose}/>
    </div>
  );
}
