import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import {MenuItem} from '@mui/material';
import {FullBookFilter} from './bookFilter.js';
import {getProductTypes} from '../services/products.js'
const classes = {
    addCont :{
      width: '95%',
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: '20px',
    },
    filterCont: {
        display: 'flex',
        flexDirection: 'row',
        marginRight: 100,
        width:'100%',
        justifyContent: 'space-evenly'
    },
    label: {
        margin:0,
        paddingTop:0,
        width: 'auto!important',
        flexWrap: 'nowrap!important'
    }
}
const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  


const TypeDropdown = (props) => {
    const [types, setTypes] = React.useState([]);
    React.useEffect(()=>{
        const getData = async() => {
            const _types = await getProductTypes();
            setTypes([{label:'All', value: "All"}, ..._types])
        }
        getData();
    }, [])
    const handleChange = (e) => {
        console.log(e.target)
        console.log(e.target.value)
        props.setSelectedType(e.target.value)
        //setOpen(false)
    }
      return (
        <Box sx={props.isMobile ? {minWidth: 150, marginRight:'10px'} : {minWidth: 200, marginRight:'50px' }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.selectedType}
                label="Type"
                onChange={handleChange}
                >
                {types.map((x, key)=><MenuItem  value={x.label} key={key}>{x.label}</MenuItem>)}
                </Select>
            </FormControl>
        </Box>
      )
}
  /*const TypeDropdown = (props) => {
      const [open, setOpen] = React.useState(false);
      const [anchorEl, setAnchorEl] = React.useState(null);
      const [types, setTypes] = React.useState([]);
      React.useEffect(()=>{
        const getData = async() => {
          const _types = await getProductTypes();
          setTypes([{title:'All'}, ..._types])
        }
        getData();
      }, [])

      const handleClose = (e) => {
          props.setSelectedType(e.target.textContent)
          setOpen(false)
      }
  
      return (
          <div style={{display: 'flex', alignItems: 'center', minHeight:'50px'}}>
              <div >Type : </div>
              <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e)=>{setAnchorEl(e.currentTarget);setOpen(!open)}}>
              {props.selectedType|| `Select Type`}
              </Button>
              <Menu
              id="type-menu"
              keepMounted
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              >
              {types.map((x, key)=><MenuItem key={key} onClick={handleClose}>{x.title}</MenuItem>)}
              </Menu>
          </div>
      )
  }*/

const TopBar = ({type, toggleType, setSelectedStatuses, selectedStatuses, includeCancel, toggleIncludeCancel, selectedType, setSelectedType}) => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const isMobile = windowDimensions.width < 500 ? true : false
    const lbl = isMobile ? 'Cancelled' : 'Include Cancelled'
    return (
        <div style={classes.addCont}>
            <div style={isMobile ? {...classes.filterCont, marginRight:0, flexDirection:'column'} : {...classes.filterCont, flexDirection:'row'}}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    {type==='booking' && <FormControlLabel control={<Switch checked={includeCancel}/>} onChange={toggleIncludeCancel} label={lbl} />}
                    {type!=='booking' && <TypeDropdown selectedType={selectedType} setSelectedType={setSelectedType} isMobile={isMobile}/>}
                    <Grid component="label" container alignItems="center" sx={classes.label} spacing={1}>
                        <Grid style={{paddingTop:'0px'}} item>Booking</Grid>
                        <Grid style={{paddingTop:'0px'}} item>
                            <Switch
                            color="default"
                            onChange={toggleType} // relevant method to handle your change
                            value={type==='booking'?false:true} // some value you need
                            />
                        </Grid>
                        <Grid style={{paddingTop:'0px'}} item>Product</Grid>
                    </Grid>
                </div>
                {type==='booking' && <FullBookFilter isMobile={isMobile} setSelectedStatuses={setSelectedStatuses} selectedStatuses={selectedStatuses}/>}
            </div>
        </div>
    )
}

export default TopBar;
