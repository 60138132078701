import React, { useEffect, useState } from 'react';
import {getProductDetail} from '../services/products.js'
import moment from 'moment'
import {ProductMonthChart, CountPie} from './charts/productMonthChart.js'
import GroupIcon from '@mui/icons-material/Group';
import BookDetailPopup from './bookDetailPopup.js'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
const classes ={
    container: {
        width: '100%',
        height: '100%',
    },
    rentCont: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        marginBottom: '30px'
    },
    purchaseCostCont: {
        display: 'flex',
        width: '95%',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    cumRentCostCont: {
        display: 'flex',
        width: '95%',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    purchaseCostBar : {
        display: 'flex',
        width: '80%'
    },
    cumRentBar: {
        display: 'flex',
        width: '80%'
    },
    purchaseCostLine: {
        height: '10px',
        color: 'black',
        borderRadius: '5px'
    },
    purchaseRentLine: {
        height: '10px',
        color: 'black',
        borderRadius: '5px'
    },
    purchaseCost: {
        marginLeft: 20,
        whiteSpace: 'nowrap'
    },
    cumRent: {
        marginLeft: 20,
        whiteSpace: 'nowrap'
    },
    calendarHeadCont: {
        display: 'flex',
        width: '100%',
    },
    weekday: {
        width: `calc(${Math.floor((1/7)*100)}% - 2px)`,
    },
    IndCont: {
        width: `calc(${Math.floor((1/7)*100)}% - 2px)`,
        height: 110,
        border: '1px solid black',
        position: 'relative'
    },
    barCont : {
        margin: '10px',
        width: '80%',
        height: 10,
        overflow: 'hidden',
        borderRadius: '10px'
    },
    fullbar :{
        width: '100%',
        height: '100%',
        display: 'flex'
    },
    booked: {
        backgroundColor: 'red',
        height: '100%'
    },
    free: {
        backgroundColor: 'green',
        height: '100%'
    },
    countCont: {
        display: 'flex'
    },
    countEl: {
        marginLeft: '10px'
    },
    revCont: {
        display: 'flex',
        marginLeft: '10px',
        marginTop:'10px'
    },
    revPend: {
        whiteSpace: 'nowrap',
        color: '#FF6700',
        marginRight: '10px'
    },
    revClose: {
        whiteSpace: 'nowrap',
        color: '#1399a7',
        marginRight: '10px'
    },
    revExpect: {
        whiteSpace: 'nowrap',
        color: '#FA2FB5',
        marginRight: '10px'
    },
    left: {
        width: '50%'
    },
    right: {
        width: '50%',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    detCont: {
        display: 'flex',
        marginBottom: '30px'
    },
    img: {
        objectFit: 'contain',
        width: '25%',
        height: '100px'
    },
    title: {
        fontSize: '1.75rem',
        fontWeight: 'bold',
        marginRight: '20px',
        whiteSpace: 'nowrap'
    },
    titleCont: {
        display: 'flex',
        alignItems:"center",
        marginBottom: '10px'
    },
    firstRow: {
        display: 'flex',
        marginBottom: '10px'
    },
    firstRowEl: {
        marginRight: '20px'
    },
    monthlyFigures : {
        display: 'flex',
        marginBottom: '10px'
    },
    dateNum :{
        margin: '5px',
        padding: '2px',
        width: '20px',
    },
    chartCont: {
        marginTop: '20px'
    },
    calendarCont: {
        overflowY: 'scroll',
        height: 'calc(100% - 350px)'
    },
    btnCont: {
        position: 'absolute',
        bottom: '0px',
        right: '10px'
    },
    monthSelectCont: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px'
    },
    monthCtrl: {
        minWidth: '200px!important'
    },
    pieCont: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    }
}
const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
const MonthSelection = ({theDate, setTheDate}) => {
    const endMonth = moment().subtract(3, "month").startOf("month").format('MMMM');
    const lastMonth = moment().subtract(2, "month").startOf("month").format('MMMM');
    const prevMonth = moment().subtract(1, "month").startOf("month").format('MMMM');
    const currMonth = moment().startOf("month").format('MMMM');
    const nextMonth = moment().add(1, "month").startOf("month").format('MMMM');
    const futureMonth = moment().add(2, "month").startOf("month").format('MMMM');
    const [value, setValue] = useState(0);
    const handleChange = (e) => {
        setTheDate(moment().add(e.target.value, "month").startOf("month").format('DD-MM-YYYY'))
        setValue(e.target.value)
    }
    return (
        <div style={classes.monthSelectCont}>
            <FormControl sx={classes.monthCtrl}>
                <InputLabel id="demo-simple-select-label">Month</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                label="Age"
                onChange={handleChange}
                >
                <MenuItem value={-3}>{endMonth}</MenuItem>
                <MenuItem value={-2}>{lastMonth}</MenuItem>
                <MenuItem value={-1}>{prevMonth}</MenuItem>
                <MenuItem value={0}>{currMonth}</MenuItem>
                <MenuItem value={1}>{nextMonth}</MenuItem>
                <MenuItem value={2}>{futureMonth}</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}

const RevenueRow = ({totalExpected, totalClosed, totalPending, id, date, isMobile}) => {
    if (totalExpected || totalClosed || totalPending) {
        return (
            <div style={isMobile ? {...classes.revCont, fontSize: '3vw', marginLeft: '2px', marginTop:'5px', display: 'flex', flexDirection: 'column'} : classes.revCont}>
                {totalPending > 0 && (<div  style={isMobile ? {...classes.revPend, marginRight:0} : classes.revPend}>₹ {Math.floor(totalPending)}</div>)}
                <div style={isMobile ? {...classes.revClose, marginRight:0} : classes.revClose}>₹ {Math.floor(totalClosed)}</div>
                {totalExpected > 0 &&  (<div style={isMobile ? {...classes.revExpect, marginRight:0} : classes.revExpect}>₹ {Math.floor(totalExpected)}</div>)}
            </div>
        )
    }
    return (<div style={classes.revCont}><div style={classes.revClose}>₹ 0</div></div>)
}
const IndCalEl = ({isMobile, row, id, product, setShowDetail, setSlectedBooks}) => {
    const getStyle = (date) => {
        if (moment(date, 'DD-MM-YYYY').isSame(new Date(), "day")) {
            return {fontWeight: 'bold', borderRadius: '12.5px', border: '1px solid black'}
        }
        return {borderBottom: '1px solid black'}
    }
    if (row) {
        if (row.schedule) {
            return (
                <div onClick={()=>{if (isMobile){setSlectedBooks(row.ids); setShowDetail(true);}}}>
                    <div style={{...classes.dateNum, ...getStyle(row.date)}}>{moment(row.date, 'DD-MM-YYYY').format('DD')}</div>
                    {!isMobile && (<div style={classes.barCont}>
                        <div style={classes.fullbar}>
                            <div  style={{...classes.booked, width: `${(row.booked/product.noOfItems)*100}%`}}></div>
                            <div style={{...classes.booked, backgroundColor:'green', width: `${(Math.max(0,((product.noOfItems-row.booked))/product.noOfItems))*100}%`}}></div>
                        </div>
                    </div>)}
                    {isMobile && (<div style={classes.pieCont}><CountPie data={[{name: 'booked', value: row.booked/product.noOfItems}, {name:'free', value: Math.max(0, (product.noOfItems-row.booked)/product.noOfItems)}]}/></div>)}
                    <div style={classes.countCont}>
                        <div  style={isMobile ? {...classes.countEl, color:'red'}:classes.countEl}>{row.booked} {!isMobile &&" booked"}</div>
                        <div  style={isMobile ? {...classes.countEl, color:'green'}:classes.countEl}>{Math.max(0, product.noOfItems-row.booked)} {!isMobile &&" free"}</div>
                    </div>
                    <RevenueRow isMobile={isMobile} totalExpected={row.totalExpected} totalPending={row.totalPending} totalClosed={row.totalClosed}  id={id}/>
                    {!isMobile && (<div style={classes.btnCont} onClick={()=>{setSlectedBooks(row.ids); setShowDetail(true);}}><GroupIcon/></div>)}
                </div>
            )
        }
        return (
            <div>
                    <div  style={{...classes.dateNum, ...getStyle(row.date)}}>{moment(row.date, 'DD-MM-YYYY').format('DD')}</div>
                    {!isMobile && (<div style={classes.barCont}>
                        <div style={classes.fullbar}>
                            <div style={{...classes.booked, width: `0%`}}></div>
                            <div style={{...classes.free, width: `100%`}}></div>
                        </div>
                    </div>)}
                    {isMobile && (<div style={classes.pieCont}><CountPie data={[{name: 'booked', value: 0}, {name:'free', value: 1}]}/></div>)}
                    <div style={classes.countCont}>
                        <div style={isMobile ? {...classes.countEl, color:'red'}:classes.countEl}>0 {!isMobile &&" booked"}</div>
                        <div style={isMobile ? {...classes.countEl, color:'green'}:classes.countEl}>{product.noOfItems} {!isMobile &&" free"}</div>
                    </div>
                    <RevenueRow id={id}/>
                </div>
        )
    }
    return (<div style={classes.weekday}></div>)
}
const MonthlyView  = ({width, product, schedule, setShowDetail, setSlectedBooks, theDate, setTheDate, isMobile}) => {
    if (schedule && Object.keys(schedule).length > 0) {
        const weekdayshort = moment.weekdaysShort();
        let weekdayshortname = weekdayshort.map(day => {
            return (
            <div key={day} style={{...classes.weekday, display: 'flex', justifyContent: 'center'}}>
                {day}
            </div>
            );
        });
        const firstDayOfMonth = () => {
            let firstDay = moment(Object.keys(schedule)[0], 'DD-MM-YYYY')
                        .startOf("month")
                        .format("d"); 
            return firstDay;
        };
        let blanks = [];
        for (let i = 0; i < firstDayOfMonth(); i++) {
        blanks.push(null);
        }
        let daysInMonth = [];
        const totalNumberOfDays = moment(Object.keys(schedule)[0], 'DD-MM-YYYY').daysInMonth()
        const yearAndMonth = moment(Object.keys(schedule)[0], 'DD-MM-YYYY').format('MM-YYYY')
        for (let d = 1; d <= totalNumberOfDays; d++) {
            d = d < 10 ? `0${d}` : d;
            console.log(schedule[`${d}-${yearAndMonth}`], `${d}-${yearAndMonth}`)
            if (schedule[`${d}-${yearAndMonth}`] && Array.isArray(schedule[`${d}-${yearAndMonth}`]) && schedule[`${d}-${yearAndMonth}`].length > 0) {
                const booked = schedule[`${d}-${yearAndMonth}`].reduce((count, el)=>{
                    if(el[product._id].status !== 'cancelled'  && moment(`${d}-${yearAndMonth}`, 'DD-MM-YYYY').isBefore(new Date(), 'day')) {
                        count +=el[product._id].count;
                    }else if(el[product._id].status === 'checkedout' || el[product._id].status === 'booked' && moment(`${d}-${yearAndMonth}`, 'DD-MM-YYYY').isSameOrAfter(new Date(), 'day')) {
                        count +=el[product._id].count;
                    }
                    return count
                },0)
                const totalPending = schedule[`${d}-${yearAndMonth}`].filter(sched=> sched[product._id].status === "checkedin").reduce((total, sched) => {
                    const discount = sched[product._id].discount;
                    const discountPercentage = discount/(sched[product._id].amount+ discount);
                    const totalRent = sched[product._id].count * sched[product._id].rent;
                    const adjustedRent = totalRent - (totalRent*discountPercentage);
                    const advPercent = adjustedRent/(sched[product._id].amount);
                    const adjustedAdvance = sched[product._id].advance * advPercent;
                    total += (adjustedRent-adjustedAdvance);
                    return total;
                }, 0)
                const totalClosed = schedule[`${d}-${yearAndMonth}`].filter(sched=> sched[product._id].status !== "cancelled").reduce((total, sched) => {
                    if (sched[product._id].status==="closed") {
                        const discount = sched[product._id].discount;
                        const discountPercentage = discount/(sched[product._id].amount + discount);
                        const totalRent = sched[product._id].count * sched[product._id].rent;
                        const adjustedRent = totalRent - (totalRent*discountPercentage)
                        total += adjustedRent
                    } else {
                        if (sched[product._id].advance) {
                            const discount = sched[product._id].discount;
                            const discountPercentage = discount/(sched[product._id].amount + discount);
                            const totalRent = sched[product._id].count * sched[product._id].rent;
                            const adjustedRent = totalRent - (totalRent*discountPercentage)
                            const advPercent = adjustedRent/(sched[product._id].amount);
                            const adjustedAdvance = sched[product._id].advance * advPercent;
                            total += adjustedAdvance;
                        }
                    }
                    return total;
                }, 0)
                const totalExpected = schedule[`${d}-${yearAndMonth}`].filter(sched=> sched[product._id].status === "booked" || sched[product._id].status === "checkedout").reduce((total, sched) => {
                    const discount = sched[product._id].discount;
                    const discountPercentage = discount/(sched[product._id].amount + discount);
                    const totalRent = sched[product._id].count * sched[product._id].rent;
                    const adjustedRent = totalRent - (totalRent*discountPercentage) ;
                    const advPercent = adjustedRent/(sched[product._id].amount);
                    const adjustedAdvance = sched[product._id].advance * advPercent;
                    total += (adjustedRent - adjustedAdvance)
                    return total;
                }, 0)
                const ids = schedule[`${d}-${yearAndMonth}`].map(x=>x[product._id].id)
                const total = Math.floor(totalExpected+totalClosed+totalPending)
                daysInMonth.push({schedule: schedule[`${d}-${yearAndMonth}`], total:total, ids:ids, booked:booked,  totalPending: Math.floor(totalPending), totalClosed:Math.floor(totalClosed), totalExpected:Math.floor(totalExpected), shortDate: d, date: `${d}-${yearAndMonth}`});
            } else {
                const total = 0
                daysInMonth.push({schedule: undefined, ids:[], total:total, booked:0, totalClosed:0, totalPending:0, totalExpected:0, shortDate: d, date: `${d}-${yearAndMonth}`});
            }
            
        }
        const totalSlots = [...blanks, ...daysInMonth];
        let rows = [];
        let cells = [];
        totalSlots.forEach((row, i) => {
            if (i % 7 !== 0) {
                if (row) {
                    cells.push(<div style={isMobile ? {...classes.IndCont, height: '130px'} : classes.IndCont}><IndCalEl isMobile={isMobile} setShowDetail={setShowDetail} setSlectedBooks={setSlectedBooks} product={product} row={row} id={product._id}/></div>); // if index not equal 7 that means not go to next week
                } else {
                    cells.push(<div style={isMobile ? {...classes.IndCont, height: '130px'} : classes.IndCont}><IndCalEl isMobile={isMobile} setShowDetail={setShowDetail} setSlectedBooks={setSlectedBooks} product={product} row={row}/></div>); // if index not equal 7 that means not go to next week
                }
            } else {
                if (row) {
                    rows.push(cells); // when reach next week we contain all td in last week to rows 
                    cells = []; // empty container 
                    cells.push(<div style={isMobile ? {...classes.IndCont, height: '130px'} : classes.IndCont}><IndCalEl isMobile={isMobile} setShowDetail={setShowDetail} setSlectedBooks={setSlectedBooks} product={product} row={row} id={product._id}/></div>); // in current loop we still push current row to new container
                } else {
                    cells.push(<div style={isMobile ? {...classes.IndCont, height: '130px'} : classes.IndCont}><IndCalEl isMobile={isMobile} product={product} setShowDetail={setShowDetail} setSlectedBooks={setSlectedBooks} row={row}/></div>); // if index not equal 7 that means not go to next week
                }
            }
            if (i === totalSlots.length - 1) { // when end loop we add remain date
                rows.push(cells);
            }
        });
        return (
            <div style={classes.calendarCont}>
                <MonthSelection theDate={theDate} setTheDate={setTheDate}/>
                <div style={{...classes.calendarHeadCont, marginBottom: '10px'}}>{weekdayshortname}</div>
                <div>
                    {rows.map(row=><div style={classes.calendarHeadCont}>{row}</div>)}
                </div>
                <div style={classes.chartCont}>
                    <ProductMonthChart schedule={daysInMonth} product={product} isMobile={isMobile} width={width}/>   
                </div>
            </div>
        )
    }
}
const Body = (props) => {
    const [showDetail, setShowDetail] = useState(false);
    const [selectedBooks, setSlectedBooks] = useState([]);
    const getPurchaseStyle =  (purchasePrice, cumulativeRent) => {
        if (purchasePrice > cumulativeRent) {
            return {
                width: '100%',
                backgroundColor: 'blue'
            }
        } 
        return {
            backgroundColor: 'blue',
            width:  `${((cumulativeRent - purchasePrice)/(cumulativeRent+purchasePrice))*100}%`
        }
    }
    const getRentStyle =  (purchasePrice, cumulativeRent) => {
        if (purchasePrice > cumulativeRent) {
            return {
                width: `${(cumulativeRent/purchasePrice)*100}%`,
                backgroundColor: 'red'
            }
        } 
        return {
            color:'green',
            width:  '100%'
        }
    }
    let expected = 0, pending = 0,  received = 0;
    const adjustRent = (status, discount, amount, rent, count, advance, type) => {
        const discountPercentage = discount/(amount + discount); 
        if (type === 'closed') {
            const totalRent = count * rent;
            if (status === 'closed') {
                return Math.floor(totalRent - (totalRent*discountPercentage))
            }
            const adjustedRent = totalRent - (totalRent*discountPercentage)
            const advPercent = adjustedRent/(amount);
            const adjustedAdvance = advance * advPercent;
            return Math.floor(adjustedAdvance)
        }
        if (type === 'expected' || type === 'pending') {
            if (advance) {
                const totalRent = count * rent;
                const adjustedRent = totalRent - (totalRent*discountPercentage)
                const advPercent = adjustedRent/amount;
                const adjustedAdvance = advance * advPercent;
                return Math.floor(adjustedRent - adjustedAdvance)
            }
            const totalRent = count * rent;
            return Math.floor(totalRent - (totalRent*discountPercentage))
        }
        
    }
    if (props.schedule) {
        for (const key in props.schedule) {
            for(const _book in props.schedule[key]) {
                const obj = props.schedule[key][_book][props?.product._id];
                if (obj.status === 'booked' || obj.status === 'checkedout') {
                    expected += adjustRent(obj.status, obj.discount, obj.amount, obj.rent, obj.count, obj.advance, 'expected')
                }
                if (obj.status === 'checkedin') {
                    pending += adjustRent(obj.status, obj.discount, obj.amount, obj.rent, obj.count, obj.advance, 'pending')
                }
                if (obj.status !== 'cancelled') {
                    received += adjustRent(obj.status, obj.discount, obj.amount, obj.rent, obj.count, obj.advance, 'closed')
                }
            }
        }
    }
    
    return (
        <div style={classes.container}>
            <div style={classes.detCont}>
                <div style={props.isMobile ? {...classes.left, width:'70%'} : classes.left}>
                    <div style={classes.titleCont}>
                        <div style={classes.title}>{props?.product?.name}</div>
                        <div style={{whiteSpace: 'nowrap'}}>₹ {props?.product?.rent}</div>
                    </div>
                    <div style={classes.firstRow}>
                        <div style={props.isMobile ? {...classes.firstRowEl, marginRight:'10px'}: classes.firstRowEl}>Total Items : {props?.product?.noOfAllItems}</div>
                        <div style={props.isMobile ? {...classes.firstRowEl, marginRight:'10px'}: classes.firstRowEl}>Total Available Items : {props?.product?.noOfItems}</div>
                    </div>
                    <div style={classes.monthlyFigures}>
                        <div style={props.isMobile ? {...classes.firstRowEl, marginRight:'10px'}: classes.firstRowEl}>Expected : <span style={classes.revExpect}>₹ {expected}</span></div>
                        <div style={props.isMobile ? {...classes.firstRowEl, marginRight:'10px'}: classes.firstRowEl}>Received : <span style={classes.revClose}>₹ {received}</span></div>
                        <div style={props.isMobile ? {...classes.firstRowEl, marginRight:'10px'}: classes.firstRowEl}>Pending :  <span style={classes.revPend}>₹ {pending}</span></div>
                    </div>
                </div>
                <div style={props.isMobile ? {...classes.right, width:'30%'} : classes.right}>
                    <img src={props?.product?.image.thumb} style={props.isMobile ? {...classes.img, width:'80%'} : {...classes.img, width: '25%'}}/>
                </div>
            </div>
            <div style={classes.rentCont}>
                        <div style={classes.purchaseCostCont}>
                                <div style={{...classes.purchaseCostLine, ...getPurchaseStyle(props?.product?.purchasePrice, props?.product?.cumulativeRent)}}></div>
                            <div style={classes.purchaseCost}>₹ {props?.product?.purchasePrice ?? 'NA'}</div>
                        </div>
                        <div style={classes.cumRentCostCont}>
                            
                                <div style={{...classes.purchaseRentLine, ...getRentStyle(props?.product?.purchasePrice, props?.product?.cumulativeRent)}}></div>
                            
                            <div style={classes.cumRent}>₹ {Math.floor(props?.product?.cumulativeRent) ?? 'NA'}</div>
                        </div>
                    </div>
            <MonthlyView width={props.width} theDate={props.theDate} isMobile={props.isMobile} setTheDate={props.setTheDate} setShowDetail={setShowDetail} setSlectedBooks={setSlectedBooks} product={props?.product} schedule={props?.schedule}/>
            <BookDetailPopup isMobile={props.isMobile} open={showDetail} setOpen={setShowDetail} selectedBookings={selectedBooks}/>
        </div>
    )
}
const ProductDetailComponent = ({id, open, setOpen}) =>{
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const isMobile = windowDimensions.width < 500 ? true : false
    const [productDetail, setProductDetail] = useState();
    const [theDate, setTheDate] = useState(moment().format('DD-MM-YYYY'))
    useEffect(()=>{
        const _getProductDetail = async(_id) => {
            const data = await getProductDetail(_id, theDate);
            setProductDetail(data);
        }
        if (id) {
            _getProductDetail(id)
        }
    },[id, theDate])

    return <Body {...productDetail} width={windowDimensions.width} theDate={theDate} setTheDate={setTheDate} isMobile={isMobile}/>
}


export default ProductDetailComponent;
