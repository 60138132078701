import React, {useState, useEffect} from 'react';
import {Paper,  Box, FormControl, InputLabel, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, Typography, TableRow, IconButton} from '@mui/material';
import FlagIcon from '@mui/icons-material/Flag';
import {CustomAvatar, ConfirmDialog} from  './general';
import {getUsers, doBlockUser, doUnBlockUser, doFlag, doUnFlag} from '../services/user.js';
import EditIcon from '@mui/icons-material/Edit';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import {removeUserAction} from '../actions/profile.js';
import FlagConfirmation from '../components/flagConfirmation.js';
import { getStore} from '../util';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {SnackBar} from  '../components/general.js';

const headers = [
    {label:'', minWidth:20},
    {label:'Name', minWidth:170},
    {label:'Status', minWidth:150},
    {label:'Phone', minWidth:150},
    {label:'Flag', minWidth:150},
    ]

const classes = {
  root: {
    width: '100%',
    position:'relative'
  },
  container: {
    maxHeight: '80vh',
  },
  pagination : {
    position: 'absolute',
    bottom:0,
    right:0
  },
  productInfoCont: {
      width: '100%',
      display: 'flex',
      marginBottom: '20px',
      paddingLeft: '10px'
  },
  productInfoLeft: {
    width: '30%'
  },
  productInfoRight : {
    width: '70%'
  },
  actionBtn: {
    marginLeft: '10px',
    cursor: 'pointer'
  },
  buttonCont: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  }
};


const UserTypeSelect = ({selectedType, setSelectedType}) => {

  const handleChange = (event) => {
    setSelectedType(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120, width: '100%', marginTop:4, marginBottom:4, display:'flex', justifyContent:'center' }}>
      <FormControl sx={{width:200}}>
        <InputLabel id="demo-simple-select-label">Status</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedType}
          label="Status"
          onChange={handleChange}
        >
          <MenuItem value='all'>All</MenuItem>
          <MenuItem value='requested'>Requested</MenuItem>
          <MenuItem value='approved'>Approved</MenuItem>
          <MenuItem value='flagged'>Flagged</MenuItem>
          <MenuItem value='declined'>Declined</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

export default function StickyHeadTable(props) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [editMenuOpen, setEditMenuOpen] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [flagConfirmOpen, setFlagConfirmOpen] = useState(false)
  const [flagReason, setFlagReason] = useState('')
  const [userToFlag, setUserToFlag] = useState();
  const [userToUnFlag, setUserToUnflag] = useState(); 
  const [unflagConfirmOpen, setUnflagConfirmOpen] = useState(false)
    const [showSnack, setShowSnack] = useState(false)
    const [snackMessage, setSnackMessage] = useState('')
    const [snackType, setSnackType] = useState('success')
    const [selectedType, setSelectedType] = useState('all');

    const navigate = useNavigate();
  const dispatch = useDispatch();


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  

    const filterByType = (type) => {
        const userStatusMap = {
            'approved': 'APPROVED',
            'declined': 'DECLINED',
            'flagged': 'FLAGGED',
            'requested': 'REQUESTED'
        }
        
        return (user) => {
            if (type=== 'all') {
                return true
            }
            return user.status === userStatusMap[type]
        }
    }
  const handleEditClick = (id) => {
    return (e) => {
        console.log(e.target.parent)
      const _user = users.find(user => user.userId === id);
        console.log(_user)
      props.setViewUser(_user);
    }
  }
   
  const flag = async() => {
      try {
          await doFlag(userToFlag, flagReason)
          setUserToFlag();
          const _users = [...users];
          const _user = _users.find(x=>x.userId === userToFlag)
          _user.status = 'FLAGGED';
          _user.reason = flagReason;
          setUsers(_users)
          setFlagConfirmOpen(false)
          setShowSnack(true)
          setSnackMessage('User has been flagged')
          setSnackType('success')
      } catch (e) {
        console.log(e)
          setShowSnack(true)
          setSnackMessage('Error in flagging user')
          setSnackType('error')
      }
  }
    const unflag = async () => {
        try {
            await doUnFlag(userToUnFlag)
            setUserToUnflag();
            const _users = [...users];
            const _user = _users.find(x=>x.userId === userToUnFlag)
            _user.status = 'APPROVED';
            delete _user.reason;
            setShowSnack(true)
            setSnackMessage('User has been unflagged')
            setSnackType('success')
            setUsers(_users)
            setUnflagConfirmOpen(false)
        } catch (e) {
            console.log(e)
            setShowSnack(true)
            setSnackMessage('Error in unflagging user')
            setSnackType('error')
        }

    }
    const handleUnflagConfirmClose = () => {
        setUserToUnflag();
        setUnflagConfirmOpen(false)
    }

    const handleFlagConfirmClose = () => {
        setUserToFlag();
        setFlagConfirmOpen(false)
    };

    const handleFlagClick = (row) => {
        return (e) => {
            e.stopPropagation();
            if (row.status === 'APPROVED') {
                setUserToFlag(row.userId);
                setFlagConfirmOpen(true )
            }
            if (row.status === 'FLAGGED') {
                setUserToUnflag(row.userId)
                setUnflagConfirmOpen(true )
            }
        }
    }
  React.useEffect(()=>{
      const getData = async() => {
        try {
          const _users = await getUsers(props.selectedProduct);
          setUsers(_users);
        } catch (e) {
          if (e.status === 401) {
            dispatch(removeUserAction())
            navigate('/');
          }
        }
      }
      getData();
     
  },[props.usersChange])

  const loggedinStore = getStore('user')?.user?.store ?? '';
  return (
    <Paper sx={classes.root}>
      <UserTypeSelect selectedType={selectedType} setSelectedType={setSelectedType}/>
      <TableContainer sx={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headers.map((column) => (
                <TableCell
                  key={column._id}
                  align={'center'}
                  style={{ width: column.minWidth, fontWeight:600 }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.filter(filterByType(selectedType)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, ind) => {
                let flagColor;
                if (row.status === 'APPROVED') {
                    flagColor ='red'
                }
                if (row.status === 'FLAGGED') {
                    flagColor = 'green'
                }
              return (
                <TableRow hover onClick={handleEditClick(row.userId)} tabIndex={-1} key={row.sku}>
                  <TableCell align={'center'}> <CustomAvatar  src={row?.user?.image?.thumb??''} name={row?.user?.name}/></TableCell>
                <TableCell align={'center'}> {row?.user?.name}</TableCell>
                <TableCell align={'center'} style={row.status === 'APPROVED'?{fontWeight:'bold', color:'#228B22'} : {fontWeight:'bold', color:'#D2042D'}}> {row.status}</TableCell>
                <TableCell align={'center'}>{row?.user?.contactNumber} </TableCell>
                <TableCell align={'center'}>
                  {(row.status === 'APPROVED' || row.status === 'FLAGGED') && <IconButton onClick={handleFlagClick(row)}>
                    <FlagIcon style={{color:flagColor}}/>
                  </IconButton>}
                </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        classes={classes.pagination}
        count={users.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <FlagConfirmation
        flagConfirmOpen={flagConfirmOpen}
        handleClose={handleFlagConfirmClose}
        flagReason={flagReason}
        setFlagReason={setFlagReason}
        doFlag={flag}
      />
      <ConfirmDialog
        title={'Are you sure ?'}
        message={'Are you sure to unflag this user?'}
        confirmAction={unflag}
        show={unflagConfirmOpen}
        handleClose={handleUnflagConfirmClose}
      />
      <SnackBar severity={snackType} message={snackMessage} open={showSnack} handleClose={()=>{setShowSnack(false)}}/>
    </Paper>
  );
}

