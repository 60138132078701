import React, {Fragment, useState, useEffect, useContext} from 'react';
import {Box,  Button, Dialog, DialogTitle, DialogActions, DialogContent, Typography, TextField, Stack } from '@mui/material';
import ImageUpload from './imageUpload';
import {getUserById, addUser, editUser, getUserStoreInfo, accreditUser, declineUser} from '../services/user.js';
import {SnackBar} from  '../components/general.js';
import {getStore, getWindowDimensions} from '../util/index.js'
import { ClassNames } from '@emotion/react';

const classes = {};

const AddUser = (props) => {
    const [user, setUser] = React.useState();
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [akpano, setAkpano] = React.useState('');    
    const [file, setFile] = React.useState(undefined);    
    const [avatarUrl, setAvatarUrl] = React.useState('');
    const [proofUrl, setProofUrl] = React.useState('');
    const [proofBackUrl, setProofBackUrl] = React.useState('');
    const [proofFile, setProofFile] = React.useState(undefined);
    const [proofBackFile, setProofBackFile] = React.useState(undefined);
    const [showSnack, setShowSnack] = React.useState(false);
    const [snackType, setSnackType] = React.useState("success");
    const [snackMessage, setSnackMessage] = React.useState('');
    const [showProof, setShowProof] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState({});
    const dim = getWindowDimensions();
    const isMobile = dim.width < 500 ? true : false
    const loggedinStore = getStore('user')?.user?.store ?? '';
    
    const handleAddUserClose = (e) => {
        setShowProof(false)
        setProofFile()
        setFile()
        setProofBackFile()
        setProofUrl()
        setProofBackUrl()
        setAvatarUrl()
        setErrors({})
        props.addUserClose()(e)
    }

    const validateForm = () => {
        let isError = false;
        const _errors = {};
       
        if (!name) {
            _errors.name = 'Name is required'
            isError = true
        } else {
            delete _errors.name
        }
        if (!email) {
            _errors.email = 'Email is required'
            isError = true
        } else {
            delete _errors.email
        }
        if (!phone) {
            _errors.phone = 'Phone is required'
            isError = true
        } else {
            delete _errors.phone
        }
        if (!file) {
            _errors.file = 'Photo is required'
            isError = true
        } else {
            delete _errors.file
        }
        if (!(proofFile && proofBackFile)) {
            _errors.proof = 'Proof is required'
            isError = true
        } else {
            delete _errors.proof
        }
        setErrors(_errors)
        return !isError
    }

    const doAddUser = async() => {
        //data, file, proof, proofBack
        if(validateForm()) {
            setLoading(true)
            const data = {
                name: name,
                email: email,
                contactNumber: phone, 
                akpano: akpano,
                address: address
            }
            try {
                await addUser(data, file, proofFile, proofBackFile)
                setLoading(false)
                setSnackType("success")
                setSnackMessage("User Added successfully")
                setShowSnack(true)
                
            } catch (e) {
                console.log(e)
                setLoading(false)
                setSnackType("error")
                setSnackMessage("Could not add user")
                setShowSnack(true)
            }
        }
    }
    
    return (
        <Dialog onClose={handleAddUserClose} fullWidth={true}
        maxWidth={"md"} aria-labelledby="customized-dialog-title" open={props.addUserOpen}>
        <DialogTitle id="customized-dialog-title" onClose={handleAddUserClose} sx={{display:'flex', alignItems:'center'}}>
            <Typography> Add User  </Typography>
        </DialogTitle>
        <DialogContent dividers>
            <Stack direction="row" sx={{ width: '100%'}} spacing={2}>
                <Stack direction="column" spacing={2} sx={{ width: '48%'}}>
                    <TextField error={errors.name} helperText={errors.name} label="Name" value={name} onChange={(e)=> setName(e.target.value)} variant="outlined" /> 
                    <TextField error={errors.email} helperText={errors.email} label="Email" value={email} onChange={(e)=> setEmail(e.target.value)} variant="outlined" />
                    <TextField error={errors.phone} helperText={errors.phone} label="Phone" value={phone} onChange={(e)=> setPhone(e.target.value)} variant="outlined" />
                    <TextField label="AKPA No" value={akpano} onChange={(e)=> setAkpano(e.target.value)} variant="outlined" />
                    <TextField label="Address"  multiline rows={4} value={address} onChange={(e) => setAddress(e.target.value)} />
                </Stack>
                <Stack direction="column" alignItems="center" spacing={2} sx={{ width: '48%'}}>
                    <Stack direction="column" alignItems="center" spacing={2}>
                        <ImageUpload avatarUrl={avatarUrl} setFile={setFile} label="Profile Photo" setAvatarUrl={setAvatarUrl} />
                        {errors.file && (<Typography color="error">{errors.file}</Typography>)}
                    </Stack>
                    <Stack direction="column" alignItems="center" spacing={2}>
                        <Stack direction="row" spacing={2}>
                            <ImageUpload avatarUrl={proofUrl} label="Proof Front" setFile={setProofFile} setAvatarUrl={setProofUrl} isSquare={true} element="proof-front" type="document"/>
                            <ImageUpload avatarUrl={proofBackUrl} label="Proof Back" setFile={setProofBackFile} setAvatarUrl={setProofBackUrl} isSquare={true} element="proof-back" type="document"/>
                        </Stack>
                        {errors.proof && (<Typography color="error">{errors.proof}</Typography>)}
                    </Stack>
                </Stack>
            </Stack>        
        </DialogContent>
        <DialogActions>
            <Button disabled={loading} onClick={doAddUser} variant="contained" color="primary">
            Add
            </Button>
            <Button disabled={loading} onClick={handleAddUserClose}  color="primary">
            Cancel
            </Button>
        </DialogActions>
 
      </Dialog>
    )
}


export default AddUser;
