import React, {useRef} from 'react';
import { Avatar,  IconButton, Button, Box, Typography, Stack} from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import {getWindowDimensions} from '../util/index.js'
import AssignmentIcon from '@mui/icons-material/Assignment';
import PersonIcon from '@mui/icons-material/Person';

const classes = {
    avatarCont: {
        height: 200,
        width:200,
        position: 'relative'
    },
    round: {
        borderRadius: "50%"
    },
    avatar: {
        height: 200,
        width:200
    },
    uploadBtn: {
        position: 'absolute',
        bottom:0,
        width: '100%',
        textAlign: 'center',
        justifyContent:'center',
        alignItems:'center',
        height: '45%',
        cursor:'pointer'
    },
    uploadInput: {
        display:'none'
    },
    cropCont: {
        position:'fixed',
        height:'80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent:'center',
        alignItems:'center',
        width:'50%',
        top:0,
        left:'25%',
        backgroundColor:'white',
        border: '1px solid black',
        zIndex:10
    }
    
}
const HoverContent = (props) => {
    if (props.show) {
        return (
            <div style={classes.uploadBtn}>
              <label htmlFor={props.id}>
                  <Stack>
                <IconButton color="primary" aria-label="upload picture" component="span">
                  <CameraAltIcon fontSize={"large"}/>
                </IconButton>
                {props.label && <Typography color="primary" sx={{fontSize: 14}}>{props.label}</Typography>}
                </Stack>
              </label>
                
            </div>
        )
    }
    return null
}

const CropElement = ({img, isMobile, onCrop, cropperRef, open, setOpen}) => {
    if (open) {
        return (
            <div style={isMobile ? {...classes.cropCont,left:'5%', width: '90%'} : classes.cropCont}>
                <Cropper
                src={img}
                style={{ height: '90%', width: "100%" }}
                initialAspectRatio={16 / 9}
                guides={false}
                //crop={onCrop}
                ref={cropperRef}
                />
                <div style={{maxWidth:'200px', display: 'flex', alignItems:'center', justifyContent:'space-around'}}>
                    <Button onClick={()=>{setOpen(false)}} color="secondary">
                        Cancel
                    </Button>
                    <Button autoFocus onClick={onCrop} color="primary">
                        Crop
                    </Button>
                </div>
            </div>
        );
    }
    return null;
}
const ImageUpload = ({avatarUrl, setAvatarUrl, setFile, disabled, isSquare=false, element, label, type})=> {
    const dim = getWindowDimensions();
    const isMobile = dim.width < 500 ? true : false
    const [showUpload, setShowUpload] = React.useState(false);
    const [showCrop, setShowCrop] = React.useState(false);
    const [beforeCrop, setBeforeCrop] = React.useState('')
    const cropperRef = useRef(null);
    let avatarIcon = <PersonIcon sx={{height: '4em', width: '4em'}}/>
    if (type === "document") {
        avatarIcon = <AssignmentIcon  sx={{height: '4em', width: '4em'}}/>
    }
    const onCrop = () => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;
        setShowCrop(false)        
        setAvatarUrl(cropper.getCroppedCanvas().toDataURL());
        setFile(cropper.getCroppedCanvas().toDataURL())
        
    };    
    const handleMouseEnter = (e)=> {
        setShowUpload(true)
    }
    const handleMouseLeave = (e)=> {
        setShowUpload(false)
    }
    const handleChange = (e) => {
        setShowCrop(true)
        setBeforeCrop(URL.createObjectURL(e.target.files[0]))
        //setAvatarUrl(URL.createObjectURL(e.target.files[0]));
        //setFile(e.target.files[0])
   }

    return (
        <Box sx={classes.avatarCont} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}>
            <input accept="image/*" style={classes.uploadInput} onChange={handleChange} id={`icon-button-${element}`} type="file" />
            <Avatar variant={isSquare ? "square" : undefined} src={avatarUrl} style={classes.avatar}>{avatarIcon}</Avatar>
            {!disabled && <HoverContent label={label} show={showUpload || isMobile} setFile={setFile} setAvatarUrl={setAvatarUrl} id={`icon-button-${element}`}/>}
            <CropElement isMobile={isMobile} img={beforeCrop} open={showCrop} onCrop={onCrop} cropperRef={cropperRef} setOpen={setShowCrop}/>
        </Box>
    )
}

export default ImageUpload